import React, { useState, useContext, useEffect } from 'react';
import {useLocation} from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import { Form, FormGroup, Input } from 'reactstrap';
import MyTitle from '../../components/MyTitle/MyTitle';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../components/CheckoutForm/CheckoutForm";
import { LOAD_STRIPE } from "../../env";
import { AppContext } from '../../data/state';
import "./PaymentInformation.css";

const promise = loadStripe(LOAD_STRIPE);

export interface serviceTicket  {
  CompaniesSubServicesID: number,
  Quantity: number,
  PriceID: number,
  Price:  number
}

const PaymentInformation:React.FC =()=>{
  const { state, dispatch } = useContext(AppContext);
  const [name, setName] = useState(state.user.name);
  const [email, setEmail] = useState(state.user.email);
  const [phone, setPhone] = useState(state.user.phoneNumber);
  const [addres, setAddres] = useState(state.user.address);
  const [companiesSubServicesId, setCompaniesSubServicesId] = useState(-1);
  const [quantity, setQuantity] = useState(-1);
  const [priceId, setPriceId] = useState(-1);
  const [price, setPrice] = useState(-1);
  const [objServiceTickets, setObjServiceTickets] = useState<serviceTicket>();
  const location: any = useLocation();
  //Ticket values
  const [companyId, setCompanyId] = useState(0);
  const [scheduledDate, setScheduledDate] = useState("");
  const [scheduleId, setScheduleId] = useState(null);

  useEffect(()=>{
    if(location.state !== undefined) {
      setCompaniesSubServicesId(location.state.companiesSubServicesId);
      setQuantity(location.state.quantity);
      setPriceId(location.state.priceId);
      setPrice(location.state.price);
    }
    setObjServiceTickets({
      CompaniesSubServicesID: companiesSubServicesId,
      Quantity: quantity,
      PriceID: priceId,
      Price:  price
    });
  }, [])

  return(
    <div className="information-div">
      <MyTitle title="Information"/>
      <Container className="information-container">
        <Row className="information-row">
          <Col className="information-img">
            <div className="information-logo-img">
              <img src="assets/signup/questions2.jpg" className="logo"/>
            </div>
          </Col>
          <Col className="personal-form">
            <div className="titles">
              <label className="title-label">Personal Info:</label>
            </div>
            <div className="contact-inputs">
              <Form>
                <FormGroup>
                  <Input 
                    value={name} type="text" name="text" 
                    className="form-input" placeholder="Name" 
                    onChange={e => setName(e.target.value)} 
                  />
                </FormGroup>
                <FormGroup>
                  <Input 
                    value={email} type="email" name="email" 
                    className="form-input" placeholder="Email" 
                    onChange={e => setEmail(e.target.value)} 
                  />
                </FormGroup>
                <FormGroup>
                  <Input 
                    value={phone} type="number" name="number" 
                    className="form-input" placeholder="Phone" 
                    onChange={e => setPhone(parseInt(e.target.value, 10))} 
                  />
                </FormGroup>
                <FormGroup>
                  <Input 
                    value={addres} type="text" name="addres" 
                    className="form-input" placeholder="Address" 
                    onChange={e => setAddres(e.target.value)} 
                  />
                </FormGroup>
                <div className="titles">
                  <label className="title-label">Payment info:</label>
                </div> 
                <div className="checkout-form-container">
                  <Elements stripe={promise}>
                    <CheckoutForm 
                      name={name}
                      email={email}
                      phone={phone}
                      addres={addres}
                      companyId={companyId}
                      scheduleDate={scheduledDate}
                      scheduleId={scheduleId}
                      objServiceTicket={objServiceTickets}
                    />
                  </Elements>
                </div>         
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PaymentInformation;
