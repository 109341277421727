import React, { useState, useEffect, useContext } from "react";
import "./DetailTicket.css";
import { useLocation } from "react-router-dom";
import { AppContext } from '../../data/state';
import Loading from "../../components/Loading/Loading";
import MyTitle from "../../components/MyTitle/MyTitle";
import CardTicket from "../../components/CardTicket/CardTicket";
import CardServiceDetail from "../../components/CardServiceDetail/CardServiceDetail";
import CardHistoryDetail from "../../components/CardHistoryDetail/CardHistoryDetail";
import CardOpenCharge from "../../components/CardOpenCharge/CardOpenCharge";
import server from "../../api/server";
import moment from "moment";

interface ServiceDetail {
  id: number,
  name: string,
  price: number,
};

interface HistoryDetail {
  id: number,
  date: string,
  status: string,
  comment: string,
  user: string,
};

interface OpenChargeDetail {
  id: number,
  description: string,
  price: number,
  duration: string,
  createdDate: string,
};

const DetailTicket: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [ticketId, setTicketId] = useState(-1);
  const [ticketName, setTicketName] = useState("");
  const [ticketEmail, setTicketEmail] = useState("");
  const [ticketPhoneNumber, setTicketPhoneNumber] = useState("");
  const [ticketAddress, setTicketAddress] = useState("");
  const [ticketTotalPrice, setTicketTotalPrice] = useState(-1);
  const [ticketCreatedDate, setTicketCreatedDate] = useState("");
  const [ticketScheduledDate, setTicketScheduledDate] = useState("");
  const [ticketService, setTicketService] = useState("");
  const [ticketStatus, setTicketStatus] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const [ticketInstructions, setTicketInstructions] = useState("");
  const [ticketDuration, setTicketDuration] = useState("");

  const [ticketServiceDetail, setTicketServiceDetail] = useState<ServiceDetail[]>([]);
  const [ticketHistoryDetail, setTicketHistoryDetail] = useState<HistoryDetail[]>([]);
  const [ticketOpenChargeDetail, setTicketOpenChargeDetail] = useState<OpenChargeDetail[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const location: any = useLocation();

  const formatDate = (date: any) => moment(date).format("YYYY-MM-DD");

  useEffect(() => {
    if (location.state !== undefined) {
      setTicketId(location.state.TicketId);
      ticketData(location.state.TicketId);
      ticketServices(location.state.TicketId);
      ticketHistory(location.state.TicketId);
      ticketOpenCharge(location.state.TicketId);
    }
  }, []);


  const ticketData = async(ticketID: any) => {
    const headers = {
      'Authorization': `Bearer ${state.token}`
    }

    setIsLoading(true);

    try {
      const responseServer = await server.get(
                                      '/admin/tickets/'+ticketID,
                                      {headers: headers}
                                    );

      if(responseServer.data.statusCode !== 200) {
        setIsLoading(false);
        console.log(responseServer.data.message);
        return;
      }

      const response = responseServer.data;

      setTicketName(response.data.name);
      setTicketEmail(response.data.email);
      setTicketPhoneNumber(response.data.phoneNumber);
      setTicketAddress(response.data.address);
      setTicketTotalPrice(response.data.totalPrice);
      setTicketCreatedDate(response.data.createdDate);
      setTicketScheduledDate(response.data.scheduledDate);
      setTicketService(response.data.serviceName);
      setTicketStatus(response.data.statusName);
      setTicketDescription(response.data.description);
      setTicketInstructions(response.data.instructions);
      setTicketDuration(response.data.serviceDuration);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR");
      return;
    }
  };

  const ticketServices = async(ticketID: any) => {
    const headers = {
      'Authorization': `Bearer ${state.token}`
    }

    setIsActive(true);

    try {
      const responseServer = await server.get('/admin/ticket-services/ticket/'+ticketID, {headers: headers});

      if(responseServer.data.statusCode !== 200) {
        setIsActive(false);
        console.log(responseServer.data.message);
        return;
      }

      const response = responseServer.data;
      setTicketServiceDetail([]);

      for(var i = 0; i < response.data.length; i++) {
        setTicketServiceDetail(tickets => [...tickets, {
          id: response.data[i].id,
          name:  response.data[i].displayName,
          price: response.data[i].price,
        }]);
      }

      setIsActive(false);
    } catch (error) {
      setIsActive(false);
      console.log("ERROR");
      return;
    }
  };

  const ticketHistory = async(ticketID: any) => {
    const headers = {
      'Authorization': `Bearer ${state.token}`
    }

    setIsActive(true);

    try {
      const responseServer = await server.get('/admin/ticket-history/ticket/'+ticketID, {headers: headers});

      if(responseServer.data.statusCode !== 200) {
        setIsActive(false);
        console.log(responseServer.data.message);
        return;
      }

      const response = responseServer.data;
      setTicketHistoryDetail([]);
      for(var i = 0; i < response.data.length; i++) {
        setTicketHistoryDetail(tickets => [...tickets, {
          id: response.data[i].id,
          date: formatDate(response.data[i].updatedAt),
          status: response.data[i].status,
          comment: response.data[i].comments,
          user: response.data[i].userType.type,
        }]);
      }

      setIsActive(false);
    } catch (error) {
      setIsActive(false);
      console.log("ERROR");
      return;
    }
  };

  const ticketOpenCharge = async(ticketID: any) => {
    const headers = {
      'Authorization': `Bearer ${state.token}`
    }

    setIsLoading(true);

    try {
      const responseServer = await server.get(
                  '/admin/open-charges/ticket/'+ticketID,
                  {headers: headers}
                );

      if(responseServer.data.statusCode !== 200) {
        setIsLoading(false);
        console.log(responseServer.data.message);
        return;
      }

      const response = responseServer.data;
      setTicketOpenChargeDetail([]);
      for(var i = 0; i < response.data.length; i++) {
        setTicketOpenChargeDetail(tickets => [...tickets, {
          id: response.data[i].id,
          description: response.data[i].description,
          price: response.data[i].price,
          duration: response.data[i].duration,
          createdDate: formatDate(response.data[i].createdDate),
        }]);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR");
      return;
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyTitle title="My Tickets Detail" />
      <section className="tickets-section">
        <CardTicket
          key={ticketId}
          id={ticketId}
          name={ticketName}
          email={ticketEmail}
          phoneNumber={ticketPhoneNumber}
          address={ticketAddress}
          totalPrice={ticketTotalPrice}
          createdDate={ticketCreatedDate}
          scheduledDate={ticketScheduledDate}
          service={ticketService}
          status={ticketStatus}
          description={ticketDescription}
          instructions={ticketInstructions}
          duration={ticketDuration}
        />
        <div className="services-detail">
          <div className="div-title">
            <h1 className="card-title">Services Detail</h1>
          </div>
          <div className="elements-info">
            {ticketServiceDetail.map((item) => (
              <CardServiceDetail
                key={item.id}
                id={item.id}
                name={item.name}
                price={item.price}
              />
              ))
            }
          </div>
        </div>
        <div className="services-detail">
          <div className="div-title">
            <h1 className="card-title">Open Charge Detail</h1>
          </div>
          <div className="elements-info">
            {ticketOpenChargeDetail.map((item) => (
              <CardOpenCharge
                key={item.id}
                id={item.id}
                description={item.description}
                price={item.price}
                duration={item.duration}
                createdDate={item.createdDate}
              />
              ))
            }
          </div>
        </div>
        <div className="services-detail">
          <div className="div-title">
            <h1 className="card-title">Ticket History</h1>
          </div>
          <div className="elements-info">
            {ticketHistoryDetail.map((item) => (
              <CardHistoryDetail
                key={item.id}
                id={item.id}
                date={item.date}
                status={item.status}
                comment={item.comment}
                user={item.user}
              />
              ))
            }
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailTicket;
