import React, { useState, useEffect, useContext } from "react";
import MyTitle from "../../components/MyTitle/MyTitle";
import Loading from "../../components/Loading/Loading";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import { AppContext } from "../../data/state";
import server from '../../api/server';
import { useHistory } from "react-router-dom";
import "./Parameters.css"

const Parameters: React.FC = () => {
  let history = useHistory();

  const { state, dispatch } = useContext(AppContext);

  const [isActive, setIsActive] = useState(false);

  const [parameters, setParameters] = useState<any>([]);
  const [allParameters, setAllParameters] = useState<any>([]);

  useEffect(() => {
    getParameters();
  }, []);

  const getParameters = async () => {
    try {
      setIsActive(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get("/admin/parameters",
                                    {headers: headers}
                                  );

      const response = request.data;

      setParameters(response.data);
      dataFormat(response.data);
      setIsActive(false);
    } catch (error) {
      setIsActive(false);
      const response = error.response;
      console.error(error);
    }
  };

  const dataFormat = (parameters: any) => {
    let table: any = [];

    let content = parameters.map((value) => {
      return value.id;
    });

    let id = {
      data: { title: "ID", content },
      button: false,
    };

    content = parameters.map((value) => {
      return value.parameter;
    });

    let parameter = {
      data: { title: "Parameter", content },
      button: false,
    };

    content = parameters.map((value) => {
      return value.description;
    });

    let description = {
      data: { title: "Description", content },
      button: false,
    };

    content = parameters.map((value) => {
      return value.value;
    });

    let value = {
      data: { title: "Value", content },
      button: false,
    };

    content = parameters.map((value) => {
      let button = [{
        label: "Edit",
        onPressed: () => editValue(value),
      }];

      return {button};
    });

    let buttons = {
      data: { title: "Buttons", content },
      button: true,
    };

    table.push(id);
    table.push(parameter);
    table.push(description);
    table.push(value);
    table.push(buttons);

    setAllParameters(table);
  };

  const editValue = (parameter: any) => {
    history.push({
      pathname: "/update-value",
      state: {
        ParameterID : parameter.id,
        ParameterValue : parameter.value,
      },
    });
  };

  return (
    <>
      {isActive &&
          <Loading />
      }
      <MyTitle title="Parameters" />
      <section className="parameters-section">
      {
        parameters ?
          <ScheduleList data={allParameters} />
        : null
      }
      </section>
    </>
  );
}

export default Parameters;
