import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Input } from 'reactstrap';
import MyTitle from "../../components/MyTitle/MyTitle";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import { AppContext } from '../../data/state';
import server from '../../api/server';
import "./UpdateStatus.css"

const UpdateStatus: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [isActive, setIsActive] = useState(false);

  const [ticketId, setTicketId] = useState(0);
  const [statusName, setStatusName] = useState("");
  const [comment, setComment] = useState("");

  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  const [action, setAction] = useState("");
  const [nextStatusId, setNextStatusId] = useState(0);

  const [nextStatusList, setNextStatusList] = useState<any[]>([]);

  const [selectStatus, setSelectStatus] = useState(0);

  let history = useHistory();
  const location: any = useLocation();

  const modalText = [
    {
      modalTitle: "Status",
      modalBody: "Status updated successfully.",
    },
  ];

  const onSubmitUpdateStatusFlow = async (e: any) => {
    e.preventDefault();

    let data = {
      TicketsStatusID: selectStatus,
      Comments: comment,
    };

    changeStatus(data);
  };

  const changeStatus = async(data: any) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`,
    }

    setIsActive(true);

    try {
      const responseServer = await server.put('/admin/tickets/'+ticketId,
        JSON.stringify(data),
        {headers: headers}
      );

      if(responseServer.data.statusCode !== 200) {
        setIsActive(false);
        console.log(responseServer.data.message);
        return;
      }

      setIsActive(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);

      setModal(true);
      setTimeout(() => {
        history.push({
          pathname: "/list-tickets",
        });
      }, 3000);
    } catch (error) {
      setIsActive(false);
      console.log("ERROR");
      return;
    }
  };

  const nextStatusFlow = async (id: any) => {
    const headers = {
      'Authorization': `Bearer ${state.token}`
    }

    try {
      const responseServer = await server.get('/customer/status-flow/'+id,
        {headers: headers});

      if(responseServer.data.statusCode !== 200) {
        setIsActive(false);
        console.log(responseServer.data.message);
        return;
      }

      const response = responseServer.data;

      setNextStatusList(response.data);

      setIsActive(false);
    } catch (error) {
      setIsActive(false);
      console.log("ERROR");
      return;
    }
  };

  const onChangeComment = (e: any) => {
    setComment(e.target.value);
  };

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  const onChangeSelectStatus = async (e: any) => {
    setSelectStatus(e.target.value);
  };

  useEffect(() => {
    if (location.state !== undefined) {
      setTicketId(location.state.TicketId);
      nextStatusFlow(location.state.TicketId);
    }
  }, []);

  return(
    <>
      {isActive &&
          <Loading />
      }
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title="Update Status" />
      <div className="section-update-ticket">
        <div className="card-update-ticket">
          <div className="div-title">
            <h1 className="card-title">Update Status</h1>
          </div>
          <form
            action=""
            onSubmit={onSubmitUpdateStatusFlow}
            className="form-container-update"
          >
            <div className="new-status-section">
              <select
                className="new-status-select"
                value={selectStatus}
                onChange={onChangeSelectStatus}
              >
                <option value={0} className="filter-option">Select Status</option>
                {
                    nextStatusList.map((item) => {
                      return (
                        <option className="filter-option" key={item.id} value={item.nextStatusID}>
                          {item.nextStatus}
                        </option>
                      )
                  })
                }
              </select>
            </div>
            <div className="comment-section">
              <p className="text-comment">Reason:</p>
              <div className="div-comment">
                <Input
                  type="textarea"
                  className="text-area"
                  id="exampleText"
                  onChange={onChangeComment}
                />
              </div>
            </div>
            <button
              className="button-submit-status"
              type="submit"
            >
              Update Status
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateStatus;
