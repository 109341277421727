import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { User } from '../../Models/User';
import { Spinner, Button } from 'reactstrap';
import MyModal from '../../components/MyModal/MyModal';
import { AppContext } from '../../data/state';
import server from '../../api/server';
import './ResetPassword.css';

const ResetPassword: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [passwordChange, setPasswordChange] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(state.isLoggedin);

  let history = useHistory();

  const onChangePassword = (e:any) => {
    setPassword(e.target.value);
  }

  const onChangeNewPassword = (e:any) => {
    setNewPassword(e.target.value);
  }

  const onChangeCurrentPassword = (e:any) => {
    setCurrentPassword(e.target.value);
  }

  const onChangeModal = async(value: any) => {
    setModal(value);
    if(passwordChange == true) {
      history.push('/home');
    }
  };

  const onSubmit = async(e:any) => {
    e.preventDefault();
    setIsActive(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`
    }

    const data = {
      CurrentPassword: currentPassword,
      NewPassword: newPassword
    }

    await server.put('/admin/auth/change-password', JSON.stringify(data), {headers: headers}
    ).then(async (res) => {
      const response = res.data;
      setModal(true);

      if(response.statusCode != 200){
        console.log('error');
        return;
      }

      if(response.statusCode == 200) {
        setModalText('Password updated successfully.');
        setPasswordChange(true);
        await dispatch({
          type: 'set-temp-password',
          isTempPassword: false,
        });
      }
    }).catch((error) => {
      setModal(true);
      setModalText('Could not update password.')
      setPasswordChange(false);

      const response = error.response;
      if (response) {
        switch(response.status){
          case 404:
        }
      }
    });

    setIsActive(false);
  };

  useEffect(() => {
    if(!loggedInUser){
      history.replace('/');
    }
  });

  return (
    <>
    <MyModal
      modalTitle="Reset Password"
      modalBody={modalText}
      modalValue={modal}
      onChangeModal={onChangeModal}
    />
      <div className="row-section-sign-in">
        <div className="container-card">
          <div className="card-reset-password">
            <div className="card-body-sign-in">
              <img src="assets/login/logo.png" alt="logo" className="logo-signin" />
              <h1 className="text-center">Reset Password</h1>
              <p className="insert-text">Insert a new password</p>
              <form onSubmit={onSubmit} className="form-reset-password">
                <p className="label-text">Current password</p>
                <img src="assets/login/login_pass.png" alt="icon password" className="icon-login-reset-password" />
                <input className="inputs-reset-password" type="password" name="password" id="password" autoComplete='off' onChange={onChangeCurrentPassword} />
                <p className="label-text">New password</p>
                <img src="assets/login/login_pass.png" alt="icon password" className="icon-login-reset-password" />
                <input className="inputs-reset-password" type="password" name="password" id="password" autoComplete='off' onChange={onChangePassword} />
                <p className="label-text">Repeat new password</p>
                <img src="assets/login/login_pass.png" alt="icon password" className="icon-login-reset-password" />
                <input className="inputs-reset-password" type="password" name="password" id="password" autoComplete='off' onChange={onChangeNewPassword} />
                <Button
                  type="submit"
                  className="btn-signin reset-button"
                  disabled={password === '' || newPassword === '' || currentPassword === '' || password !== newPassword}
                >
                  {isActive
                    ? <Spinner />
                    : "Change Password"
                  }
                </Button>
              </form>
                {password !== '' && newPassword !== '' && currentPassword !== ''
                ? password !== newPassword
                ? <p className="match-password">Password did not match</p>
                : <p className="match-password-good">Password match</p>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
