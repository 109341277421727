import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from '../../data/state';
import server from '../../api/server';
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import MyTitle from "../../components/MyTitle/MyTitle";
import moment from "moment";

import "./UpdateTimeUnit.css"

const UpdateTimeUnit: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [timeUnitValue, setTimeUnitValue] = useState("DEFAULT");
  const [listTimeUnit, setListTimeUnit] = useState([]);
  const [zipCodeId, setZipCodeId] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  let history = useHistory();
  const location: any = useLocation();

  useEffect(() => {
    if (location.state !== undefined) {
      setZipCodeId(location.state.zipCodeID);
    }
    getTimeUnit();
  }, []);

  const formatHours = (time: any) => {
    let timeArray: any [] = time.split(":");
    timeArray.splice(timeArray.length-1,1);
    return timeArray.join(":") + " hrs";
  };

  const getTimeUnit = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
      };
      const request = await server.get(`/customer/time-units`, {
        headers,
      });

      const response = request.data;
      setListTimeUnit(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const onSubmitEditTimeUnit = async (e: any) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      let data:any = {
        TimeUnitID: timeUnitValue.substring(0, 5)
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.put(`/admin/company-location-zip/${zipCodeId}`,
        JSON.stringify(data),
        {headers: headers}
      );
      setIsLoading(false);

      setModalTitle("Update Time");
      setModalBody("Time updated successfully.");

      setModal(true);
      setTimeout(() => {
        history.goBack();
      }, 3000);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const onChangeSelectTimeUnit = (e: any) => {
    let val = e.target.value;
    setTimeUnitValue(val);
  };

  const onChangeModal = async(value: any) => {
    setModal(value);
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle
        title={"Update Time Unit"}
      />
      <section className="section-create">
        <div className="card-create">
          <form
            action=""
            onSubmit={onSubmitEditTimeUnit}
            className="form-container-update-time"
          >
            <div className="form-container-input">
              <span className="label-input-create">Time between Jobs: </span>
              <select
                name="selectTimeUnit"
                id="selectTimeUnit"
                className="input-form-create select-create"
                onChange={onChangeSelectTimeUnit}
                style={{
                  backgroundImage: "url('assets/home/arrow_down.png')",
                }}
                value={timeUnitValue}
                required
              >
                <option value="DEFAULT" disabled>
                  -- Choose an option --
                </option>
                {listTimeUnit.map((timeUnitObj: any, index: number) => {
                  return (
                    <option key={index} value={timeUnitObj.duration}>
                      {formatHours(timeUnitObj.duration)}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="btn-container-create">
              <button type="submit" className="btn-submit-create">
                <span className="label-btn-submit">Edit</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default UpdateTimeUnit;
