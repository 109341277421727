import ScheduleItem from "../ScheduleItem/ScheduleItem";
import "./ScheduleList.css";
const ScheduleList = ({data, style = {}}) => {

  return (
    <>
      <div className="container-table">
        <section className="container-schedule" style={style}>
          {data.map((value: any, index:number) => {
            return <ScheduleItem button={value.button} data={value.data} key={index}/>;
          })}
        </section>

      </div>
    </>
  );
};

export default ScheduleList;
