import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Spinner } from 'reactstrap';
import { Button, Form, FormGroup,Input } from 'reactstrap';
import MyTitle from '../../components/MyTitle/MyTitle';
import MyModal from '../../components/MyModal/MyModal';
import { AppContext } from '../../data/state';
import { User } from '../../Models/User';
import server from '../../api/server';
import "./Profile.css";

const Profile:React.FC =()=>{
  const { state, dispatch } = useContext(AppContext);
  const [name, setName] = useState(state.user.name);
  const [email, setEmail] = useState(state.user.email);
  const [phone, setPhone] = useState(state.user.phoneNumber);
  const [address, setAddress] = useState(state.user.address);
  const [isActive, setIsActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalText, setModalText] = useState('');

  let history = useHistory();

  const userElements = [
    {
      elValue: name,
      elType: "text",
      elName: "text",
      elPlaceholder: "Name",
      elChange: e => setName(e.target.value),
      isDisable: true,
    },
    {
      elValue: email,
      elType: "email",
      elName: "email",
      elPlaceholder: "Email",
      elChange: e => setEmail(e.target.value),
      isDisable: true,
    },
    {
      elValue: phone,
      elType: "number",
      elName: "number",
      elPlaceholder: "Phone",
      elChange: e => setPhone(e.target.value),
      isDisable: false,
    },
    {
      elValue: address,
      elType: "text",
      elName: "address",
      elPlaceholder: "Adress",
      elChange: e => setAddress(e.target.value),
      isDisable: false,
    }
  ];

  const onChangeModal = async(value: any) => {
    setModal(value);
    history.push('/home');
  };

  const onChangePassword = async() => {
    history.push('/reset-password');
  };

  const saveChanges = async(e) =>{
    e.preventDefault();
    setIsActive(true);
    let userChanges: User = {
      email: email,
      name: name,
      phoneNumber: phone.toString(),
      address: address
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`
    }

    const data = {
      phoneNumber: phone,
      address: address
    }

    await server.put('/customer', JSON.stringify(data), {headers: headers}
    ).then(async (res) => {
      const response = res.data;
      setModal(true);

      if(response.statusCode != 200){
        console.log('error');
        return;
      }

      if(response.statusCode == 200) {
        setModalText('Information updated successfully.');
        await dispatch({
          type: 'set-user',
          user: userChanges
        });
      }
    }).catch((error) => {
      setModal(true);
      setModalText('Could not update your information.')

      const response = error.response;
      if (response) {
        switch(response.status){
          case 404:
        }
      }
    });

    setIsActive(false);
  }

  return(
    <>
      <MyModal
        modalTitle="Reset info"
        modalBody={modalText}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <div className="information-div">
        <MyTitle title="Profile"/>
        <Container className="information-container">
          <Row className="information-row">
            <Col className="information-img-signup information-img">
              <div className="information-logo-img">
                <img src="assets/login/default-avatar.png" className="logo"/>
              </div>
            </Col>
            <Col className="personal-form-signup personal-form">
              <div className="titles">
                <label className="title-label">Personal Info:</label>
              </div>
              <div className="contact-inputs">
                <Form>
                  {userElements.map((element:any) => {
                    return(
                      <FormGroup key={element.elName}>
                        <Input
                          type={element.elType}
                          name={element.elName}
                          value={element.elValue}
                          className="form-input"
                          placeholder={element.elPlaceholder}
                          onChange={e => element.elChange(e)}
                          disabled={element.isDisable}
                        />
                      </FormGroup>
                    );
                  })}
                  <div className="buttons-div">
                    <Button
                      className="form-submit"
                      disabled={(name === "") || (email === "")}
                      onClick={e => saveChanges(e)}
                    >
                      <strong>
                        {isActive
                          ? <Spinner />
                          : "Save"
                        }
                      </strong>
                    </Button>
                    <Button
                      className="form-submit change-pass"
                      disabled={(name === "") || (email === "")}
                      onClick={onChangePassword}
                    >
                      <strong>
                        {isActive
                          ? <Spinner />
                          : "Change Password"
                        }
                      </strong>
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Profile;
