import { useHistory, useLocation } from "react-router";
import React, { useEffect, useContext, useState } from "react";
import server from "../../api/server";
import { AppContext } from "../../data/state";
import MyTitle from "../../components/MyTitle/MyTitle";
import Loading from "../../components/Loading/Loading";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import BtnAddList from "../../components/BtnAddList/BtnAddList";
import MyModal from "../../components/MyModal/MyModal";
import "./ListLocation.css";

const ListLocation: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [locationList, setLocationList] = useState<any[]>([]);
  const [companyID, setCompanyID] = useState(0);


  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location: any = useLocation();
  let history = useHistory();

  const modalText = [
    {
      modalTitle: "Company Location",
      modalBody: "Company Location modified successfully",
    },
  ];

  useEffect(() => {
    if (location.state !== undefined) {
      setCompanyID(location.state.companyID);
    }
    requestData();
  }, []);

  const requestData = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get(`/admin/company-locations/company/${location.state.companyID}`, {
        headers,
      });
      const response = request.data;
      let table: any = [];
      let content = response.data.map((value) => {
        return value.locationName;
      });
      let locationName = {
        data: { title: "Location Name", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.address;
      });
      let address = {
        data: { title: "Address", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.phoneNumber;
      });
      let phoneNumber = {
        data: { title: "Phone number", content },
        button: false,
      };

      content = response.data.map((value) => {
        const locValue = value;
        return {
          button: [
            {
              label: "EDIT",
              onPressed: () => updateLocation(locValue),
            },
            {
              label: "ZIP codes",
              onPressed: () => listZipCodes(locValue),
            },
            {
              label: locValue.active ? "ACTIVE" : "DEACTIVE",
              onPressed: (e: any) => updateStatusLocation(locValue, e),
            },
          ],
        };
      });

      let buttons = {
        data: { title: "Buttons", content },
        button: true,
      };
      table.push(locationName);
      table.push(address);
      table.push(phoneNumber);
      table.push(buttons);

      setLocationList(table);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      console.error(error);
    }
  };

  const updateLocation = (locationValue: any) => {
    window.scrollTo(0,0);
    history.push({
      pathname: "/create-location",
      state: {
        idCompanyLocation: locationValue.id,
        locationName: locationValue.locationName,
        address: locationValue.address,
        phoneNumber: locationValue.phoneNumber,
        active: locationValue.active,
      },
    });
  };

  const listZipCodes = (locationValue: any) => {
    window.scrollTo(0,0);
    history.push({
      pathname: "/list-locations-zip",
      state: {
        locationID: locationValue.id,
      },
    });
  };

  const updateStatusLocation = async (location: any, e: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.delete(`/admin/company-locations/${location.id}`, {
        headers,
      });
      const response = request.data;
      if (e.target.innerText === "ACTIVE") {
        e.target.classList.toggle("deactive-btn-user");
        e.target.innerText = "DEACTIVE";
      } else {
        e.target.classList.toggle("deactive-btn-user");
        e.target.innerText = "ACTIVE";
      }
      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      console.error(error);
    }
  };

  const addLocation = () => {
    window.scrollTo(0,0);
    history.push({
      pathname: "/create-location",
      state: {
        idCompanyLocation: companyID,
        locationName: null,
        address: null,
        phoneNumber: null,
        active: null,
      },
    });
  };
  const onChangeModal = (value: any) => {
    setModal(value);
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyTitle title="Location list" />
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <div className="container-btn-services">
        <BtnAddList label="Add Location" onClick={addLocation} />
      </div>
      <section className="customer-section">
        <ScheduleList data={locationList} />
      </section>
    </>
  );
};
export default ListLocation;
