import React, { useState, useContext, useEffect } from "react";
import { saveAs } from "file-saver";

import { AppContext } from "../../data/state";

import "./CreatePrintStatement.css";
import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import MyDatePicker from "../../components/MyDatePicker/MyDatePicker";
import moment from "moment";
import { useLocation } from "react-router";

const CreatePrintStatement: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [companyID, setCompanyID] = useState<any>("");

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const location: any = useLocation();

  useEffect(() => {
    if (location.state !== undefined) {
      setCompanyID(location.state.companyID);
    }
  }, []);

  const getPDFStatement = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        StartDate: moment(startDate).format("YYYY-MM-DD"),
        EndDate: moment(endDate).format("YYYY-MM-DD"),
        CompanyID: companyID, //Only for Staff Users
      };

      const request = await server.post(
        "/reports/company-statement",
        JSON.stringify(data),
        {
          headers,
          responseType: "blob",
        }
      );

      const response: string = request.data;

      const pdfBlob = new Blob([response], { type: "application/pdf" });
      saveAs(pdfBlob, "Company_Statement.pdf");

      setIsLoading(false);

      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } catch (error) {
      setModalTitle("Error!");
      setModalBody("There's an error on trying to generate the file");
      setModal(true);
      setIsLoading(false);
    }
  };

  const onChangeStartDate = (value) => {
    setStartDate(value);
  };

  const onChangeEndDate = (value) => {
    setEndDate(value);
  };

  const [today, setToday] = useState(new Date());

  const modalText = [
    {
      modalTitle: "PDF generated",
      modalBody: "PDF generated",
    },
  ];

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  const onSubmitFormCompanyLocation = (e: any) => {
    e.preventDefault();
    if (startDate > endDate) {
      setModalTitle("Error!");
      setModalBody("Start date can't be greater than end date");
      setModal(true);
    } else {
      getPDFStatement();
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title="Print statement" />
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">Print statement</h1>
          </div>
          <div
            className="card-body-create"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <form
              action=""
              onSubmit={onSubmitFormCompanyLocation}
              className="form-container"
            >
              <div className="form-container-input">
                <div className="container-input-label">
                  <span className="label-input-create">Start Date:</span>
                </div>
                <MyDatePicker
                  setDatePicker={onChangeStartDate}
                  startDate={startDate}
                  isBlocked={false}
                />
              </div>
              <div className="form-container-input">
                <div className="container-input-label">
                  <span className="label-input-create">End Date:</span>
                </div>
                <MyDatePicker
                  setDatePicker={onChangeEndDate}
                  startDate={endDate}
                  isBlocked={false}
                />
              </div>
              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreatePrintStatement;
