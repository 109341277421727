import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../data/state";

import "./LinkedService.css";

import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import BtnAddList from "../../components/BtnAddList/BtnAddList";

const LinkedService: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);

  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  const location: any = useLocation();

  const [companyId, setCompanyId] = useState(-1);

  const [linkedCompanyServices, setLinkedCompanyServices] = useState<any[]>([]);

  let history = useHistory();

  useEffect(() => {
    if (location.state !== undefined) {
      setCompanyId(location.state.companyId);
      requestData(location.state.companyId);
    }
  }, []);

  const modalText = [
    {
      modalTitle: "Company Service",
      modalBody: "Company Service created succesfully",
    },
    {
      modalTitle: "Service",
      modalBody: "Choose a service to continue",
    },
    {
      modalTitle: "Linked Service",
      modalBody: "Company Service disabled succesfully",
    },
  ];

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  const requestData = async (idCompany: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get(
        `/admin/linked-company-services/company/${idCompany}`,
        {
          headers: headers,
        }
      );

      const requestActiveStatus = await server.get(
        `/admin/linked-services/company/${idCompany}`,
        {
          headers: headers,
        }
      );

      dataFormat(idCompany, request.data.data, requestActiveStatus.data.data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const disableLinkedService = async (id: any) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`,
    }

    setIsLoading(true);
    try {
      const responseServer = await server.delete(
        `/admin/linked-services/${id}`,
        {
          headers: headers,
      });

      setLinkedCompanyServices([]);
      requestData(location.state.companyId);
      setModalTitle(modalText[2].modalTitle);
      setModalBody(modalText[2].modalBody);
      setModal(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return;
    }
  };

  const createNewLinkedService = async () => {
    let data = {
      CompanyID: companyId,
    };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`,
    }

    setIsLoading(true);
    try {
      const responseServer = await server.post(
        `/admin/linked-services`,
        JSON.stringify(data),
        {
          headers: headers,
        }
      );

      setIsLoading(false);
      window.scrollTo(0, 0);
      history.push({
        pathname: "/create-linked-service",
        state: {
          CompanyId: companyId,
          IsCreate: true,
          LinkedServiceId: responseServer.data.data.id,
        },
      });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return;
    }
  };

  const dataFormat = (idCompany: any, linkedServices: any, statusOfServices: any) => {
    let table: any = [];

    let content = linkedServices.map((value) => {
      return value.linkedServiceID;
    });

    let linkedId = {
      data: { title: "ID", content },
      button: false,
    };

    content = linkedServices.map((value) => {
      return value.linkedServices;
    });

    let name = {
      data: { title: "Services", content },
      button: false,
    };

    content = statusOfServices.map((value) => {
      return value.active ? "Active" : "Inactive";
    });

    let isActive = {
      data: { title: "Status", content },
      button: false,
    };

    content = linkedServices.map((value) => {
      const lkdService = value;

      let button = [{
        label: "Editar",
        onPressed: () => editLinkedService(idCompany, lkdService.linkedServiceID),
      }];

      button.push({
        label: "Disable",
        onPressed: () => disableLinkedService(lkdService.linkedServiceID),
      });

      return {button};
    });

    let buttons = {
      data: { title: "Buttons", content },
      button: true,
    };

    table.push(linkedId);
    table.push(name);
    table.push(isActive);
    table.push(buttons);

    setLinkedCompanyServices(table);
  };

  const addNewLinkedService = () => {
    createNewLinkedService();
  };

  const editLinkedService = (idCompany: any, linkedId: any) => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/create-linked-service",
      state: {
        CompanyId: idCompany,
        IsCreate: false,
        LinkedServiceId: linkedId,
      },
    });
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title="Linked service" />
      <div className="add-new-linked-service-container">
        <BtnAddList label="New Linked service" onClick={addNewLinkedService} />
      </div>
      <section className="company-service-section">
        <ScheduleList data={linkedCompanyServices} />
      </section>
    </>
  );
};

export default LinkedService;
