import React from 'react'
import "./MyTitle.css"

function MyTitle({title}: any) {
    return (
        <>
            <div className="container-title">
                <div className="figure">
                <div className="title">
                    <h1>{title}</h1>
                </div>
                </div>
            </div>   
        </>
    )
}

export default MyTitle
