import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Spinner, Button } from 'reactstrap';
import { AppContext } from '../../data/state';
import { User } from '../../Models/User';
import server from '../../api/server';

import "./SignIn.css"

const SignIn: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(state.isLoggedin);
  const [errorMessage, setErrorMessage] = useState('');

  let history = useHistory();

  useEffect(() => {
    if(loggedInUser){
      history.replace('/');
    }
  });

  const onChangeEmail = (e:any) => {
    setEmail(e.target.value);
  }

  const onChangePassword = (e:any) => {
    setPassword(e.target.value);
  }

  const onSubmit = async(e:any) => {
    e.preventDefault();
    setIsActive(true);
    const data = {
      Email: email,
      Password: password,
    }
    const headers = {
      'Content-Type': 'application/json',
    }
    try {
      const res = await server.post('/admin/auth/login', JSON.stringify(data), { headers: headers });
      const response = res.data;
      if(response.statusCode != 200){
        console.log('error');
        return;
      }
      let userRetrieved: User = response.data.user;

      await dispatch({
        type: 'set-is-loggedin',
        isLoggedin: true,
      });
      await dispatch({
        type: 'set-user',
        user: userRetrieved,
      });
      await dispatch({
        type: 'set-token',
        token: response.data.token,
      });

      dispatch({
        type: 'set-temp-password',
        isTempPassword: response.data.user.tempPassword,
      });

      setErrorMessage('');
      setIsActive(false);

      const headersP = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${response.data.token}`,
      };

      const responseServer = await server.get('/admin/auth/permissions', { headers: headersP });
      const responsePermissions = responseServer.data.data;
      if(res.data.statusCode != 200){
        console.log('error');
        return;
      }

      let permissionsTemp : any[] = [];
      for(var i = 0; i < responsePermissions.length; i++) {
        if(responsePermissions[i].permissions.active) {
          permissionsTemp.push(responsePermissions[i].permissions.id);
        }
      }

      await dispatch({
        type: "set-permissions",
        permissions: [...permissionsTemp],
      });
      state.permissions = [...permissionsTemp]

      if(response.data.user.tempPassword) {
        history.replace('/reset-password');
      } else {
        history.replace('/home');
      }
      setIsActive(false);
    } catch (error) {
      setIsActive(false);

      const response = error.response;
      if (response) {
        // pending to complete error status
        switch(response.status){
          case 404:
            setErrorMessage(response.data.message);
        }
      }
    }
  };

  return (
    <>
      <div className="row-section-sign-in">
        <div className="container-card">
          <div className="card-sign-in">
            <div className="card-body-sign-in">
              <div className="container-logo">
                <img src="assets/login/logo.png" alt="logo" className="logo-signin" />
                <span className="label-logo">Staff</span>
              </div>
              <h1 className="text-center">Login into account</h1>
              <p className="mb-5 text-center">Use your credentials to access your account.</p>
              <form onSubmit={onSubmit} className="form-sign-in">
                <img src="assets/login/login_email.png" alt="icon email" className="icon-login-email" />
                <input className="input-sign-in" type="email" name="email" id="email" autoComplete='off' onChange={onChangeEmail} placeholder="Email" />
                <img src="assets/login/login_pass.png" alt="icon password" className="icon-login-password" />
                <input className="input-sign-in" type="password" name="password" id="password" autoComplete='off' onChange={onChangePassword} placeholder="Password" />
                {
                  errorMessage ?
                  <p className="error-message">{errorMessage}</p> : null
                }
                <Button
                  type="submit"
                  className="btn-signin"
                  disabled={email === '' || password === ''}
                >
                  {isActive
                    ? <Spinner />
                    : "SIGN IN"
                  }
                </Button>
                <a className="forgot-pass" href="/recover-password">Forgot Password?</a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
