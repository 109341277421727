import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../data/state";

import "./CreateService.css";

import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import InputFile from "../../components/InputFile/InputFile";

const CreateService: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [service, setService] = useState("");
  const [serviceUnit, setServiceUnit] = useState("");

  const [idService, setIdService] = useState(0);
  const [disclaimer, setDisclaimer] = useState("");
  const [logoString, setLogoString] = useState("");
  const [order, setOrder] = useState<string>("");
  const [logo, setLogo] = useState("");
  const [logoName, setLogoName] = useState("");

  const [statusService, setStatusService] = useState(false);
  const [selectStatusService, setSelectStatusService] = useState("DEFAULT");

  const [statusComingSoon, setStatusComingSoon] = useState(false);
  const [selectStatusComingSoon, setSelectStatusComingSoon] =
    useState("DEFAULT");

  const [statusMultiServices, setStatusMultiServices] = useState(false);
  const [selectStatusMultiServices, setSelectStatusMultiServices] =
    useState("DEFAULT");

  const [isUpdateCreateService, setIsUpdateCreateService] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [isError, setIsError] = useState(false);

  const location: any = useLocation();
  const history: any = useHistory();
  useEffect(() => {
    if (location.state !== undefined) {
      setIsUpdateCreateService(true);
      setService(location.state.service);
      setIdService(location.state.idService);
      setDisclaimer(location.state.description);
      setStatusService(location.state.active);
      setLogoString(location.state.image);

      setStatusService(location.state.active);
      setSelectStatusService(location.state.active ? "1" : "0");

      setSelectStatusComingSoon(location.state.comingSoon ? "1" : "0");
      setStatusComingSoon(location.state.comingSoon);

      setSelectStatusMultiServices(location.state.multiServices ? "1" : "0");
      setStatusMultiServices(location.state.comingSoon);

      setServiceUnit(location.state.serviceUnit);

      setOrder(location.state.order);
    }
  }, []);

  const modalText = [
    {
      modalTitle: "Service",
      modalBody: "Service created succesfully",
    },
    {
      modalTitle: "Service",
      modalBody: "Choose a status to service",
    },
    {
      modalTitle: "Service",
      modalBody: "Service updated succesfully",
    },
    {
      modalTitle: "Service not available",
      modalBody: "Try again later",
    },
  ];

  const onChangeModal = (value: any) => {
    setModal(value);
    if(!isError) {
      history.goBack();
    } else {
      setIsError(false);
    }
  };

  const onSubmitFormCompanyService = (e: any) => {
    e.preventDefault();
    if (selectStatusService == "DEFAULT" || selectStatusService == "") {
      setModalBody(modalText[1].modalBody);
      setModalTitle(modalText[1].modalTitle);
      setModal(true);
      setIsError(true);
    } else {
      if (isUpdateCreateService) {
        updateService();
      } else {
        addService();
      }
    }
  };

  const addService = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        Service: service,
        Description: disclaimer,
        Active: statusService,
        Image: logoString,
        ComingSoon: statusComingSoon,
        MultiServices: statusMultiServices,
        ServiceUnit: serviceUnit,
        Order: order,
      };
      const request = await server.post(
        "/admin/services",
        JSON.stringify(data),
        {
          headers,
        }
      );

      const response = request.data;
      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setIsError(true);

      setModalTitle(modalText[3].modalTitle);
      setModalBody(modalText[3].modalBody);
      setModal(true);
    }
  };

  const updateService = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        Service: service,
        Description: disclaimer,
        Active: statusService,
        Image: logoString,
        ComingSoon: statusComingSoon,
        MultiServices: statusMultiServices,
        ServiceUnit: serviceUnit,
        Order: order,
      };

      const request = await server.put(
        `/admin/services/${idService}`,
        JSON.stringify(data),
        {
          headers,
        }
      );
      setIsLoading(false);
      setModalTitle(modalText[2].modalTitle);
      setModalBody(modalText[2].modalBody);
      setModal(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setIsError(true);

      setModalTitle(modalText[3].modalTitle);
      setModalBody(modalText[3].modalBody);
      setModal(true);
    }
  };

  const onChangeService = (e: any) => {
    setService(e.target.value);
  };

  const onChangeServiceUnit = (e: any) => {
    setServiceUnit(e.target.value);
  };

  const onChangeDisclaimer = (e: any) => {
    setDisclaimer(e.target.value);
  };

  const onChangeOrder = ({target: {value}}) => {
    setOrder(value);
  }

  const onChangeSelectService = (e: any) => {
    setSelectStatusService(e.target.value);
    if (e.target.value == "1") {
      setStatusService(true);
    } else {
      setStatusService(false);
    }
  };

  const onChangeSelectComingSoon = (e: any) => {
    setSelectStatusComingSoon(e.target.value);
    if (e.target.value == "1") {
      setStatusComingSoon(true);
    } else {
      setStatusComingSoon(false);
    }
  };

  const onChangeSelectMultiServices = (e: any) => {
    setSelectStatusMultiServices(e.target.value);
    if (e.target.value == "1") {
      setStatusMultiServices(true);
    } else {
      setStatusMultiServices(false);
    }
  };

  const onChangeLogo = (e: any) => {
    setLogo(e.target.value);
    var fullPath = e.target.value;
    var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
    var filename = fullPath.substring(startIndex);
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
    }

    setLogoName(filename);

    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded;
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoaded = (readerEvt: any) => {
    let binaryString = readerEvt.target.result;
    setLogoString(btoa(binaryString));
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle
        title={isUpdateCreateService ? "Update a Service" : "Create a Service"}
      />
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">
              {isUpdateCreateService ? "Update a Service" : "Create a Service"}
            </h1>
          </div>
          <div className="card-body-create">
            <form
              action=""
              onSubmit={onSubmitFormCompanyService}
              className="form-container"
            >
              <div className="form-container-input">
                <span className="label-create-input">Service Name:</span>
                <input
                  className="input-form-create"
                  type="text"
                  name="service"
                  id="service"
                  onChange={onChangeService}
                  autoComplete="off"
                  placeholder="Service name..."
                  value={service}
                  required
                />
              </div>
              <div className="form-container-input">
                <span className="label-create-input">Disclaimer:</span>
                <textarea
                  className="text-form-create"
                  name="description"
                  id="description"
                  placeholder="Description..."
                  onChange={onChangeDisclaimer}
                  value={disclaimer}
                />
              </div>
              <div className="form-container-input">
                <span className="label-create-input">Is a Coming Soon?</span>
                <select
                  name="selectComingSoon"
                  id="selectComingSoon"
                  className="input-form-create select-create"
                  onChange={onChangeSelectComingSoon}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={selectStatusComingSoon}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Choose an option --
                  </option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
              <div className="form-container-input">
                <span className="label-create-input">Is a MultiServices?</span>
                <select
                  name="selectMultiServices"
                  id="selectMultiServices"
                  className="input-form-create select-create"
                  onChange={onChangeSelectMultiServices}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={selectStatusMultiServices}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Choose an option --
                  </option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
              <div className="form-container-input">
                <span className="label-create-input">Order:</span>
                <input
                  className="input-form-create"
                  type="number"
                  name="order"
                  id="order"
                  onChange={onChangeOrder}
                  autoComplete="off"
                  placeholder="Order..."
                  value={order}
                  required
                />
              </div>
              <div className="form-container-input">
                <span className="label-create-input">Status of service:</span>
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-create select-create"
                  onChange={onChangeSelectService}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={selectStatusService}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Choose an option --
                  </option>
                  <option value="0">Deactive</option>
                  <option value="1">Active</option>
                </select>
              </div>
              <div className="form-container-input">
                <span className="label-create-input">Service Unit:</span>
                <input
                  className="input-form-create"
                  type="text"
                  name="serviceUnit"
                  id="serviceUnit"
                  onChange={onChangeServiceUnit}
                  autoComplete="off"
                  placeholder="Service Unit..."
                  value={serviceUnit}
                  required
                />
              </div>
              <div className="form-container-input">
                <InputFile
                  onChange={onChangeLogo}
                  value={logo}
                  text={"Click to upload your logo"}
                  id={1}
                />
                {
                    logoName.length !== 0 ?
                        <span className="label-create-input">
                            <strong>File uploaded: </strong>{logoName}
                        </span>
                    : null
                }
              </div>
              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateService;
