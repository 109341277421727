import React, { useState } from 'react';
import moment from "moment";

import "./CardHistoryDetail.css";

interface CardItemProps {
  id: number,
  date: string,
  status: string,
  comment: string,
  user: string,
};

const CardHistoryDetail: React.FC<CardItemProps> = (
                                        {id=-1,
                                          date= '',
                                          status= '',
                                          comment= '',
                                          user=''}) => {

  const formatDate = (date: any) => moment(date).format("MMM. DD. YYYY");

  return (
    <>
      <ul className="list-history-info">
        <li className="list-item">
          <label className="fields-history">Update Date</label>
          <label className="fields-history-info">{formatDate(date)}</label>
        </li>
        <li className="list-item">
          <label className="fields-history">Status</label>
          <label className="fields-history-info">{status}</label>
        </li>
        <li className="list-item">
          <label className="fields-history">Comment</label>
          <label className="fields-history-info">{comment}</label>
        </li>
        <li className="list-item">
          <label className="fields-history">User</label>
          <label className="fields-history-info">{user}</label>
        </li>
      </ul>
    </>
  );
}

export default CardHistoryDetail;
