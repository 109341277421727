import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Input } from 'reactstrap';
import MyTitle from "../../components/MyTitle/MyTitle";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import { AppContext } from '../../data/state';
import server from '../../api/server';
import "./UpdateValue.css"

const UpdateValue: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [isActive, setIsActive] = useState(false);

  const [parameterId, setParameterId] = useState(0);
  const [parameterValue, setParameterValue] = useState("");
  const [newValue, setNewValue] = useState("");

  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  let history = useHistory();
  const location: any = useLocation();

  const modalText = [
    {
      modalTitle: "Value",
      modalBody: "Value updated successfully.",
    },
  ];

  const onSubmitUpdateValue = async (e: any) => {
    e.preventDefault();

    let data = {
      Value: newValue,
    };

    changeValue(data);
  };

  const changeValue = async(data: any) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`,
    }

    setIsActive(true);

    try {
      const responseServer = await server.put('/admin/parameters/'+parameterId,
        JSON.stringify(data),
        {headers: headers}
      );

      if(responseServer.data.statusCode !== 200) {
        setIsActive(false);
        console.log(responseServer.data.message);
        return;
      }

      setIsActive(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);

      setModal(true);
      setTimeout(() => {
        history.push({
          pathname: "/parameters",
        });
      }, 3000);
    } catch (error) {
      setIsActive(false);
      console.log("ERROR");
      return;
    }
  };

  const onChangeValue = (e: any) => {
    setNewValue(e.target.value);
  };

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  useEffect(() => {
    if (location.state !== undefined) {
      setParameterId(location.state.ParameterID);
      setParameterValue(location.state.ParameterValue);
      setNewValue(location.state.ParameterValue);
    }
  }, []);

  return(
    <>
      {isActive &&
          <Loading />
      }
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title="Update Value" />
      <div className="section-update-ticket">
        <div className="card-update-ticket">
          <div className="div-title-update-value">
            <h1 className="card-title">Update Value</h1>
            <p><strong>Parameter ID:</strong> {parameterId}</p>
          </div>
          <form
            action=""
            onSubmit={onSubmitUpdateValue}
            className="form-container-update"
          >
            <div className="update-value-section">
              <p className="text-comment-new-value">New value:</p>
              <Input
                type="text"
                className="text-area-new-value"
                id="exampleText"
                onChange={onChangeValue}
                value={newValue}
              />
            </div>
            <button
              className="button-submit-update-value"
              type="submit"
            >
              Update Value
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateValue;
