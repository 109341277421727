import React, { useState, useEffect } from "react";
import "./CardReview.css";

function CardReview({ user, rating, date, description }) {
  const [descriptionExpanse, setDescriptionExpanse] = useState(description);
  const [descriptionRender, setDescriptionRender] = useState(description);
  const [isEnabledArrow, setIsEnabledArrow] = useState(false);

  useEffect(() => {
    if(window.innerWidth < 600){
      if(description.length > 20){
        let descriptionTemp = descriptionExpanse;
        let descriptionReduce = descriptionTemp.slice(0, Math.round(descriptionTemp.length / 2));
        descriptionReduce += ".....";
        setDescriptionRender(descriptionReduce);
      }
    } else {
      setDescriptionRender(descriptionExpanse);
    }
  }, [])

  
  const showDescription = () => {
    if(description.length > 20){
      if(isEnabledArrow) {
        let descriptionTemp = descriptionExpanse;
        let descriptionReduce = descriptionTemp.slice(0, Math.round(descriptionTemp.length / 2));
        descriptionReduce += ".....";
        setDescriptionRender(descriptionReduce);
        setIsEnabledArrow(false);
      } else {
        setDescriptionRender(descriptionExpanse);
        setIsEnabledArrow(true);
      }
    }
  }

  const getScores = (num: number) => {
    let stars: any[] = [];
    const starSelected = (
      <img src="assets/home/star100.png" alt="img star" className="icon-star" />
    );
    const starUnSelected = (
      <img src="assets/home/star0.png" alt="img star" className="icon-star" />
    );
    for (let index = 1; index <= num; index++) {
      stars.push(starSelected);
    }
    for (let index = num + 1; index <= 5; index++) {
      stars.push(starUnSelected);
    }
    return (
      <>
        {stars.map((value, index) => {
          return <React.Fragment key={index}>{value}</React.Fragment>;
        })}
      </>
    );
  };

  return (
    <>
      <div className="item-review">
        <div className="col-user-review">
          <div className="container-user">
            <h3 className="name-user-review">{user}</h3>
            <div className="container-rating">{getScores(rating)}</div>
          </div>
        </div>
        <div className="col-description-review">
          <span className="date-description">{date}</span>
          <p className="description-review">{descriptionRender}</p>
        </div>
        <div className="btn-show-text show-arrow" onClick={showDescription}>
          <img src="assets/reviews/arrow_orange.png" alt="arrow orange" className={`img-arrow-review ${isEnabledArrow ? 'active-arrow': 'inactive-arrow'}`}/>
        </div>
      </div>
    </>
  );
}

export default CardReview;
