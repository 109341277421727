import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../data/state";

import "./CompanyService.css";

import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";

const CompanyService: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [fee, setFee] = useState("");
  const [selectService, setSelectService] = useState("DEFAULT");
  const [services, setServices] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [isUpdateCompanyService, setIsUpdateCompanyService] = useState(false);
  const [companyServiceId, setCompanyServiceId] = useState<any>('');

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  const location: any = useLocation();
  useEffect(() => {
    if (location.state !== undefined) {
      if (location.state.fee) {
        setIsUpdateCompanyService(true);
        setCompanyId(location.state.companyId);
        setFee(location.state.fee);
        setSelectService(location.state.servicesID);
        setCompanyServiceId(location.state.companyServiceId);
      } else {
        setCompanyId(location.state.companyId);
      }
    }
    requestData();
  }, []);

  const requestData = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get("/admin/services", {
        headers,
      });
      const response = request.data;
      setServices(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const modalText = [
    {
      modalTitle: "Company Service",
      modalBody: "Company Service created succesfully",
    },
    {
      modalTitle: "Service",
      modalBody: "Choose a service to continue",
    },
    {
      modalTitle: "Company Service",
      modalBody: "Company Service updated succesfully",
    },
  ];

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  const onSubmitFormCompanyService = (e: any) => {
    e.preventDefault();
    if (selectService == "DEFAULT" || selectService == "") {
      setModalBody(modalText[1].modalBody);
      setModalTitle(modalText[1].modalTitle);
      setModal(true);
    } else {
      if (isUpdateCompanyService) {
        updateCompanyService(companyServiceId, fee);
      } else {
        addCompanyService();
      }
    }
  };

  const addCompanyService = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        CompanyID: companyId,
        ServicesID: selectService,
        Fee: fee,
        Active: true,
      };
      const request = await server.post(
        "/admin/company-services",
        JSON.stringify(data),
        {
          headers,
        }
      );

      const response = request.data;

      if (response.data) {
        updateCompanyService(response.data.id,fee);
      } else {
        setIsLoading(false);
        setModalTitle(modalText[0].modalTitle);
        setModalBody(modalText[0].modalBody);
        setModal(true);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const updateCompanyService = async (companyServiceId, fee) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        Fee: fee,
        Active: true,
      };
      const request = await server.put(
        `/admin/company-services/${companyServiceId}`,
        JSON.stringify(data),
        {
          headers,
        }
      );
      setIsLoading(false);
      setModalTitle(modalText[2].modalTitle);
      setModalBody(modalText[2].modalBody);
      setModal(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const onChangeFee = (e: any) => {
    setFee(e.target.value);
  };

  const onChangeSelectService = (e: any) => {
    setSelectService(e.target.value);
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title="Company service" />
      <section className="company-service-section">
        <div className="card-company-service">
          <div className="card-head-company-service">
            <h1 className="title-card-company-service">
              {isUpdateCompanyService
                ? "Update Company Service"
                : "Create Company Service"}
            </h1>
          </div>
          <div className="card-body-company-service">
            <form
              action=""
              onSubmit={onSubmitFormCompanyService}
              className="form-container"
            >
              <div className="form-container-input">
                <input
                  className="input-form-company-service"
                  type="number"
                  name="fee"
                  id="fee"
                  min={0.0}
                  max={1.0}
                  step={0.01}
                  placeholder="Fee..."
                  onChange={onChangeFee}
                  autoComplete="off"
                  value={fee}
                  required
                />
              </div>
              <div className="form-container-input">
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-company-service select-company-service"
                  onChange={onChangeSelectService}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={selectService}
                  disabled={isUpdateCompanyService ? true : false}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Select a service --
                  </option>
                  {services.map((value: any, index) => {
                    return (
                      <option value={value.id} key={index}>
                        {value.service}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="btn-container-company-service">
                <button type="submit" className="btn-submit-company-service">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyService;
