import React from "react";
import "./Home.css";

const Home: React.FC = () => {
  
  return (
    <section className="home-page-container">
      <img className="logo-image" src="assets/Transparent-PNG-01.png" alt="Company log" />
    </section>
  );
};

export default Home;

