import React, { useEffect, useState, useRef } from "react";
import MyTitle from "../../components/MyTitle/MyTitle";
import CardReview from "../../components/CardReview/CardReview";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import server from "../../api/server";
import moment from "moment";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";

import "./Reviews.css";

const Reviews: React.FC = () => {
  const [reviews, setReviews] = useState<any[]>([]);
  const [reviewsRaw, setReviewsRaw] = useState<any[]>([]);
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const [selectValue, setSelectValue] = useState("");
  const [pagination, setPagination] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);


  const [carouselPagination, setCarouselPagination] = useState([]);

  const selectRef = useRef<any>(null);

  useEffect(() => {
    setIsLoading(true);
    loadReviews();
  }, []);

  const loadReviews = async () => {
    try {
      const response = await server.get("/customer/reviews/up-keep");
      let data = response.data.data;
      setReviewsRaw(data);
      let dataPaginate = paginateArray(data, pageSize, page);
      setReviews(dataPaginate);
      setCarousel(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
        setIsLoading(false);
    }
  };

  const setCarousel = (data: any) => {
    let dataCarousel: any = [];
    let paginationTemp = numPagination(data, pageSize);
    setPagination(paginationTemp);
    for (let i = 0; i < paginationTemp; i++) {
      dataCarousel.push(`Page ${i + 1} out of ${paginationTemp}`);
    }
    setCarouselPagination(dataCarousel);
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === carouselPagination.length - 1 ? 0 : activeIndex + 1;
      if (pagination != 1) {
        window.scrollTo(0, 0);
        onClickIndicatorNext(nextIndex + 1);
      }
      setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? carouselPagination.length - 1 : activeIndex - 1;
      if (pagination != 1) {
        window.scrollTo(0, 0);
        onClickIndicatorPrev(nextIndex + 1);
      }
      setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: any) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const formatDate = (date: any) => moment(date).format("MMM. DD. YYYY");

  const paginateArray = (array: any, page_size: any, page_number: any) => {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  const numPagination = (items, page_size) =>
    Math.ceil(items.length / page_size);

  const onClickIndicatorPrev = (activeIndex: number) => {
    let dataPaginate = paginateArray(reviewsRaw, pageSize, activeIndex);
    setReviews(dataPaginate);
  };

  const onClickIndicatorNext = (activeIndex: number) => {
    let dataPaginate = paginateArray(reviewsRaw, pageSize, activeIndex);
    setReviews(dataPaginate);
  };

  const onSubtmitForm = (e: any) => {
    e.preventDefault();
    setAnimating(false);
    setActiveIndex(0);
    setIsLoading(true);
    let dataFilter = reviewsRaw.filter(
      (value) => value.upKeepScore >= selectValue
    );

    if(dataFilter.length != 0){
      let dataPaginate = paginateArray(dataFilter, pageSize, 1);
      setReviews(dataPaginate);
      setCarousel(dataPaginate);
      setTimeout(() => {
        setIsLoading(false);
      },1000);
    } else {
      let childsSelect: any [] = Array.from(selectRef.current.childNodes);
      childsSelect = childsSelect.map((value) => {
        value.selected = false;
        return value;
      });
      childsSelect[0].selected = true;
      setModal(true);
      setIsLoading(false);
      let dataPaginate = paginateArray(reviewsRaw, pageSize, 1);
      setReviews(dataPaginate);
      setCarousel(dataPaginate);
    }
  };

  const onChangeSelect = (e: any) => {
    setSelectValue(e.target.value);
  };

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle="Not found reviews"
        modalBody="Choose another option"
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title="Reviews" />
      <section className="section-reviews">
        <form className="form-reviews" onSubmit={onSubtmitForm}>
          <div className="container-select">
            <select
              name="rating"
              id="rating"
              className="select-reviews"
              style={{
                backgroundImage:
                  "url('assets/home/arrow_down.png'), url('assets/reviews/filter_gray.png')",
              }}
              defaultValue={"DEFAULT"}
              onChange={onChangeSelect}
              ref={selectRef}
            >
              <option value="DEFAULT" disabled>
                Star Rating
              </option>
              <option value="1">&#9733; &#9734; &#9734; &#9734; &#9734; or more</option>
              <option value="2">&#9733; &#9733; &#9734; &#9734; &#9734; or more</option>
              <option value="3">&#9733; &#9733; &#9733; &#9734; &#9734; or more</option>
              <option value="4">&#9733; &#9733; &#9733; &#9733; &#9734; or more</option>
              <option value="5">&#9733; &#9733; &#9733; &#9733; &#9733;</option>
            </select>
            <button className="btn-reviews-form">
              <img
                src="assets/reviews/filter.svg"
                alt="img filter"
                className="img-filter-reviews"
              />
              <span>FILTER</span>
            </button>
          </div>
        </form>

        <div className="container-reviews">
          {/* Items */}
          {reviews.map((value, index) => {
            return (
              <CardReview
                date={formatDate(value.reviewDate)}
                rating={value.upKeepScore}
                description={value.upKeepComment}
                user={value.userName}
                key={index}
              />
            );
          })}
          <div className="container-carousel">
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              className="carousel-review"
              interval={false}
            >
              <CarouselIndicators
                items={carouselPagination}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
                className="indicator-review"
              />
              {carouselPagination.map((value, index) => {
                return (
                  <CarouselItem
                    className=""
                    tag="div"
                    key={index}
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                  >
                    <div className="pagination">
                      <p>{value}</p>
                    </div>
                  </CarouselItem>
                );
              })}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
                className="control-previous"
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
                className="control-next"
              />
            </Carousel>
          </div>
        </div>
      </section>
    </>
  );
};

export default Reviews;
