import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../data/state";
import "./MyFooter.css";

const Footer = () => {
  const { state, dispatch } = useContext(AppContext);

  const fooItems = [
    {
      id: 1,
      title: 'TICKETS',
      locationHref: '/list-tickets'
    },
    {
      id: 2,
      title: 'COMPANY',
      locationHref: '/list-companies'
    },
    {
      id: 3,
      title: 'SERVICES',
      locationHref: '/list-services'
    },
    {
      id: 4,
      title: 'ZIP CODES',
      locationHref: '/list-zip-codes'
    },
    {
      id: 5,
      title: 'USERS',
      locationHref: '/list-users'
    },
  ];

  return (
    <>
      <footer className="my-footer">
        <div className="container-footer">
          <img src="assets/logo_white.png" alt="logo" />
          <ul className="nav-footer">
          {
            state.permissions !== undefined &&
            fooItems.map((element) => {
              if(state.permissions.includes(element.id)) {
                return (
                  <li>
                    <Link to={element.locationHref} onClick={() => window.scrollTo(0, 0)}>
                      {element.title}
                    </Link>
                  </li>
                )
              }
            })
          }
          </ul>
          <span className="copyright-text">
            Copyright &copy; 2022 UpKeepFilm. All rights reserved
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
