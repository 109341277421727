import React from "react";
import "./BtnAddList.css";
export default function BtnAddList({ label, onClick }) {
  return (
    <div className="row-add-btn">
      <div className="container-btn" onClick={onClick}>
        <button className="btn-add">
          <span className="icon-btn">+</span>
        </button>
        <span className="label-btn">{label}</span>
      </div>
    </div>
  );
}
