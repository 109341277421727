import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../data/state";

import "./CreatePaymentReq.css";
import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import MyDatePicker from "../../components/MyDatePicker/MyDatePicker";

const CreatePaymentReq: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [comments, setComments] = useState<string>("");
  const [companyID, setCompanyID] = useState<string>("");
  const [listRequestStatus, setListRequestStatusID] = useState([]);
  const [isExpress, setIsExpress] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalBody, setModalBody] = useState<string>("");
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const location: any = useLocation();
  const history: any = useHistory();
  useEffect(() => {
    if (location.state !== undefined) {
      setCompanyID(location.state.companyID);
      requestDatesPayment(location.state.companyID);
    }
  }, []);

  const modalText = [
    {
      modalTitle: "Payment Request",
      modalBody: "Payment Request created succesfully",
    },
  ];

  const onChangeModal = (value: any) => {
    setModal(value);
    if (!isError) {
      history.goBack();
    }
  };

  const onSubmitFormCompanyLocation = (e: any) => {
    e.preventDefault();
    addCompanyPayments();
  };

  const onChangeComments = (e: any) => {
    setComments(e.target.value);
  };

  const onCheckInputExpress = ({ target: { checked } }) => {
    setIsExpress(checked);
  };

  const requestDatesPayment = async (companyID) => {
    setIsLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get(
        `/admin/payment-request/request-dates/company/${companyID}`,
        {
          headers,
        }
      );
      const {
        data: {
          data: { startDate: startDateReq, endDate: endDateReq },
        },
      } = request;
      setStartDate(new Date(startDateReq));
      setEndDate(new Date(endDateReq));
      setIsLoading(false);
    } catch (error: any) {
      const response = error.response;
      console.error(response);
      setIsLoading(false);
    }
  };

  const addCompanyPayments = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        Comments: comments,
        IsExpress: isExpress,
      };
      const request = await server.post(
        `/admin/payment-request/${companyID}`,
        JSON.stringify(data),
        {
          headers,
        }
      );

      const response = request.data;
      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } catch (error: any) {
      const response = error.response;
      const message = response.data.message;
      setIsLoading(false);

      setIsError(true);

      if (response.data.status === 400) {
        setModalBody("Company does not have enough Balance");
      } else {
        setModalBody(message);
      }
      setModalTitle(modalText[0].modalTitle);
      setModal(true);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title={"Create Payment Request"} />
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">Create Payment Request</h1>
          </div>
          <div className="card-body-create">
            <form
              action=""
              onSubmit={onSubmitFormCompanyLocation}
              className="form-container"
            >
              <div className="form-container-input">
                <span className="label-input-create">Comments: </span>
                <textarea
                  className="text-form-create"
                  name="comments"
                  id="comments"
                  onChange={onChangeComments}
                  autoComplete="off"
                  placeholder="Comments..."
                  value={comments}
                  required
                />
              </div>
              <div className="form-container-input">
                <span className="label-input-create">Start Date: </span>
                <MyDatePicker
                  startDate={startDate}
                  setDatePicker={setStartDate}
                  disabled
                />
              </div>
              <div className="form-container-input">
                <span className="label-input-create">End Date: </span>
                <MyDatePicker
                  startDate={endDate}
                  setDatePicker={setEndDate}
                  disabled
                />
              </div>
              <div className="form-container-input">
                <div className="container-check-express">
                  <label htmlFor="express" className="label-check-express">
                    Is an express payment?
                  </label>
                  <input
                    type="checkbox"
                    id="express"
                    onChange={onCheckInputExpress}
                  />
                </div>
              </div>
              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreatePaymentReq;
