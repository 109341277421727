import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../data/state";
import "./CreateCompany.css";
import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import InputFile from "../../components/InputFile/InputFile";
import DatePicker from "react-datepicker";
import moment from "moment";
import { TIMEOUT } from "dns";

const CreateCompany: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [idCompany, setIdCompany] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPosition, setContactPosition] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [email, setEmail] = useState("");
  const [fee, setFee] = useState(0.0);
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [zipCodeValid, setZipCodeValid] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminPosition, setAdminPosition] = useState("");
  const [adminPhone, setAdminPhone] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [bank, setBank] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [license, setLicense] = useState("");
  const [licenseString, setLicenseString] = useState("");
  const [licenseNotification, setLicenseNotification] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [insuranceNotification, setInsuranceNotification] = useState("");

  const [insuranceExpiration, setInsuranceExpiration] = useState(new Date());
  const [insuranceExpirationSelected, setInsuranceExpirationSelected] = useState(false);

  const [insurancePolicy, setInsurancePolicy] = useState("");
  const [insurancePolicyString, setInsurancePolicyString] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");

  const [companyStatusID, setCompanyStatusID] = useState(1);
  const [logoString, setLogoString] = useState("");
  const [logo, setLogo] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const [statusList, setStatusList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  const [isUpdateCompany, setIsUpdateCompany] = useState(false);

  const location: any = useLocation();
  let history = useHistory();

  const formatDate = (date: any) => moment(date).format("YYYY-MM-DD HH:mm");

  useEffect(() => {
    if (location.state !== undefined) {
      setIsUpdateCompany(true);
      setIdCompany(location.state.idCompany);
      getCompany(location.state.idCompany);
    }
    getCompanyStatusList();
  }, []);

  const modalText = [
    {
      modalTitle: "Company",
      modalBody: "Company created successfully.",
    },
    {
      modalTitle: "Company found",
      modalBody: "Company already registered, Choose another email.",
    },
    {
      modalTitle: "Company",
      modalBody: "Company updated successfully.",
    },
    {
      modalTitle: "Company",
      modalBody: "You must complete all the fields..",
    },
  ];

  const getCompanyStatusList = async() =>{
    setIsLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get('/admin/company-status', {headers});
      const response = request.data;
      let content = response.data.map((value) => {
        return value;
      })
      setStatusList(content);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setModalBody("Error retrieving status list");
      setIsLoading(false);
      setModal(true);
    }
  }

  const onChangeModal = async(value: any) => {
    setModal(value);
  };

  const onSubmitFormCompany = async (e: any) => {
    e.preventDefault();

    if(zipCode === "") {
      setZipCodeValid(true);
    } else {
      validateZipCode(zipCode);
    }

    if(zipCodeValid) {
      let data = {
        CompanyName: companyName,
        CompanyStatusID: selectStatus,
        ContactName: contactName,
        ContactPosition: contactPosition,
        ContactPhone: contactPhone,
        ContactEmail: contactEmail,
        Address: address,
        ZIPCode: zipCode,
        PhoneNumber: phoneNumber,
        Email: email,
        Fee: fee,
        AdminName: adminName,
        AdminPosition: adminPosition,
        AdminPhone: adminPhone,
        AdminEmail: adminEmail,
        Bank: bank,
        BankAccount: bankAccount,
        License: licenseString,
        LicenseNotification: licenseNotification,
        LicenseNumber: licenseNumber,
        InsuranceCompany: insuranceCompany,
        InsuranceNotification: insuranceNotification,
        InsuranceExpiration: formatDate(insuranceExpiration),
        InsurancePolicy: insurancePolicyString,
        PolicyNumber: policyNumber ,
        Logo: logoString,
      };

      if (!isUpdateCompany) {
        addCompany(data);
      } else {
        updateCompany(data);
      }
    } else {
      setModalTitle("Invalid fields or empty.");
      setModalBody("Make sure to fill all required fields and a valid zip code.");
      setModal(true);
    }
  };

  const addCompany = async (data: any) => {
    try {
      setIsLoading(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.post("/admin/company", JSON.stringify(data), {
        headers: headers,
      });

      const response = request.data;

      if (response.statusCode != 200) {
        return;
      }

      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);

      setIsLoading(false);
      setModal(true);
      setTimeout(()=>{}, 3000);
      history.push('/list-companies');
    } catch (error) {
      setIsLoading(false);

      const response = error.response;
      if (response) {
        switch (response.status) {
          case 400: {
            setModalTitle(modalText[3].modalTitle);
            setModalBody(modalText[3].modalBody);

            setModal(true);
            break;
          }
          case 401: {
            break;
          }
        }
      }
    }
  };

  const getCompany = async (id: any) => {
    try {
      setIsLoading(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get(`/admin/company/${id}`,
         {headers: headers,}
      );

      const response = request.data;

      setCompanyName(response.data.companyName);
      setCompanyStatusID(response.data.companyStatusID);
      setSelectStatus(response.data.companyStatusID);
      setContactName(response.data.contactName);
      setContactPosition(response.data.contactPosition);
      setContactPhone(response.data.contactPhone);
      setContactEmail(response.data.contactEmail);
      setAddress(response.data.address);
      setZipCode(response.data.zipCode);
      setPhoneNumber(response.data.phoneNumber);
      setEmail(response.data.email);
      setFee(response.data.fee);
      setAdminName(response.data.adminName);
      setAdminPosition(response.data.adminPosition);
      setAdminPhone(response.data.adminPhone);
      setAdminEmail(response.data.adminEmail);
      setBank(response.data.bank);
      setBankAccount(response.data.bankAccount);
      setLicenseString(response.data.license);
      setLicenseNotification(response.data.licenseNotification);
      setLicenseNumber(response.data.licenseNumber);
      setInsuranceCompany(response.data.insuranceCompany);
      setInsuranceNotification(response.data.insuranceNotification);
      setInsuranceExpiration(new Date(response.data.insuranceExpiration));
      setInsuranceExpirationSelected(true);
      setInsurancePolicyString(response.data.insurancePolicy);
      setPolicyNumber(response.data.policyNumber);
      setLogoString(response.data.logo);

      if (response.statusCode != 200) {
        return;
      }

      setTimeout(() => {}, 3000);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const validateZipCode = async (zipCodeInput: any) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };

      const request = await server.get(`/zip-codes/code/${zipCodeInput}`,
         {headers: headers,}
      );

      const response = request.data;

      if (response.statusCode == 200) {
        setZipCodeValid(true);
      }
    } catch (error) {
      setZipCodeValid(false);
      console.error(error);
    }
  };

  const updateCompany = async (data: any) => {
    try {
      setIsLoading(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.put(`/admin/company/${idCompany}`,
        JSON.stringify(data), {headers: headers,}
      );

      const response = request.data;

      if (response.statusCode != 200) {
        return;
      }

      setModalTitle(modalText[2].modalTitle);
      setModalBody(modalText[2].modalBody);

      setIsLoading(false);
      setModal(true);
      setTimeout(() => {
        history.push('/list-companies');
      }, 3000);

    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const onChangeCompanyName = (e: any) => {
    setCompanyName(e.target.value);
  };

  const onChangeContactName = (e: any) => {
    setContactName(e.target.value);
  };

  const onChangeContactPosition = (e: any) => {
    setContactPosition(e.target.value);
  };

  const onChangeContactPhone = (e: any) => {
    setContactPhone(e.target.value);
  };

  const onChangeContactEmail = (e: any) => {
    setContactEmail(e.target.value);
  };

  const onChangeAddress = (e: any) => {
    setAddress(e.target.value);
  };

  const onChangeZipCode = (e: any) => {
    setZipCode(e.target.value);
    setZipCodeValid(true);
  };

  const onChangePhoneNumber = (e: any) => {
    setPhoneNumber(e.target.value);
  };

  const onChangeAdminName = (e: any) => {
    setAdminName(e.target.value);
  };

  const onChangeAdminPosition = (e: any) => {
    setAdminPosition(e.target.value);
  };

  const onChangeAdminPhone = (e: any) => {
    setAdminPhone(e.target.value);
  };

  const onChangeAdminEmail = (e: any) => {
    setAdminEmail(e.target.value);
  };

  const onChangeBank = (e: any) => {
    setBank(e.target.value);
  };

  const onChangeBankAccount = (e: any) => {
    setBankAccount(e.target.value);
  };

  const onChangeLicense = (e: any) => {
    setLicense(e.target.value);
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoadedLicense;
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoadedLicense = (readerEvt: any) => {
    let binaryString = readerEvt.target.result;
    setLicenseString(btoa(binaryString));
  };

  const onChangeLicenseNotification = (e: any) => {
    setLicenseNotification(e.target.value);
  };

  const onChangeLicenseNumber = (e: any) => {
    setLicenseNumber(e.target.value);
  };

  const onChangeInsuranceCompany = (e: any) => {
    setInsuranceCompany(e.target.value);
  };

  const onChangeInsuranceNotification = (e: any) => {
    setInsuranceNotification(e.target.value);
  };

  const onChangeInsuranceExpiration = (e: any) => {
    setInsuranceExpirationSelected(true);
    setInsuranceExpiration(e);
  };

  const onChangeInsurancePolicy = (e: any) => {
    setInsurancePolicy(e.target.value);
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoadedInsurancPolicy;
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoadedInsurancPolicy = (readerEvt: any) => {
    let binaryString = readerEvt.target.result;
    setInsurancePolicyString(btoa(binaryString));
  };

  const onChangePolicyNumber = (e: any) => {
    setPolicyNumber(e.target.value);
  };

  const onChangeFee = (e: any) => {
    setFee(parseFloat(e.target.value));
  };

  const onChangeLogo = (e: any) => {
    setLogo(e.target.value);
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoadedLogo;
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoadedLogo = (readerEvt: any) => {
    let binaryString = readerEvt.target.result;
    setLogoString(btoa(binaryString));
  };

  const onChangeSelectStatus = (e: any) => {
    if(e.target.value != "0") {
      setSelectStatus(e.target.value);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title="Company" />
      <section className="company-section">
        <div className="card-company">
          <div className="card-head">
            <h1 className="title-company">
              {isUpdateCompany ? "Update company" : "Create company"}
            </h1>
          </div>
          <div className="card-body-company">
            <form
              action=""
              onSubmit={onSubmitFormCompany}
              className="form-container"
            >
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Company name</label>
                <input
                  className="input-form-company"
                  type="text"
                  name="companyName"
                  id="companyName"
                  placeholder="Company name..."
                  onChange={onChangeCompanyName}
                  autoComplete="off"
                  value={companyName}
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">* Contact name</label>
                <input
                  className="input-form-company"
                  type="text"
                  name="contact"
                  id="contact"
                  placeholder="Contact name..."
                  onChange={onChangeContactName}
                  autoComplete="off"
                  value={contactName}
                  required
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">* Contact position</label>
                <input
                  className="input-form-company"
                  type="text"
                  name="contact"
                  id="contact"
                  placeholder="Contact position..."
                  onChange={onChangeContactPosition}
                  autoComplete="off"
                  value={contactPosition}
                  required
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">* Contact phone</label>
                <input
                  className="input-form-company"
                  type="number"
                  name="contact"
                  id="contact"
                  placeholder="Contact phone..."
                  onChange={onChangeContactPhone}
                  autoComplete="off"
                  value={contactPhone}
                  required
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">* Contact email</label>
                <input
                  className="input-form-company"
                  type="email"
                  name="contact"
                  id="contact"
                  placeholder="Contact email..."
                  onChange={onChangeContactEmail}
                  autoComplete="off"
                  value={contactEmail}
                  required
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Address</label>
                <input
                  className="input-form-company"
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Address..."
                  onChange={onChangeAddress}
                  autoComplete="off"
                  value={address}
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Zip Code</label>
                <input
                  className="input-form-company"
                  type="number"
                  name="zipCode"
                  id="zipCode"
                  placeholder="Zip code..."
                  onChange={onChangeZipCode}
                  autoComplete="off"
                  value={zipCode}
                />
              </div>
              {
                !zipCodeValid ?
                <div className="form-container-input-create-company">
                  <label className="zipcode-text">Invalid zip code.</label>
                </div>
                : null
              }
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Phone Number</label>
                <input
                  className="input-form-company"
                  type="number"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Phone number..."
                  onChange={onChangePhoneNumber}
                  autoComplete="off"
                  value={phoneNumber}
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">* Email</label>
                <input
                  className="input-form-company"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email..."
                  onChange={onChangeEmail}
                  autoComplete="off"
                  value={email}
                  required
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Fee:</label>
                <input
                  className="input-form-company fee"
                  type="number"
                  name="fee"
                  id="fee"
                  placeholder="Fee..."
                  onChange={onChangeFee}
                  autoComplete="off"
                  min="0.0"
                  max="1.0"
                  step="0.01"
                  value={fee}
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Admin Name</label>
                <input
                  className="input-form-company"
                  type="text"
                  name="adminName"
                  id="adminName"
                  placeholder="Admin name..."
                  onChange={onChangeAdminName}
                  autoComplete="off"
                  value={adminName}
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Admin Position</label>
                <input
                  className="input-form-company"
                  type="text"
                  name="position"
                  id="position"
                  placeholder="Admin position..."
                  onChange={onChangeAdminPosition}
                  autoComplete="off"
                  value={adminPosition}
                  required
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Admin Phone</label>
                <input
                  className="input-form-company"
                  type="number"
                  name="adminPhone"
                  id="adminPhone"
                  placeholder="Admin phone..."
                  onChange={onChangeAdminPhone}
                  autoComplete="off"
                  value={adminPhone}
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Admin Email</label>
                <input
                  className="input-form-company"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Admin email..."
                  onChange={onChangeAdminEmail}
                  autoComplete="off"
                  value={adminEmail}
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Bank</label>
                <input
                  className="input-form-company"
                  type="text"
                  name="bank"
                  id="bank"
                  placeholder="Bank..."
                  onChange={onChangeBank}
                  autoComplete="off"
                  value={bank}
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Banck Account</label>
                <input
                  className="input-form-company"
                  type="text"
                  name="bankAccount"
                  id="bankAccount"
                  placeholder="Bank account..."
                  onChange={onChangeBankAccount}
                  autoComplete="off"
                  value={bankAccount}
                />
              </div>
              <div className="form-container-input-create-company">
                <InputFile
                  onChange={onChangeLicense}
                  value={license}
                  text={"Click to upload your license"}
                  key={1}
                  id={1}
                />
                {
                  licenseString ?
                  <div className="photo-container-create-company">
                    <img
                      className="card-image-create-company license"
                      src={licenseString ? "data:image;base64,"+licenseString : ''}
                    />
                  </div>
                  : null
                }
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">License Notification</label>
                <input
                  className="input-form-company"
                  type="text"
                  name="licenseNotification"
                  id="licenseNotification"
                  placeholder="License notification..."
                  onChange={onChangeLicenseNotification}
                  autoComplete="off"
                  value={licenseNotification}
                  required
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">License Number</label>
                <input
                  className="input-form-company"
                  type="number"
                  name="licenseNumber"
                  id="licenseNumber"
                  placeholder="License number..."
                  onChange={onChangeLicenseNumber}
                  autoComplete="off"
                  value={licenseNumber}
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Insurance Company</label>
                <input
                  className="input-form-company"
                  type="text"
                  name="insuranceCompany"
                  id="insuranceCompany"
                  placeholder="Insurance company..."
                  onChange={onChangeInsuranceCompany}
                  autoComplete="off"
                  value={insuranceCompany}
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Insurance notification</label>
                <input
                  className="input-form-company"
                  type="text"
                  name="insuranceNotification"
                  id="insuranceNotification"
                  placeholder="Insurance notification..."
                  onChange={onChangeInsuranceNotification}
                  autoComplete="off"
                  value={insuranceNotification}
                  required
                />
              </div>
              <div className="form-container-input-create-company">
                <label className="insurance-label">Insurance expiration : </label>
                <DatePicker
                  placeholderText={"Insurance Expiration"}
                  selected={insuranceExpirationSelected ? insuranceExpiration : null}
                  onChange={(date) => onChangeInsuranceExpiration(date)}
                  selectsStart
                  className="expiration-date"
                />
              </div>
              <div className="form-container-input-create-company">
                <InputFile
                  onChange={onChangeInsurancePolicy}
                  value={insurancePolicy}
                  text={"Click to upload your insurance policy"}
                  key={2}
                  id={2}
                />
                {
                  insurancePolicyString ?
                  <div className="photo-container-create-company">
                    <img
                      className="card-image-create-company insurance"
                      src={insurancePolicyString ? "data:image;base64,"+insurancePolicyString : ''}
                    />
                  </div>
                  : null
                }
              </div>
              <div className="form-container-input-create-company">
                <label className="label-input-create-company">Policy Number</label>
                <input
                  className="input-form-company"
                  type="text"
                  name="policyNumber"
                  id="policyNumber"
                  placeholder="Policy number..."
                  onChange={onChangePolicyNumber}
                  autoComplete="off"
                  value={policyNumber}
                />
              </div>
              <div className="form-container-input-create-company">
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-company-service select-company-service"
                  onChange={onChangeSelectStatus}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={selectStatus}
                >
                  <option value="0">Select Company</option>
                  {statusList.map((value: any, index) => {
                    return (
                      <option value={value.id} key={index}>
                        {value.status}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-container-input-create-company">
                <InputFile
                  onChange={onChangeLogo}
                  value={logo}
                  text={"Click to upload your logo"}
                  key={3}
                  id={3}
                />
                {
                  logoString ?
                  <div className="photo-container-create-company">
                    <img
                      className="card-image-create-company license"
                      src={logoString ? "data:image;base64,"+logoString : ''}
                    />
                  </div>
                  : null
                }
              </div>
              <div className="btn-container-company">
                <button type="submit" className="btn-submit-company">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateCompany;
