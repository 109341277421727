import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../data/state";

import "./CreateLocationZip.css";
import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import moment from "moment";

const CreateLocationZip: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  let history = useHistory();

  const [isError, setIsError] = useState<boolean>(false);

  const [stateID, setStateID] = useState("DEFAULT");
  const [listState, setListStates] = useState([]);

  const [majorCityId, setMajorCityId] = useState("DEFAULT");
  const [listMajorCiy, setListMajorCiy] = useState([]);

  const [cityID, setCityID] = useState("DEFAULT");
  const [listCity, setListCity] = useState([]);

  const [zipCodeID, setZipCodeID] = useState("DEFAULT");
  const [listZipCode, setListZipCode] = useState([]);

  const [timeUnitID, setTimeUnitID] = useState("DEFAULT");
  const [listTimeUnit, setListTimeUnit] = useState([]);


  const [isUpdateCreateLocationZip, setIsUpdateCreateLocationZip] =
    useState(false);

  const [idCompanyLocation, setIdCompanyLocation] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  const location: any = useLocation();

  useEffect(() => {
    if (location.state !== undefined) {
      if (location.state.zipCodeID) {
        setIsUpdateCreateLocationZip(true);
        setIdCompanyLocation(location.state.companyLocationID);
        setZipCodeID(location.state.zipCodeID);
      } else {
        setIdCompanyLocation(location.state.companyLocationID);
      }
      getStates();
      getTimeUnit();
    }
  }, []);

  const getStates = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get("/customer/state", {
        headers,
      });

      const response = request.data;
      setListStates(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getMajorCityByState = async (id: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get(`customer/major-city/state/${id}`, {
        headers,
      });

      const response = request.data;
      setListMajorCiy(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getCities = async (majorId: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get(`/customer/city/major-city/${majorId}`, {
        headers,
      });

      const response = request.data;
      setListCity(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const getZipCodes = async (cityId: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get(`/zip-codes/city/${cityId}`, {
        headers,
      });

      const response = request.data;
      setListZipCode(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getTimeUnit = async () => {
    try {
      try {
        setIsLoading(true);
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.token}`,
        };
        const request = await server.get(`/customer/time-units`, {
          headers,
        });

        const response = request.data;
        setListTimeUnit(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  const modalText = [
    {
      modalTitle: "Company Location ZIP",
      modalBody: "Company Location ZIP created succesfully",
    },
    {
      modalTitle: "Company Location ZIP",
      modalBody: "Fill out the form",
    },
    {
      modalTitle: "Company Location ZIP",
      modalBody: "Company Location ZIP updated succesfully",
    },
  ];

  const onChangeModal = (value: any) => {
    setModal(value);
    if(!isError){
      history.goBack();
    } else {
      setIsError(false);
    }
  };

  const onSubmitFormCompanyLocationZip = (e: any) => {
    e.preventDefault();
    if (
      stateID == "DEFAULT" || timeUnitID == "DEFAULT"
    ) {
      setModalBody(modalText[1].modalBody);
      setModalTitle(modalText[1].modalTitle);
      setModal(true);
      setIsError(true);
    } else {
      let data:any = {
        CompanyLocationID: idCompanyLocation
      };
      if(stateID != "DEFAULT"){
        data.StateID = stateID;
      }
      if(majorCityId != "DEFAULT"){
        data.MajorCityID = majorCityId;
      }
      if(cityID != "DEFAULT"){
        data.CityID= cityID;
      }
      if(zipCodeID != "DEFAULT"){
        data.ZIPCodesID= zipCodeID;
      }
      if(timeUnitID != "DEFAULT") {
        data.TimeUnitID= timeUnitID;
      }
      addCompanyLocationZIP(data);
    }
  }

  const addCompanyLocationZIP = async (data:any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.post(
        "/admin/company-location-zip",
        JSON.stringify(data),
        {
          headers,
        }
      );

      const response = request.data;
      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const onChangeSelectState = (e: any) => {
    let id = e.target.value;
    setStateID(id);
    setMajorCityId("DEFAULT");
    setCityID("DEFAULT");
    setZipCodeID("DEFAULT");
    getMajorCityByState(id);
  };

  const onChangeSelectMajorCity= (e: any) => {
    let id = e.target.value;
    setMajorCityId(id);
    setCityID("DEFAULT");
    setZipCodeID("DEFAULT");
    getCities(id);
  };

  const onChangeSelectCity = (e: any) => {
    let id = e.target.value;
    setCityID(id);
    setZipCodeID("DEFAULT");
    getZipCodes(id);
  };

  const onChangeSelectZipCode = (e: any) => {
    let id = e.target.value;
    setZipCodeID(id);
  };

  const onChangeSelectTimeUnit = (e: any) => {
    let id = e.target.value;
    setTimeUnitID(id);
  };

  const formatHours = (time: any) => {
    let timeArray: any [] = time.split(":");
    timeArray.splice(timeArray.length-1,1);
    return timeArray.join(":") + " hrs";
};

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle
        title={
          isUpdateCreateLocationZip
            ? "Update Location Zip"
            : "Create Location Zip"
        }
      />
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">
              {isUpdateCreateLocationZip
                ? "Update a Location Zip"
                : "Create a Location Zip"}
            </h1>
          </div>
          <div className="card-body-create">
            <form
              action=""
              onSubmit={onSubmitFormCompanyLocationZip}
              className="form-container"
            >
              <div className="form-container-input">
                <span className="label-input-create">State: </span>
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-create select-create"
                  onChange={onChangeSelectState}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={stateID}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Select state --
                  </option>
                  {listState.map((stateObj: any, index: number) => {
                    return (
                      <option key={index} value={stateObj.id}>
                        {stateObj.stateName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-container-input">
                <span className="label-input-create">Time between Jobs: </span>
                <select
                  name="selectTimeUnit"
                  id="selectTimeUnit"
                  className="input-form-create select-create"
                  onChange={onChangeSelectTimeUnit}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={timeUnitID}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Choose an option --
                  </option>
                  {listTimeUnit.map((timeUnitObj: any, index: number) => {
                    return (
                      <option key={index} value={timeUnitObj.id}>
                        {formatHours(timeUnitObj.duration)}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-container-input">
                <span className="label-input-create">Major City: </span>
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-create select-create"
                  onChange={onChangeSelectMajorCity}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={majorCityId}
                  disabled={stateID == "DEFAULT" ? true : false}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Select Major City --
                  </option>
                  {listMajorCiy.map((majorCityObj: any, index: number) => {
                    return (
                      <option key={index} value={majorCityObj.id}>
                        {majorCityObj.majorCityName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-container-input">
                <span className="label-input-create">City: </span>
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-create select-create"
                  onChange={onChangeSelectCity}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={cityID}
                  disabled={majorCityId == "DEFAULT" ? true : false}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Select city --
                  </option>
                  {listCity.map((cityObj: any, index: number) => {
                    return (
                      <option key={index} value={cityObj.id}>
                        {cityObj.cityName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-container-input">
                <span className="label-input-create">Zip Codes: </span>
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-create select-create"
                  onChange={onChangeSelectZipCode}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={zipCodeID}
                  disabled={cityID == "DEFAULT" ? true : false}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Select Zip Code --
                  </option>
                  {listZipCode.map((zipCodeObj: any, index: number) => {
                    return (
                      <option key={index} value={zipCodeObj.id}>
                        {zipCodeObj.zipCode}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateLocationZip;
