import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import MyTitle from "../../components/MyTitle/MyTitle";
import Loading from "../../components/Loading/Loading";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import { AppContext } from "../../data/state";
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";
import BtnAddList from "../../components/BtnAddList/BtnAddList";
import server from '../../api/server';
import moment from "moment";
import { saveAs } from 'file-saver';
import "./Balance.css"

const Balance: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [isActive, setIsActive] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalOfBalances, setTotalOfBalances] = useState(0);

  const [allBalances, setAllBalances] = useState<any[]>([]);
  const [currentBalance, setCurrentBalance] = useState(0);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startDateSelected, setStartDateSelected] = useState(false);
  const [endDateSelected, setEndDateSelected] = useState(false);
  const [reference, setReference] = useState("");

  const formatDate = (date: any) => moment(date).format("YYYY-MM-DD");
  const formatDateAndTime = (date: any) => moment(date).format("YYYY-MM-DD hh:mm");

  let history = useHistory();

  useEffect(() => {
    getCurrentBalance();

    var startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    setStartDate(startDate);

    setStartDateSelected(true);
    setEndDateSelected(true);
    
    var endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    setEndDate(endDate);
    requestData(startDate, endDate, "", 1);
  }, []);


  const requestData = async (
    start: any,
    end: any,
    reference: any,
    currentPage: number
  ) => {
    try {
      setIsActive(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      let requestString = "/admin/upkeep-balance?PageNumber="+currentPage
                          +"&_pageSize="+pageSize;

      requestString += "&StartDate="+formatDate(start);
      requestString += "&EndDate="+formatDate(end);

      if(reference !== "") {
        requestString += "&Reference="+reference;
      }

      const request = await server.get(requestString,
                                    {headers: headers}
                                  );

      const response = request.data;

      setTotalOfBalances(response.pages.totalCount);
      dataFormat(response.data);

      setIsActive(false);
    } catch (error) {
      setIsActive(false);
      const response = error.response;
      console.error(error);
    }
  };

  const getCurrentBalance = async () => {
    try {
      setIsActive(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get("/admin/upkeep-balance/current",
                                    {headers: headers}
                                  );

      const response = request.data;

      setCurrentBalance(response.data);

      setIsActive(false);
    } catch (error) {
      setIsActive(false);
      const response = error.response;
      console.error(error);
    }
  };

  const dataFormat = (upkeepBalance: any) => {
    let table: any = [];

    let content = upkeepBalance.map((value) => {
      return formatDateAndTime(value.date);
    });

    let date = {
      data: { title: "Date", content },
      button: false,
    };

    content = upkeepBalance.map((value) => {
      return value.description;
    });

    let description = {
      data: { title: "Description", content },
      button: false,
    };

    content = upkeepBalance.map((value) => {
      return value.reference;
    });

    let reference = {
      data: { title: "Reference", content },
      button: false,
    };

    content = upkeepBalance.map((value) => {
      return `$ ${value.charges.toFixed(2)}`;
    });

    let charges = {
      data: { title: "Charge", content },
      button: false,
    };

    content = upkeepBalance.map((value) => {
      return `$ ${value.payments.toFixed(2)}`;
    });

    let payments = {
      data: { title: "Payments", content },
      button: false,
    };

    table.push(date);
    table.push(description);
    table.push(reference);
    table.push(charges);
    table.push(payments);

    setAllBalances(table);
  };

  const paginate = async (e:any) => {
    window.scroll({top: 0, left: 0, behavior: 'smooth' });
    setCurrentPage(e.selected+1);
    requestData(startDate, endDate, reference, e.selected+1);
  };

  const onChangeReference = (r: any) => {
    setReference(r.target.value);
    if(startDateSelected && endDateSelected) {
      requestData(startDate, endDate, r.target.value, 1);
    }
  };

  const onChangePickerStart = (s: any) => {
    setStartDateSelected(true);
    setCurrentPage(1);
    setStartDate(s);
    if(endDateSelected) {
      requestData(s, endDate, reference, 1);
    }
  };

  const onChangePickerEnd = (e: any) => {
    setEndDateSelected(true);
    setCurrentPage(1);
    setEndDate(e);
    if(startDateSelected) {
      requestData(startDate, e, reference, 1);
    }
  };

  const onClickButtonRestore = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setStartDateSelected(false);
    setEndDateSelected(false);
    setCurrentPage(1);
    setReference("");
    setAllBalances([]);
  };

  const onClickDownloadBalance = async () => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`,
    };

    const data = {
      StartDate: formatDate(startDate),
      EndDate: formatDate(endDate),
    };

    setIsActive(true);
    try {
      const responseServer = await server.post('/admin/upkeep-balance/export',
        JSON.stringify(data),
        {
          headers: headers,
          responseType: 'blob'
        }
      );

      if(responseServer.status !== 200) {
        setIsActive(false);
        console.log(responseServer.data.message);
        return;
      }
      const pdfBlob = new Blob([responseServer.data], { type: 'application/csv'});
      saveAs(pdfBlob, formatDate(startDate)+","+formatDate(endDate)+'.csv');

      const response = responseServer.data;
      setIsActive(false);
    } catch (error) {
      setIsActive(false);
      console.log(error);
      return;
    }
  };

  const addBalanceBtn = () => {
    history.push('/add-balance');
  };

  return (
    <>
      {isActive &&
          <Loading />
      }
      <div className="balance-div">
        <MyTitle title="UpKeep Balance" />
        <div className="current-balance-div">
          <p className="label-current-balance-view">
            <strong>Current Balance:</strong>
          </p>
          <p className="label-current-balance-view">
            {`$ ${currentBalance.toFixed(2)}`}
          </p>
        </div>
      </div>
      <div className="add-balance-button">
        <BtnAddList label="Add Balance" onClick={addBalanceBtn} />
      </div>
      <div className="filters-container-balance">
        <div className="balance-filters-container">
          <label>Start Date:</label>
          <DatePicker
            placeholderText={"Start Date"}
            selected={startDateSelected ? startDate : null}
            onChange={(date) => onChangePickerStart(date)}
            selectsStart
            className="calendar-filter"
          />
          <label>End Date:</label>
          <DatePicker
            placeholderText={"End Date"}
            selected={endDateSelected ? endDate : null}
            onChange={(date) => onChangePickerEnd(date)}
            startDate={startDate}
            minDate={startDate}
            selectsEnd
            className="calendar-filter"
          />
          <input
             type="text"
             placeholder="Search By Reference"
             className="filter-by-reference"
             value={reference}
             onChange={onChangeReference}
           />
        </div>
        <div className="buttons-cointainer-balance">
          <button
            className="reset-balance-values"
            onClick={onClickButtonRestore}
          >
            CLEAR FILTERS
          </button>
          <button
            className="download-excel"
            onClick={onClickDownloadBalance}
          >
            Download Balance
          </button>
        </div>
      </div>
      <section className="balance-section">
      {
        totalOfBalances > 0 ?
          <ScheduleList data={allBalances} />
        :
          <p className="">
            Select a range of dates to display information.
          </p>
      }

      </section>
      <div>
        {
          totalOfBalances > 0 ?
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={'previous'}
              previousLinkClassName={'page-link'}
              nextLinkClassName={'page-link'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={Math.ceil(totalOfBalances/pageSize)}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={paginate}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              forcePage={currentPage-1}
            />
          </div>
          : null
        }
      </div>
    </>
  );
}

export default Balance;
