import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../data/state";
import { User } from "../../Models/User";
import MyTitle from "../../components/MyTitle/MyTitle";
import MyModal from "../../components/MyModal/MyModal";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";

import "./SignUp.css";

const SignUp: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(0);
  const [addres, setAddres] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [modal, setModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  let history = useHistory();

  const location: any = useLocation();

  const modalText = [
    {
      modalTitle: "Passwords do not match",
      modalBody: "Write the passwords again",
    },
    {
      modalTitle: "User found",
      modalBody: "User already registered, please log in",
    },
  ];

  useEffect(() => {
    if (location.state !== undefined) {
      setEmail(location.state.inputEmail);
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } else {
      setIsActive(true);
      // Send request
      const data = {
        Name: name,
        Email: email,
        PhoneNumber: phone,
        Password: password,
        Address: addres,
      };
      setIsActive(true);
      const headers = {
        "Content-Type": "application/json",
      };
      try {
        const res = await server.post("/customer", JSON.stringify(data), {
          headers: headers,
        });
        const response = res.data;
        if (response.statusCode != 200) {
          return;
        }
        let userRetrieved: User = response.data.user;

        await dispatch({
          type: "set-is-loggedin",
          isLoggedin: true,
        });
        await dispatch({
          type: "set-user",
          user: userRetrieved,
        });
        await dispatch({
          type: "set-token",
          token: response.data.token,
        });
        setIsActive(false);
        window.scrollTo(0, 0);
        history.replace("/home");
        setIsActive(false);
      } catch (error) {
        setIsActive(false);
        const response = error.response;
        if (response) {
          switch (response.status) {
            case 400: {
              setModalTitle(modalText[1].modalTitle);
              setModalBody(modalText[1].modalBody);
              setModal(true);
            }
          }
        }
      }
    }
  };

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  return (
    <>
      {isActive && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <div className="information-div">
        <MyTitle title="Sign Up" />
        <Container className="information-container">
          <Row className="information-row">
            <Col className="information-img-signup information-img">
              <div className="information-logo-img">
                <img src="assets/signup/questions2.jpg" className="logo" />
              </div>
            </Col>
            <Col className="personal-form-signup personal-form">
              <div className="titles">
                <label className="title-label">Personal Info:</label>
              </div>
              <div className="contact-inputs">
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Input
                      type="text"
                      name="text"
                      className="form-input"
                      placeholder="Name"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="email"
                      value={email}
                      name="email"
                      className="form-input"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="number"
                      name="number"
                      className="form-input"
                      placeholder="Phone"
                      onChange={(e) => setPhone(parseInt(e.target.value, 10))}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="text"
                      name="addres"
                      className="form-input"
                      placeholder="Address"
                      onChange={(e) => setAddres(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="password"
                      name="password"
                      className="form-input"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="password"
                      name="confirmPassword"
                      className="form-input"
                      placeholder="Confirm password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <Button className="form-submit">
                    <strong>Sign Up</strong>
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SignUp;
