import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import MyTitle from "../../components/MyTitle/MyTitle";
import Loading from "../../components/Loading/Loading";
import { AppContext } from "../../data/state";
import server from "../../api/server";
import MyModal from "../../components/MyModal/MyModal";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import BtnAddList from "../../components/BtnAddList/BtnAddList";

import "./ListPriority.css";

const ListPriority: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [listServices, setListServices] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  let history = useHistory();
  const location: any = useLocation();

  useEffect(() => {
    if (location.state !== undefined) {
        requestData(location.state.idService)
    } else {
        history.goBack()
    }
  }, []);

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  const requestData = async (idService: string) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get(`/admin/service-priority/service/${idService}`, {
        headers,
      });
      const response = request.data;
      let table: any = [];

      let content = response.data.map((value) => {
        return value.id;
      });
      let idCompany = {
        data: { title: "ID", content },
        button: false,
      };
      

      content = response.data.map((value) => {
        return value.companyService.company.companyName;
      });
      let nameCompany = {
        data: { title: "Name Company", content },
        button: false,
      };


      content = response.data.map((value) => {
        return value.priority;
      });
      let priority = {
        data: { title: "Priority", content },
        button: false,
      };

      content = response.data.map((value) => {
        const {companyService: {company: {companyName}}} = value;
        return {
          button: [
            {
              label: "EDIT",
              onPressed: () => updatePriority(value.id, value.priority, companyName),
            },
          ],
        };
      });
      let buttons = {
        data: { title: "Buttons", content },
        button: true,
      };

      table.push(idCompany);
      table.push(nameCompany);
      table.push(priority);
      table.push(buttons);
      setListServices(table);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const updatePriority = (idPriority: any, priority: any, nameCompany: any) => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/create-priority",
      state: {
        idPriority,
        priority,
        nameCompany,
      },
    });
  };
  return (
    <>
      <MyTitle title="List Priority" />
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      {/* <div className="container-btn-services">
        <BtnAddList label="Add service" onClick={addService} />
      </div> */}
      <section className="section-list-services">
        <ScheduleList data={listServices} />
      </section>
    </>
  );
};

export default ListPriority;
