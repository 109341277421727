import React from "react";

import "./BtnAction.css";

export default function BtnAction({label, action}) {
  return (
    <div className="container-btn-action">
      <button className="btn-action" onClick={action}>{label}</button>
    </div>
  );
}
