import moment from "moment";

import "./CardTicket.css";

interface CardItemProps {
  id: number,
  name: string,
  email: string,
  phoneNumber: string,
  address: string,
  totalPrice: number,
  createdDate: string,
  scheduledDate: string,
  service: string,
  status: string,
  description: string,
  instructions: string,
  duration: string,
};

const CardTicket: React.FC<CardItemProps> = (
                                        {id=-1,
                                        name='',
                                        email='',
                                        phoneNumber='',
                                        address='',
                                        totalPrice=-1,
                                        createdDate='',
                                        scheduledDate='',
                                        service='',
                                        status='',
                                        description='',
                                        instructions='',
                                        duration=''}) => {

  const formatDate = (date: any) => moment(date).format("MMM. DD. YYYY, hh:mm A");

  return (
    <div className="ticket-div-container">
      <div className="div-title">
        <h1 className="card-title">Ticket Detail</h1>
      </div>
      <div className="card-ticket-info">
        <ul className="list-ticket-info">
          <li className="list-item">
            <label className="fields-ticket">Ticket Number</label>
            <label className="fields-ticket-info">{id}</label>
          </li>
          <li className="list-item">
            <label className="fields-ticket">Name</label>
            <label className="fields-ticket-info">{name}</label>
          </li>
          <li className="list-item">
            <label className="fields-ticket">Email</label>
            <label className="fields-ticket-info">{email}</label>
          </li>
          <li className="list-item">
            <label className="fields-ticket">Service</label>
            <label className="fields-ticket-info">{service}</label>
          </li>
          <li className="list-item">
            <label className="fields-ticket">Phone</label>
            <label className="fields-ticket-info">{phoneNumber}</label>
          </li>
          <li className="list-item">
            <label className="fields-ticket">Address</label>
            <label className="fields-ticket-info">{address}</label>
          </li>
          <li className="list-item">
            <label className="fields-ticket">Total Price</label>
            <label className="fields-ticket-info">${totalPrice}</label>
          </li>
          <li className="list-item">
            <label className="fields-ticket">Created Date</label>
            <label className="fields-ticket-info">{formatDate(createdDate)}</label>
          </li>
          <li className="list-item">
            <label className="fields-ticket">Scheduled Date & Time</label>
            <label className="fields-ticket-info">{formatDate(scheduledDate)}</label>
          </li>
          <li className="list-item">
            <label className="fields-ticket">Service Duration</label>
            <label className="fields-ticket-info">{duration}</label>
          </li>
          <li className="list-item">
            <label className="fields-ticket">Status</label>
            <label className="fields-ticket-info">{status}</label>
          </li>
          {
            instructions == null || instructions === "" ?
              null
            :
            <li className="list-item">
              <label className="fields-ticket">Instructions</label>
              <label className="fields-ticket-info">{instructions}</label>
            </li>

          }
          {
            description == null || description === "" ?
              null
            :
            <li className="list-item">
              <label className="fields-ticket">Description</label>
              <label className="fields-ticket-info">{description}</label>
            </li>
          }
        </ul>
      </div>
    </div>
  );
}

export default CardTicket;
