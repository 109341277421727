import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import CardService from "../../components/CardService/CardService";
import MyTitle from '../../components/MyTitle/MyTitle';
import Loading from "../../components/Loading/Loading";
import server from '../../api/server';
import "./Services.css";

const Services:React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [cardList, setCardList] = useState<any[]>([]);

  let history = useHistory();

  const handleGetServiceCards = async() => {
    setIsActive(true);
    await server.get('/customer/services/tasks',
    ).then(async (res) => {
      const response = res.data;
      setCardList(response.data);
      setIsActive(false);
    }).catch(error => {
      setIsActive(false);
      console.log("Error: " + error);
    })
  };

  let handleClick = (item) => history.push({
    pathname: '/questionnaire',
    state: {
      zipCode: '',
      service: item.service,
      serviceId: item.id
    }
  });

  useEffect(() => {
    handleGetServiceCards();
  }, []);

  return(
    <>
    {isActive &&
        <Loading />
    }
    <div>
      <MyTitle title="Services"/>
      <div className="services-title">
        <h1 className="services-title">Our <strong>Services</strong></h1>
      </div>
      <Container className="service-container">
        <Row className="services-row">
          {
            cardList &&
              cardList.map((item) => {
                return (
                  <Col className="card-col" key={item.id}>
                    <CardService
                      itemImg={item.imageString}
                      itemService={item.service}
                      itemList={item.tasks}
                      onClickButton={()=>handleClick(item)}
                      />
                  </Col>
              )
            })
          }
        </Row>
      </Container>
    </div>
    </>
  );
}

export default Services;
