import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import MyTitle from "../../components/MyTitle/MyTitle";
import MyDatePicker from "../../components/MyDatePicker/MyDatePicker";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";

import "./Questionnaire.css";
const Questionnaire: React.FC = () => {
  const [datePicker, setDatePicker] = useState(new Date());
  const [zipCode, setZipCode] = useState("");
  const [service, setService] = useState("");
  const [features, setFeatures] = useState<any[]>([]);
  const [featuresSelected, setFeaturesSelected] = useState<any[]>([]);
  const [serviceId, setServiceId] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const location: any = useLocation();
  let history = useHistory();

  const modalText = [
    {
      modalTitle: "Select features",
      modalBody: "Choose a features to continue",
    },
    {
      modalTitle: "Not found code zip",
      modalBody: "ZIP Code given not found or not allowed",
    },
  ];

  const onSubmitQuestionnaire = (e: any) => {
    e.preventDefault();
    if (featuresSelected.length !== features.length) {
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } else {
      validateZipCode();
    }
  };

  useEffect(() => {
    if (location.state !== undefined) {
      setZipCode(location.state.zipCode);
      setService(location.state.service);
      setServiceId(location.state.serviceId);
    }
    loadFeatures();
  }, []);

  const loadFeatures = async () => {
    setIsActive(true);
    try {
      const response = await server.get(
        `/customer/features/${location.state.serviceId}`
      );
      setFeatures(response.data.data);
      setIsActive(false);
    } catch (error) {
      console.error(error);
      setIsActive(false);
    }
  };

  const validateZipCode = async () => {
    setIsActive(true);
    try {
      await server.get(`/zip-codes/code/${zipCode}`);
      window.scrollTo(0, 0);
      let idFeatures: any[] = featuresSelected.map((value) => {
        return value.id;
      });
      setIsActive(false);
      history.push({
        pathname: "/companies",
        state: {
          date: datePicker,
          zipCode,
          service,
          serviceId,
          quantity,
          idFeatures,
        },
      });
    } catch (error) {
      setIsActive(false);
      setModalTitle(modalText[1].modalTitle);
      setModalBody(modalText[1].modalBody);
      setModal(true);
    }
  };

  const onChangeDatePicker = (value) => {
    setDatePicker(value);
  };

  const onChangeZipCode = (e: any) => {
    setZipCode(e.target.value.replace(/\D/, ""));
  };

  const onChangeFeature = (e: any, question: string, idQuestion: any) => {
    let featuresTemp = featuresSelected;
    let index = featuresTemp.findIndex((value) => value.question === question);
    let idFeature = 0;
    if (index !== -1) {
      index = features.findIndex((value) => value.question === question);
      let indexFeature = features[index].values.findIndex(
        (value) => value.value === e.target.value
      );
      featuresTemp[index].feature = e.target.value;
      featuresTemp[index].id = features[index].values[indexFeature].id;
      idFeature = featuresTemp[index].id;
    } else {
      index = features.findIndex((value) => value.question === question);
      let indexFeature = features[index].values.findIndex(
        (value) => value.value === e.target.value
      );
      idFeature = features[index].values[indexFeature].id;
      featuresTemp.push({
        question,
        idQuestion,
        feature: e.target.value,
        id: features[index].values[indexFeature].id,
      });
    }
    getNextValues(idQuestion, idFeature);
    setFeaturesSelected(featuresTemp);
  };

  const getNextValues = async (idQuestion: number, idFeature: number) => {
    setIsActive(true);
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      QuestionID: idQuestion,
      ValueID: idFeature,
    };
    try {
      const res = await server.post(
        "/customer/features/next-values",
        JSON.stringify(data),
        { headers }
      );
      const response = res.data.data;
      if (response.values.length != 0) {
        let indexQuestion = features.findIndex((questionRequest) => {
          return questionRequest.id === idQuestion;
        });
        let copyFeatures = features;
        copyFeatures.splice(indexQuestion + 1, 0, response);
        setFeatures(copyFeatures);
      }
      setIsActive(false);
    } catch (error) {
      console.error(error);
      setIsActive(false);
    }
  };

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  return (
    <>
      {isActive && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title="Questionnaire" />
      <section className="section-questionnaire">
        <h1 className="title-questionnaire">{service}</h1>
        <div className="card-questionnaire">
          <form
            action=""
            className="form-questionnaire"
            onSubmit={onSubmitQuestionnaire}
          >
            {features &&
              features.map((values, index) => {
                return (
                  <div className="row-container" key={index}>
                    <span className="label-input">{values.question}</span>
                    <div className="container-select-questionnaire">
                      <select
                        name={`${values.feature}`}
                        onChange={(e: any) =>
                          onChangeFeature(e, values.question, values.id)
                        }
                        className="input-questionnaire select-questionnaire"
                        style={{
                          backgroundImage: "url('assets/home/arrow_down.png')",
                        }}
                        defaultValue={"DEFAULT"}
                        required
                      >
                        <option value="DEFAULT" disabled>
                          Select a feature
                        </option>
                        {values.values.map((feature, index) => {
                          return (
                            <option
                              value={`${feature.value}`}
                              key={index}
                              disabled={!feature.active}
                              data-feature-id={feature.id}
                            >
                              {feature.value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                );
              })}
            <div className="row-container">
              <span className="label-input">Please choose a date:</span>
              <div className="container-select-questionnaire">
                <MyDatePicker
                  setDatePicker={onChangeDatePicker}
                  startDate={datePicker}
                />
              </div>
            </div>
            <div className="row-container">
              <span className="label-input">Enter your code zip:</span>
              <div className="container-select-questionnaire">
                <input
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  className="input-questionnaire"
                  value={zipCode}
                  autoComplete="off"
                  onChange={onChangeZipCode}
                  required
                />
              </div>
            </div>
            <div className="container-btn-questionnaire">
              <button type="submit" className="btn-submit-questionnaire">
                <span>SUBMIT</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Questionnaire;
