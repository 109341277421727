import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Button, Form, FormGroup, Input} from 'reactstrap';
import { useHistory } from "react-router-dom";

import { ListGroup, ListGroupItem } from 'reactstrap';

import Loading from "../../components/Loading/Loading";
import MyModal from '../../components/MyModal/MyModal';
import server from '../../api/server';

import MyTitle from '../../components/MyTitle/MyTitle'
import "./Contact.css";

const Contact:React.FC =()=>{
  const [name,setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] =  useState('');
  const [redirect, setRedirect] = useState(false);

  let history = useHistory();

  const modalText = [
    {
      modalTitle: "Thank you!",
      modalBody: "We will review your message!",
    },
    {
      modalTitle: "Service not available",
      modalBody: "Try again later",
    },
  ];

  const onSubmitForm = (e:any) => {
    e.preventDefault();
    sendData();
  }

  const sendData = async () => {
    setIsActive(true);
    const headers = {
      'Content-Type': 'application/json',
    }
    const data = {
      Name: name,
      Email: email,
      Phone: phone,
      Message: message 
    }
    try {
      await server.post('/customer/contact', JSON.stringify(data), { headers });
      setIsActive(false);
      setModalBody(modalText[0].modalBody);
      setModalTitle(modalText[0].modalTitle);
      setRedirect(true);
      setModal(true);
    } catch (error) {
        console.log(error);
        setModalBody(modalText[1].modalBody);
        setModalTitle(modalText[1].modalTitle);
        setModal(true);
        setIsActive(false);
    }
  }

  const onChangeName = (e:any) => {
    setName(e.target.value);
  }

  const onChangeEmail = (e:any) => {
    setEmail(e.target.value);
  }

  const onChangePhone = (e:any) => {
    setPhone(e.target.value);
  }

  const onChangeMessage = (e:any) => {
    setMessage(e.target.value);
  }

  const onChangeModal = (value: any) => {
    setModal(value);
    if(redirect){
      window.scrollTo(0, 0);
      history.push('/home');
    }
  };

  return(
    <>
      {isActive &&
        <Loading />
      }
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <div className="contact-div">
        <MyTitle title="Contact us"/>
        <Container className="contact-container">
          <Row className="contact-row">
            <Col className="contact-info">
              <div className="contact-logo-img">
                <img src="assets/logo_white.png" className="logo-contact"/>
              </div>
              <div className="contact-list">
                <ListGroup flush className="contact-list-group">
                  <ListGroupItem tag="a" href="#">
                    <div className="list-icon">
                      <img src="assets/contact/phone.png"/>
                    </div>
                    <div className="text-info">
                      <label className="label-info">
                        Phone number:
                      </label>
                      <label>
                      <strong>1-800-344-4555</strong>
                      </label>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#">
                    <div className="list-icon">
                      <img src="assets/contact/email.png"/>
                    </div>
                    <div className="text-info">
                      <label className="label-info">
                        Email address:
                      </label>
                      <label>
                      <strong>mail@upkeep.com</strong>
                      </label>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="#">
                    <div className="list-icon">
                      <img src="assets/contact/pobox.png" />
                    </div>
                    <div className="text-info">
                      <label className="label-info">
                        P.O Box:
                      </label>
                      <label>
                      <strong>18900 Corner loop 234, Manassas, VA</strong>
                      </label>
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </div>
            </Col>
            <Col className="contact-form">
              <div className="contact-title">
                <label className="title-label">Contact Us:</label>
              </div>
              <div className="contact-inputs">
                <Form onSubmit={onSubmitForm}>
                  <FormGroup>
                    <Input onChange={onChangeName} type="text" name="text" className="form-input" placeholder="Name" required/>
                  </FormGroup>
                  <FormGroup>
                    <Input onChange={onChangeEmail}  type="email" name="email" className="form-input" placeholder="Email" required/>
                  </FormGroup>
                  <FormGroup>
                    <Input onChange={onChangePhone} type="number" name="number" className="form-input" placeholder="Phone" required/>
                  </FormGroup>
                  <FormGroup>
                    <Input onChange={onChangeMessage} type="textarea" name="text" className="form-input form-textarea" placeholder="Message" required/>
                  </FormGroup>
                  <Button className="form-submit"><strong>SUBMIT</strong></Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Contact;
