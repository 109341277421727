import React from 'react'
import './InputTextComponent.css'

function InputTextComponent({ idName, name, value, handleChangeValue, inputType }) {
  return (
    <div className="input-container">
      <div className="inputs-form">
        {name}
      </div>
      <input type={inputType}
        name={idName}
        id={idName}
        className="select-reviews"
        value={value}
        onChange={handleChangeValue}
      />
    </div>
  )
}

export default InputTextComponent
