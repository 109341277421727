import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyDatePicker from "../../components/MyDatePicker/MyDatePicker";
import MyModal from "../../components/MyModal/MyModal";
import MyTime from "../../components/MyTime/MyTime";
import MyTitle from "../../components/MyTitle/MyTitle";
import { AppContext } from "../../data/state";

import "./BookingReschedule.css";

const formatDate = (date: any) => moment(date).format("YYYY-MM-DD");
const formatTime = (time: any) => moment(time, "HHmmss").format("hh:mm A");

const formatDuration = (duration: string) =>
  duration
    .split(":")
    .filter((value, index) => index !== 0 || value !== "00")
    .join(":");

const BookingReschedule: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [isFourOrEight, setIsFourOrEight] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [time, setTime] = useState("");
  const [timeId, setTimeId] = useState(-1);
  const [indexTime, setIndexTime] = useState(-1);

  const [duration, setDuration] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [ticketID, setticketID] = useState("");
  const [companyServiceId, setCompanyServiceId] = useState("");
  const [datePicker, setDatePicker] = useState(new Date());

  const [isJustified, setIsJustified] = useState(true);

  const [listTimes, setListTimes] = useState<any[]>([]);

  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [isError, setIsError] = useState(false);

  const location: any = useLocation();
  let history = useHistory();

  useEffect(() => {
    if (location.state !== undefined) {
      const {
        state: { ticketID },
      } = location;
      setticketID(ticketID);
      getTicketById(ticketID);
    }
  }, []);

  const getTicketById = async (ticketID: number) => {
    setIsLoading(true);
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };

    try {
      const responseServer = await server.get(`/admin/tickets/${ticketID}`, {
        headers,
      });

      if (responseServer.data.statusCode !== 200) {
        setIsLoading(false);
        console.log(responseServer.data.message);
        return;
      }
      const { data } = responseServer.data;
      const { duration, scheduledDate } = data;
      if (new Date(scheduledDate) < new Date()) {
        setDatePicker(new Date());
      } else {
        setDatePicker(new Date(scheduledDate));
      }

      setDuration(duration);

      const companyServiceId = await getCompanyServiceId(ticketID);
      setCompanyServiceId(companyServiceId);
      loadSchedules(companyServiceId, new Date(scheduledDate), duration);
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR");
      setModalTitle("Error!!!");
      setModalBody("Try again later");
      setModal(true);
      setIsError(true);
      return;
    }
  };

  const getCompanyServiceId = async (ticketID: number) => {
    const headers = {
      Authorization: `Bearer ${state.token}`,
    };

    try {
      const responseServer = await server.get(
        `/admin/company-services/ticket/${ticketID}`,
        { headers }
      );

      if (responseServer.data.statusCode !== 200) {
        setIsLoading(false);
        console.log(responseServer.data.message);
        return;
      }
      const { data } = responseServer.data;
      const { id } = data;

      return id;
    } catch (error: any) {
      return new Error(error);
    }
  };

  const loadSchedules = async (companyServiceId, datePicker, duration) => {
    if (!isLoading) setIsLoading(true);

    let data = {
      CompanyServiceID: companyServiceId,
      Date: formatDate(datePicker),
      Duration: duration,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const response = await server.post(
        "/customer/company-calendar/schedules",
        JSON.stringify(data),
        { headers }
      );
      setListTimes(response.data.data);
      const maxLength = 16;
      if (response.data.data.length < maxLength) {
        setIsFourOrEight(true); // Four Columns
      } else {
        setIsFourOrEight(false); // Eight Columns
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const onChangeSelectTimes = (values: any, index) => {
    if (formatTime(values.startTime) === time) {
      setTime("");
    } else {
      setTime(formatTime(values.startTime));
      setTimeId(values.id);
      setIndexTime(index);
    }
  };

  const onSubmitBooking = () => {
    if (timeId === -1) {
      setModalTitle("Reschedule");
      setModalBody("Choose a time");
      setModal(true);
      setIsError(true);
    } else {
      rescheduleTicket();
    }
  };

  const onChangeDatePicker = (value) => {
    setDatePicker(value);
    loadSchedules(companyServiceId, value, duration);
  };

  const rescheduleTicket = async () => {
    setIsLoading(true);
    const data = {
      newDate: formatDate(datePicker),
      ScheduleID: timeId,
      IsJustified: isJustified,
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const response = await server.put(
        `/admin/tickets/reschedule/${ticketID}`,
        JSON.stringify(data),
        { headers }
      );

      setIsLoading(false);
      setModalTitle("Reschedule");
      setModalBody("Ticket rescheduled successfully");
      setModal(true);
    } catch (error: any) {
      setIsLoading(false);
      console.log("ERROR");
      setModalTitle("Error!!!");
      setModalBody("Try again later");
      setModal(true);
      setIsError(true);
    }
  };

  const onCheckInputJustified = ({ target: { checked } }) => {
    setIsJustified(checked);
  };

  const onChangeModal = (value: any) => {
    if (!isError) {
      history.goBack();
      window.scrollTo(0, 0);
    } else {
      setIsError(false);
    }
    setModal(value);
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyTitle title="Reschedule" />
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <section className="section-booking-reschedule">
        <div className="card-booking-reschedule">
          <div className="container-date-reschedule">
            <h2 className="text-card-booking-reschedule">
              Please choose a date below:{" "}
            </h2>
            <MyDatePicker
              setDatePicker={onChangeDatePicker}
              startDate={datePicker}
            />
          </div>
          <div className="container-time-reschedule">
            <h2 className="text-card-booking-reschedule">
              Please choose a time below:{" "}
            </h2>
            <div
              className="list-items-time-reschedule"
              style={{
                paddingLeft: isFourOrEight ? "14em" : "0em",
                paddingRight: isFourOrEight ? "14em" : "0em",
              }}
            >
              {listTimes.length != 0 ? (
                listTimes.map((values, index) => {
                  if (index === indexTime) {
                    return (
                      <MyTime
                        time={formatTime(values.startTime)}
                        onChangeListTimes={() =>
                          onChangeSelectTimes(values, index)
                        }
                        key={index}
                        index={index}
                        valueSelected={true}
                        isAvailable={values.isAvailable}
                      />
                    );
                  } else {
                    return (
                      <MyTime
                        time={formatTime(values.startTime)}
                        onChangeListTimes={() =>
                          onChangeSelectTimes(values, index)
                        }
                        key={index}
                        index={index}
                        valueSelected={false}
                        isAvailable={values.isAvailable}
                      />
                    );
                  }
                })
              ) : (
                <div className="message-not-found-reschedule">
                  <h3>No available schedules for this day</h3>
                </div>
              )}
            </div>

            <div className="container-check-justified">
              <label htmlFor="justified" className="label-check-justified">
                Is Justified Reschedule?
              </label>
              <input
                type="checkbox"
                id="justified"
                defaultChecked
                onChange={onCheckInputJustified}
              />
            </div>

            <button
              className="btn-section-booking-reschedule"
              onClick={onSubmitBooking}
            >
              <span>BOOK</span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingReschedule;
