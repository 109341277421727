import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Button, Spinner } from 'reactstrap';
import { useLocation } from "react-router-dom";
import { AppContext } from '../../data/state';
import MyTitle from '../../components/MyTitle/MyTitle';
import Loading from "../../components/Loading/Loading";
import server from '../../api/server';
import "./PaymentConfirmation.css";

const PaymentConfirmation:React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [ticketId, setTicketId] = useState(0);
  const { state, dispatch } = useContext(AppContext);

  const handleGetTicket = async() => {
    setIsActive(true);

    const headers = {
      'Authorization': `Bearer ${state.token}`
    }

    await server.get('/customer/ticket/'+ticketId, {headers: headers}
    ).then(async (res) => {
      const response = res.data;
      setName(response.data.name);
      setEmail(response.data.email);
      setPhoneNumber(response.data.phoneNumber);
      setAddress(response.data.address);
      setIsActive(false);
    }).catch(error => {
      setIsActive(false);
      console.log("Error: " + error);
    })
  };

  const onSubmit = async(e:any) => {
    setIsActive(false);
  };

  useEffect(() => {
    if (state.existTicketId !== false) {
      setTicketId(state.ticketID);
      handleGetTicket();
    }
  }, []);

  return (
    <>
    {isActive &&
        <Loading />
    }
    <div className="confirmation-div">
      <MyTitle title="Confirmation"/>
      <Container className="confirm-container">
        <div className="confirmation-title">
          <label className="title-text">Payment Confirmation</label>
          <p className="p-text">Thank you, your payment has been successful.</p>
          <p className="p-text">A confirmation email has been sent to <a className="">{email}</a></p>
        </div>
        <div className="confirm-img-div">
          <img src="assets/payment_confirmation/paymentconfirmation.svg" className="confirm-img"/>
        </div>
        <div className="confirm-info">
          <ul className="list-info">
            <li className="list-item">
              <label className="fields">Name</label><label className="fields-info">{name}</label>
            </li>
            <li className="list-item">
              <label className="fields">Email</label><label className="fields-info">
                <a className="">{email}</a>
              </label>
            </li>
            <li className="list-item">
              <label className="fields">Phone</label><label className="fields-info">
                <a className="">{phoneNumber}</a>
              </label>
            </li>
            <li className="list-item">
              <label className="fields">Address</label><label className="fields-info">{address}</label>
            </li>
            <li className="list-item">
              <label className="fields">Ticket Id</label><label className="fields-info">{ticketId}</label>
            </li>
          </ul>
        </div>
         <form onSubmit={onSubmit} className="form-reset-password">
           <Button
             type="submit"
             className="btn-signin download-button-ticket"
           >
           {isActive
              ? <Spinner />
              : "Download ticket"
            }
           </Button>
         </form>
      </Container>
    </div>
    </>
  );
}

export default PaymentConfirmation;
