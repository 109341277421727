import React, { useState, useEffect, useContext } from "react";
import MyTitle from "../../components/MyTitle/MyTitle";
import Loading from "../../components/Loading/Loading";
import { AppContext } from "../../data/state";
import server from "../../api/server";
import MyModal from "../../components/MyModal/MyModal";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import BtnAddList from "../../components/BtnAddList/BtnAddList";

import "./ListServices.css";
import { useHistory } from "react-router";

const ListServices: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [listServices, setListServices] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  let history = useHistory();

  useEffect(() => {
    requestData();
  }, []);

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  const addService = () => {
    window.scrollTo(0, 0);
    history.push("/create-service");
  };

  const requestData = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get(`/admin/services`, {
        headers,
      });
      const response = request.data;
      let table: any = [];
      let content = response.data.map((value) => {
        return value.service;
      });
      let services = {
        data: { title: "Service", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.description;
      });
      let descriptions = {
        data: { title: "Disclaimer", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.comingSoon ? 'Yes' : 'No';
      });
      let comingSoon = {
        data: { title: "Is Coming Soon?", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.active ? "Active" : "Deactive";
      });
      let status = {
        data: { title: "Status", content },
        button: false,
      };

      content = response.data.map((value) => {
        const service = value;
        return {
          button: [
            {
              label: "EDIT",
              onPressed: () => updateService(service),
            },
            {
              label: "QUESTION",
              onPressed: (e: any) => renderListFeatures(service),
            },
            {
              label: "Company Priority",
              onPressed: (e: any) => renderListPrority(service),
            },
          ],
        };
      });
      let buttons = {
        data: { title: "Buttons", content },
        button: true,
      };

      table.push(services);
      table.push(descriptions);
      table.push(comingSoon);
      table.push(status);
      table.push(buttons);

      setListServices(table);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const updateService = (service: any) => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/create-service",
      state: {
        service: service.service,
        idService: service.id,
        description: service.description,
        active: service.active,
        image: service.image,
        comingSoon: service.comingSoon,
        multiServices: service.multiServices,
        serviceUnit: service.serviceUnit,
        order: service.order,
      },
    });
  };

  const renderListFeatures = (service: any) => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/list-features",
      state: {
        idService: service.id,
      },
    });
  };

  const renderListPrority = (service: any) => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/list-priority",
      state: {
        idService: service.id,
      },
    });
  };
  return (
    <>
      <MyTitle title="List Services" />
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <div className="container-btn-services">
        <BtnAddList label="Add service" onClick={addService} />
      </div>
      <section className="section-list-services">
        <ScheduleList data={listServices} />
      </section>
    </>
  );
};

export default ListServices;
