import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import {
  NavItem,
  NavLink,
} from 'reactstrap';
import './NavbarItem.css';

interface NavbarItemProps {
  itemTitle: string,
  itemLoc: string,
  toggle: Function
}; 

const NavbarItem: React.FC<NavbarItemProps> = ({itemTitle='',itemLoc='', toggle}) =>{
  let history = useHistory();
  const [isMobileActive, setIsMobileActive] = useState(false);
  useEffect(() => {
    if(window.innerWidth < 600){
      setIsMobileActive(true);
    }
  },[])
  return (
      <NavItem className="nav-item" onClick={() => {
        if(isMobileActive){
          toggle();
        }
          history.push(itemLoc)
        }}>
        <NavLink>{itemTitle}</NavLink>
      </NavItem>
  );
}

export default NavbarItem;