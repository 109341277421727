import React, { useState, useEffect } from "react";
import { User } from '../../Models/User';
import { useHistory } from "react-router-dom";
import { Spinner, Button } from 'reactstrap';
import MyModal from '../../components/MyModal/MyModal';
import server from '../../api/server';
import './RecoverPassword.css';


const RecoverPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [response, setResponse] = useState('');
  const [emailSend, setEmailSend] = useState(false);
  const [modalText, setModalText] = useState('');

  let history = useHistory();

  const onChangeEmail = (e:any) => {
    setEmail(e.target.value);
  }

  const onSubmit = async(e:any) => {
    e.preventDefault();
    setIsActive(true);

    const headers = {
      'Content-Type': 'application/json',
    }

    const data = {
      Email: email
    }

    await server.put('/admin/auth/forgot-password', JSON.stringify(data), {headers:headers}
    ).then(async (res) => {
      const response = res.data;
      setModal(true);

      if(response.statusCode == 200) {
        setModalText("It's this email exists we will send a password.");
        setEmailSend(true);
      }

      if(response.statusCode != 200){
        console.log('error');
        return;
      }
    }).catch(error => {
      setModal(true);
      setModalText("It's this email exists we will send a password.");
      setEmailSend(false);

      const response = error.response;
      if (response) {
        switch(response.status){
          case 404:
        }
      }
    });

    setIsActive(false);
  };

  const onChangeModal = async(value: any) => {
    setModal(value);
    history.push('/home');
  };

  return (
    <>
      <MyModal
        modalTitle="Recover password"
        modalBody={modalText}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <div className="row-section-sign-in">
        <div className="container-card">
          <div className="card-recover-password">
            <div className="card-body-sign-in">
              <img src="assets/login/logo.png" alt="logo" className="logo-signin" />
              <h1 className="text-center">Recover Password</h1>
              <p className="mb-5 text-center">Enter your email</p>
              <form onSubmit={onSubmit} className="form-sign-in">
                <img src="assets/login/login_email.png" alt="icon email" className="icon-login-email" />
                <input className="input-sign-in" type="email" name="email" id="email" autoComplete='off' onChange={onChangeEmail} placeholder="Email" />
                <Button
                  type="submit"
                  className="btn-signin"
                  disabled={email === ''}
                >
                  {isActive
                    ? <Spinner />
                    : "Send new Password"
                  }
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecoverPassword;
