import React from 'react';
import { useState, useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { AppContext } from "../../data/state";
import { Button } from 'reactstrap';
import "./ImportPrice.css";
import MyTitle from "../../components/MyTitle/MyTitle";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import server from '../../api/server';

const ImportPrice:React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [companyServicesId, setCompanyServicesId] = useState("");
  const [priceName, setPriceName] = useState("");
  const [priceString, setPriceString] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  const location: any = useLocation();
  let history = useHistory();

  const modalText = [
    {
      modalTitle: "Price",
      modalBody: "Price created successfully.",
    },
    {
      modalTitle: "Price",
      modalBody: "Error.",
    },
  ];


  const onSubmitFormPrice = async (e: any) => {
    e.preventDefault();
    addPrices();
 };

 const addPrices = async () => {
    try {
      setIsLoading(true);

      const headers = {
        Authorization: `Bearer ${state.token}`,
        'Content-Type': 'application/json'
      };

      const request = await server.post(
        "/admin/company-sub-services/import/" + companyServicesId,
        JSON.stringify(priceString),
        {
          headers: headers,
        }
      );

      const response = request.data;

      if (response.statusCode != 200) {
        return;
      }

      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);

      setIsLoading(false);
      history.goBack();
      setModal(true);
    } catch (error) {
      const response = error.response;
      if (response) {
        setModalTitle(modalText[1].modalTitle);
        setModalBody(modalText[1].modalBody);

        setModal(true);
      }
      setIsLoading(false);
    }
  };

  const onChangePrice = (e: any) => {
    let file = e.target.files[0];
    setPriceName(file.name);

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoadedLicense;
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoadedLicense = (readerEvt: any) => {
    let binaryString = readerEvt.target.result;
    setIsLoading(true);
    setPriceString(binaryString);
    setIsLoading(false);
  };

  const onChangeModal = async(value: any) => {
    setModal(value);
  };

  useEffect(() => {
    if (location.state !== undefined) {
      setCompanyServicesId(location.state.serviceId);
    }
  }, []);

  return(
    <>
      {isLoading && <Loading />}
      <MyTitle title="Upload Price" />
      <MyModal
        modalTitle="Create Price"
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <div className="upload-section-csv">
        <form
          action=""
          onSubmit={onSubmitFormPrice}
          className="form-csv-container"
        >
          <div className="browse-div-csv">
            <div className="selection-csv-div">
              <div className="browse-text">
                <p className="text">
                  Browse
                </p>
              </div>
              <div className="select-csv-div">
                <label htmlFor="csv-upload" className="custom-csv-upload">
                  <strong className="upload-text"> SELECT FILE FROM</strong>
                  {priceName !== "" ?
                    <strong className="upload-text">{priceName}</strong>
                    : null
                  }
                  <img src="assets/icons/arrow_blue.png" className="arrow-selet-file" />
                </label>
                <input
                  className="input-form-csv"
                  type="file"
                  name="csv-upload"
                  id="csv-upload"
                  placeholder=""
                  onChange={onChangePrice}
                  autoComplete="off"
                  accept=".csv"
                />
              </div>
            </div>
          </div>
          <div className="upload-button-csv-section">
            <Button type="submit" className="upload-button-csv">
              IMPORT PRICES
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
export default ImportPrice;
