import React, { useState, useEffect,useContext } from "react";
import MyTitle from "../../components/MyTitle/MyTitle";
import Loading from "../../components/Loading/Loading";
import { AppContext } from '../../data/state';
import server from '../../api/server';
import MyModal from "../../components/MyModal/MyModal";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import BtnAddList from "../../components/BtnAddList/BtnAddList";


import "./ListFeatures.css"
import { useHistory, useLocation } from "react-router";


const ListFeatures: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [listFeatures, setListFeatures] = useState([]);
  const [idService, setIdService] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  let history = useHistory();

  const location: any = useLocation();


  useEffect(() => {
    if (location.state !== undefined) {
      setIdService(location.state.idService);
      requestData(location.state.idService)
    }
  }, []);

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  const addFeatures = () => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/create-feature",
      state: {
        idService,
      },
    });
  }

  const requestData = async (idService:any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get(
        `/admin/service-features/service/${idService}`,
        {
          headers,
        }
      );
      const response = request.data;
      let table: any = [];

      let content = response.data.map((value) => {
        return value.order;
      });
      let order = {
        data: { title: "Order", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.question;
      });
      let questions = {
        data: { title: "Questions", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.isPrincipal ? 'Yes' : 'No';
      });
      let isPrincipal = {
        data: { title: "Display under service category?", content },
        button: false,
      };

      content = response.data.map((value) => {
        const feature = value;
        return {
          button: [
            {
              label: "EDIT",
              onPressed: () => updateFeature(feature),
            },
            {
              label: "ANSWERS",
              onPressed: (e: any) => renderListValues(feature),
            },
          ],
        };
      });
      let buttons = {
        data: { title: "Buttons", content },
        button: true,
      };

      table.push(order);

      table.push(questions);
      table.push(isPrincipal);
      table.push(buttons);

      setListFeatures(table);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const updateFeature = (feature:any) => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/create-feature",
      state: {
        idService,
        idFeature: feature.id,
        feature: feature.feature,
        question: feature.question,
        isPrincipal: feature.isPrincipal,
        order: feature.order,
        isAddOn: feature.isAddOn,
        disclaimers: feature.disclaimers,
      },
    });
  }

  const renderListValues = (feature:any) => {
    window.scrollTo(0, 0);

    dispatch({
      type: 'set-is-add-on',
      isAddOn: feature.isAddOn,
    })

    history.push({
      pathname: "/list-values",
      state: {
        idFeature: feature.id,
      },
    });


  }

  return(
    <>
        <MyTitle title="List Questions"/>
        {isLoading && <Loading />}
        <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <div className="container-btn-services">
        <BtnAddList label="Add questions" onClick={addFeatures} />
      </div>
      <section className="section-list-features">
          <ScheduleList data={listFeatures} />
      </section>
    </>
  );
}

export default ListFeatures;
