import { useHistory, useLocation } from 'react-router';
import React, { useEffect, useContext, useState } from 'react';
import server from '../../api/server';
import { AppContext } from '../../data/state';
import MyTitle from '../../components/MyTitle/MyTitle';
import Loading from "../../components/Loading/Loading";
import ScheduleList from '../../components/ScheduleList/ScheduleList';
import BtnAddList from "../../components/BtnAddList/BtnAddList";
import MyModal from "../../components/MyModal/MyModal";
import "./LocationsZip.css";

const LocationsZip: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [zipList, setZipList] = useState<any[]>([]);
  const [idCompanyLocation, setIdCompanyLocation] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const location: any = useLocation();
  let history = useHistory();
  const modalText = [
    {
      modalTitle: "Company Location Zip",
      modalBody: "Company Location ZIP deleted successfully",
    },
  ];

  useEffect(() => {
    if (location.state !== undefined) {
      setIdCompanyLocation(location.state.locationID);
    }
    requestData();
  }, []);

  const requestData = async() => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get(`/admin/company-location-zip/location/${location.state.locationID}`, {headers});
      const response = request.data;
      let table:any = [];
      let content = response.data.map((value) => {
        return value.cityName;
      });
      let cityName = {
        data: {title: "City", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.majorCityName;
      });

      let majorCity = {
        data: {title: "Major City", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.stateCode;
      });
      let stateCode = {
        data: {title: "State Code", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.stateName;
      });

      let stateName = {
        data: {title: "State", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.zipCode;
      });

      let zipcode = {
        data: {title: "ZIP code", content},
        button: false,
      }

      content = response.data.map((value) => {
        return value.translationTime;
      });

      let translationTime = {
        data: {title: "Translation Time", content},
        button: false,
      }

      content = response.data.map((value) => {
        const zipCode = value;
        return{
          button: [
            {
              label: "EDIT",
              onPressed: () => editLocationZip(zipCode),
            },
            {
              label: "DELETE",
              onPressed: () => deleteLocationZip(zipCode),
            },
          ],
        };
      });

      let buttons = {
        data: {title: "Buttons", content},
        button: true,
      };
      table.push(zipcode);
      table.push(cityName);
      table.push(majorCity);
      table.push(stateCode);
      table.push(stateName);
      table.push(translationTime);
      table.push(buttons);

      setZipList(table);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      console.error(error);
    }
  }

  const addZip = () =>{
    history.push({
      pathname: '/create-location-zip',
      state: {
        companyLocationID: idCompanyLocation
      }
    });
    window.scrollTo(0, 0);
  };

  const updateZipCode = (zipCode:any) => {
     window.scrollTo(0, 0);
     history.push({
      pathname: '/create-location-zip',
      state: {
        zipCodeID: zipCode.id,
        companyLocationID: zipCode.companyLocationID
      }
    })
  };

  const editLocationZip = (zipCode:any) => {
    window.scrollTo(0, 0);

    history.push({
      pathname: '/time-unit',
      state: {
        zipCodeID: zipCode.id
      }
    })
  };

  const deleteLocationZip = async (companyLocationZip:any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.delete(`/admin/company-location-zip/${companyLocationZip.id}`, {
        headers,
      });

      const response = request.data;
      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  const onChangeModal = (value: any) => {
    setModal(value);
    window.location.reload();
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyTitle title="ZIP codes list" />
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <div className="container-btn-services">
        <BtnAddList label="Add Location ZIP" onClick={addZip} />
      </div>
      <section className="customer-section">
        <ScheduleList data={zipList} />
      </section>
    </>
  );
}
export default LocationsZip;
