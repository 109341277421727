import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { AppContext } from "../../data/state";
import MyTitle from "../../components/MyTitle/MyTitle";
import MyDatePicker from "../../components/MyDatePicker/MyDatePicker";
import MyTime from "../../components/MyTime/MyTime";
import MyModal from "../../components/MyModal/MyModal";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";

import moment from "moment";
import "./Booking.css";

const Booking: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [datePicker, setDatePicker] = useState(new Date());
  const [time, setTime] = useState("");
  const [indexTime, setIndexTime] = useState(-1);
  const [zipCode, setZipCode] = useState("");
  const [serviceId, setServiceId] = useState(0);
  const [service, setService] = useState("");
  const [modal, setModal] = useState(false);
  const [listTimes, setListTimes] = useState<any[]>([]);
  const [isActive, setIsActive] = useState(false);

  const location: any = useLocation();
  let history = useHistory();

  useEffect(() => {
    if (location.state !== undefined) {
      setDatePicker(location.state.date);
      setZipCode(location.state.zipCode);
      setService(location.state.service);
      setServiceId(location.state.serviceId);
    }
    loadSchedules();
  }, []);

  const loadSchedules = async () => {
    setIsActive(true);
    let data = {
      CompanyServiceID: serviceId,
      Date: formatDate(datePicker),
    };
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const response = await server.post("/customer/company-calendar/schedules", JSON.stringify(data), {headers,})
      setIsActive(false);
      setListTimes(response.data.data);
    } catch (error) {
      console.error(error);
      setIsActive(false);
    }
  };

  const onChangeDatePicker = (value) => {
    setDatePicker(value);
    loadSchedules();
  };

  const onChangeSelectTimes = (value, index) => {
    if (value === time) {
      setTime("");
    } else {
      setTime(value);
      setIndexTime(index);
    }
  };

  const onSubmitBooking = () => {
    if (state.isLoggedin) {
      window.scrollTo(0, 0);
      history.push({
        pathname: "/payment-info",
        state: {
          date: datePicker,
          zipCode: zipCode,
          service: service,
          time: time,
        },
      });
    } else {
      setModal(true);
    }
  };
  const onChangeModal = (value: any) => {
    setModal(value);
  };

  const formatTime = (time: any) => moment(time, "HHmmss").format("hh:mm A");

  const formatDate = (date: any) => moment(date).format("YYYY-MM-DD");

  return (
    <>
      {isActive && <Loading />}
      <MyTitle title="Booking" />
      <MyModal
        modalTitle="Not logged in"
        modalBody="Log in or create an account to continue"
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <section className="section-booking">
        <h1 className="title-questionnaire">{service}</h1>
        <div className="card-booking">
          <div className="container-date">
            <h2 className="text-card-booking">Please choose a date below: </h2>
            <MyDatePicker
              setDatePicker={onChangeDatePicker}
              startDate={datePicker}
            />
          </div>
          <div className="container-time">
            <h2 className="text-card-booking">Please choose a time below: </h2>
            <div className="list-items-time">
              {listTimes.length != 0 ? (
                listTimes.map((values, index) => {
                  if (index === indexTime) {
                    return (
                      <MyTime
                        time={formatTime(values.startTime)}
                        onChangeListTimes={onChangeSelectTimes}
                        key={index}
                        index={index}
                        valueSelected={true}
                        isAvailable={values.isAvailable}
                      />
                    );
                  } else {
                    return (
                      <MyTime
                        time={formatTime(values.startTime)}
                        onChangeListTimes={onChangeSelectTimes}
                        key={index}
                        index={index}
                        valueSelected={false}
                        isAvailable={values.isAvailable}
                      />
                    );
                  }
                })
              ) : (
                <div className="message-not-found">
                  <h3>No available schedules for this day</h3>
                </div>
              )}
            </div>
            <button className="btn-section-booking" onClick={onSubmitBooking}>
              <span>BOOK</span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Booking;
