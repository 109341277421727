import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import "./ProtectedRoutes.css"

const ProtectedRoutes = ({component: Component, permissions, pathGroup,...rest}) => {
    return (
        <Route {...rest} render={props => (
            permissions.includes(pathGroup) ?
                <Component {...props} />
            : <Redirect to="/" />
        )} />
    );
};

export default ProtectedRoutes;
