import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../data/state";

import "./CreateLinkedService.css";

import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import BtnAddList from "../../components/BtnAddList/BtnAddList";

const CreateLinkedService: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);

  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [selectStatus, setSelectStatus] = useState(0);

  const location: any = useLocation();
  const history = useHistory();

  const [companyId, setCompanyId] = useState(-1);
  const [isCreate, setIsCreate] = useState(false);
  const [linkedId, setLinkedId] = useState(-1);

  const [linkedServices, setLinkedServices] = useState<any[]>([]);
  const [notLinkedServices, setNotLinkedServices] = useState<any[]>([]);

  useEffect(() => {
    if (location.state !== undefined) {
      setCompanyId(location.state.CompanyId);
      setIsCreate(location.state.IsCreate);
      setLinkedId(location.state.LinkedServiceId);
      requestNotLinkedService(location.state.CompanyId);
      requestData(location.state.LinkedServiceId);
    }
  }, []);

  const modalText = [
    {
      modalTitle: "Company Service",
      modalBody: "Company Service created succesfully",
    },
    {
      modalTitle: "Service",
      modalBody: "Choose a service to continue",
    },
    {
      modalTitle: "Linked Service",
      modalBody: "Service removed succesfully",
    },
  ];

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  const onChangeSelectNewService = async (e: any) => {
    setSelectStatus(e.target.value);
  };

  const onClickButtonAddService = () => {
    linkService(selectStatus);
  };

  const requestData = async (idLinkedService: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get(
        `/admin/linked-company-services/linked-services/${idLinkedService}`,
        {
          headers: headers,
        }
      );

      dataFormat(request.data.data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const requestNotLinkedService = async (idCompany: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get(
        `/admin/linked-company-services/not-linked/company/${idCompany}`,
        {
          headers: headers,
        }
      );

      setNotLinkedServices(request.data.data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const removeLinkedService = async (idCompany: any, id: any) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`,
    }

    setIsLoading(true);
    try {
      const responseServer = await server.delete(
        `/admin/linked-company-services/${id}`,
        {
          headers: headers,
      });

      setNotLinkedServices([]);

      await requestNotLinkedService(idCompany);
      await requestData(linkedId);

      setIsLoading(false);
      setModalTitle(modalText[2].modalTitle);
      setModalBody(modalText[2].modalBody);
      setModal(true);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return;
    }
  };

  const linkService = async (id: any) => {
    let data = {
      CompanyServiceID: id,
      LinkedServiceID: linkedId,
    };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`,
    }

    setIsLoading(true);
    try {
      const responseServer = await server.post(
        `/admin/linked-company-services`,
        JSON.stringify(data),
        {
          headers: headers,
        }
      );

      setIsLoading(false);
      setSelectStatus(0);
      setNotLinkedServices([]);

      await requestData(linkedId);
      await requestNotLinkedService(companyId);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return;
    }
  };

  const dataFormat = (linkedServices: any) => {
    let table: any = [];

    let content = linkedServices.map((value) => {
      return value.id;
    });

    let id = {
      data: { title: "ID", content },
      button: false,
    };

    content = linkedServices.map((value) => {
      return value.companyService.service.service;
    });

    let name = {
      data: { title: "Nombre", content },
      button: false,
    };

    content = linkedServices.map((value) => {
      const lkdService = value;

      let button = [{
        label: "Remove",
        onPressed: () => removeLinkedService(companyId, lkdService.id),
      }];

      return {button};
    });

    let buttons = {
      data: { title: "Buttons", content },
      button: true,
    };

    table.push(id);
    table.push(name);
    table.push(buttons);

    setLinkedServices(table);
  };

  const backToList = () => {
    history.goBack();
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title={isCreate ? "Linked service" : "Linked service update"} />
      <div className="add-new-linked-service-div">
        <div className="container-linked-service-actions">
          <select
            className="add-new-service-select"
            value={selectStatus}
            onChange={onChangeSelectNewService}
            >
            <option value={0} key={0} className="filter-option">Services</option>
            {
              notLinkedServices &&
              notLinkedServices.map((item) => {
                return (
                  <option className="filter-option" key={item.id} value={item.id}>
                  {item.service.service}
                  </option>
                )
              })
            }
          </select>
          {
            selectStatus != 0 ?
            <button
              className="add-service-button"
              onClick={onClickButtonAddService}
            >
              Add Service
            </button>
            : null
          }
        </div>
        <BtnAddList label="Back to Linked service" onClick={backToList} />
      </div>
      <section className="company-service-section">
        <ScheduleList data={linkedServices} />
      </section>
    </>
  );
};

export default CreateLinkedService;
