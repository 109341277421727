import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../data/state";

import "./CreateFeature.css";

import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";

const CreateFeature: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [feature, setFeature] = useState("");
  const [idService, setIdService] = useState("");
  const [idFeature, setIdFeature] = useState("");
  const [question, setQuestion] = useState("");
  const [order, setOrder] = useState("");
  const [disclaimers, setDisclaimers] = useState<string>('');
  
  const [selectStatusFeature, setSelectStatusFeature] = useState("DEFAULT");
  const [isPrincipal, setIsPrincipal] = useState(false);

  const [selectStatusIsAddOn, setSelectStatusIsAddOn] = useState("DEFAULT");
  const [isAddOn, setIsAddOn] = useState(false);
  
  const [isUpdateCreateFeature, setIsUpdateCreateFeature] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [isError, setIsError] = useState(false);

  const location: any = useLocation();
  const history: any = useHistory();
  useEffect(() => {
    if (location.state !== undefined) {
      if(location.state.idFeature){
        setIsUpdateCreateFeature(true);
        setIdFeature(location.state.idFeature);
        setIdService(location.state.idService);

        setFeature(location.state.feature);
        setQuestion(location.state.question);
        setOrder(location.state.order);
        setDisclaimers(location.state.disclaimers? location.state.disclaimers: "");
        
        setIsPrincipal(location.state.isPrincipal);
        setSelectStatusFeature(location.state.isPrincipal ? "1" : "0"); 
        
        setIsAddOn(location.state.isAddOn);
        setSelectStatusIsAddOn(location.state.isAddOn ? "1" : "0"); 

      } else {
        setIdService(location.state.idService);
      }
    }
  }, []);

  const modalText = [
    {
      modalTitle: "Service Feature",
      modalBody: "Service Feature created succesfully",
    },
    {
      modalTitle: "Service Feature",
      modalBody: "Service Feature updated succesfully",
    },
    {
      modalTitle: "Service Feature",
      modalBody: "Choose status to Add On",
    },
    {
      modalTitle: "Error!!!",
      modalBody: "Try again later",
    },
  ];

  const onChangeModal = (value: any) => {
    if(!isError){
      history.goBack();
    } else {
      setIsError(false);
    }
    setModal(value);
  };

  const onSubmitFormCompanyService = (e: any) => {
    e.preventDefault();
    if(selectStatusIsAddOn == "DEFAULT"){
      setModalBody(modalText[2].modalBody);
      setModalTitle(modalText[2].modalTitle);
      setModal(true);
      setIsError(true);
    } else {
      if (isUpdateCreateFeature) {
        updateFeature(idFeature);
      } else {
        addFeature();
      }
    }
  };

  const addFeature = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        ServicesID: idService,
        Feature: feature,
        Question: question,
        Order: order,
        IsAddOn: isAddOn,
        Disclaimers:disclaimers,
      };

      const request = await server.post(
        "/admin/service-features",
        JSON.stringify(data),
        {
          headers,
        }
      );

      const response = request.data;
      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setModalTitle(modalText[3].modalTitle);
      setModalBody(modalText[3].modalBody);
      setIsError(true);
      setModal(true);
    }
  };

  const updateFeature = async (idFeature: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        Feature: feature,
        Question: question,
        Order: order,
        IsPrincipal: isPrincipal,
        Disclaimers: disclaimers,
        IsAddOn: isAddOn,
      };

      const request = await server.put(
        `/admin/service-features/${idFeature}`,
        JSON.stringify(data),
        {
          headers,
        }
      );
      setIsLoading(false);
      setModalTitle(modalText[1].modalTitle);
      setModalBody(modalText[1].modalBody);
      setModal(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setModalTitle(modalText[3].modalTitle);
      setModalBody(modalText[3].modalBody);
      setIsError(true);
      setModal(true);
    }
  };

  const onChangeFeature = (e: any) => {
    setFeature(e.target.value);
  };



  const onChangeQuestion = (e: any) => {
    setQuestion(e.target.value);
  };

  const onChangeOrder = (e:any) => {
    setOrder(e.target.value);
  }

  const onChangeSelectService = (e: any) => {
    setSelectStatusFeature(e.target.value);
    if (e.target.value == "1") {
      setIsPrincipal(true);
    } else {
      setIsPrincipal(false);
    }
  };

  
  const onChangeSelectIsAddOn = (e: any) => {
    setSelectStatusIsAddOn(e.target.value);
    if (e.target.value == "1") {
      setIsAddOn(true);
    } else {
      setIsAddOn(false);
    }
  };

  const onChangeDisclaimers = (e:any) => {
    setDisclaimers(e.target.value);
  }


  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title={isUpdateCreateFeature ? "Update a Feature" : "Create a Feature"} />
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">
              {isUpdateCreateFeature ? "Update a Feature" : "Create a Feature"}
            </h1>
          </div>
          <div className="card-body-create">
            <form
              action=""
              onSubmit={onSubmitFormCompanyService}
              className="form-container"
            >
              <div className="form-container-input">
                <span className="label-create-input">Feature: </span>
                <input
                  className="input-form-create"
                  type="text"
                  name="feature"
                  id="feature"
                  onChange={onChangeFeature}
                  autoComplete="off"
                  placeholder="Feature..."
                  value={feature}
                  required
                />
              </div>
              <div className="form-container-input">
                <span className="label-create-input">Question: </span>
                <textarea
                  className="text-form-create"
                  name="question"
                  id="question"
                  placeholder="Question..."
                  onChange={onChangeQuestion}
                  value={question}
                  required
                />
              </div>
              <div className="form-container-input">
              <span className="label-create-input">Order: </span>
                <input
                  className="input-form-create"
                  type="text"
                  name="order"
                  id="order"
                  onChange={onChangeOrder}
                  autoComplete="off"
                  placeholder="Order..."
                  value={order}
                  required
                />
              </div>
              <div className="form-container-input">
                <span className="label-create-input">Is an Add On?</span>
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-create select-create"
                  onChange={onChangeSelectIsAddOn}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={selectStatusIsAddOn}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Choose an option --
                  </option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
              <div className="form-container-input">
                <span className="label-create-input">Display under service category?</span>
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-create select-create"
                  onChange={onChangeSelectService}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={selectStatusFeature}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Choose an option --
                  </option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>

              <div className="form-container-input">
                <span className="label-create-input">Disclaimers: </span>
                <textarea
                  className="text-form-create"
                  name="disclaimers"
                  id="disclaimers"
                  placeholder="Disclaimers..."
                  onChange={onChangeDisclaimers}
                  value={disclaimers}
                  required
                />
              </div>
              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateFeature;
