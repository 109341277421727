import React, { useState } from 'react';
import moment from "moment";

import "./CardOpenCharge.css";

interface CardItemProps {
  id: number,
  description: string,
  price: number,
  duration: string,
  createdDate: string,
};

const CardOpenCharge: React.FC<CardItemProps> = (
                                        {id=-1,
                                          description= '',
                                          price,
                                          duration= '',
                                          createdDate=''}) => {

  const formatDate = (date: any) => moment(date).format("MMM. DD. YYYY");

  return (
    <>
      <ul className="list-open-charge-info">
        <li className="list-item">
          <label className="fields-open-charge">Description</label>
          <label className="fields-open-charge-info">{description}</label>
        </li>
        <li className="list-item">
          <label className="fields-open-charge">Price</label>
          <label className="fields-open-charge-info">{price}</label>
        </li>
        <li className="list-item">
          <label className="fields-open-charge">Duration</label>
          <label className="fields-open-charge-info">{duration}</label>
        </li>
        <li className="list-item">
          <label className="fields-open-charge">Date</label>
          <label className="fields-open-charge-info">{formatDate(createdDate)}</label>
        </li>
      </ul>
    </>
  );
}

export default CardOpenCharge;
