import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./MyDatePicker.css";

interface myDatePickerProps {
  setDate: (date: Date) => void;
  startDate: Date;
  isBlocked?: boolean;
}

const MyDatePicker: React.FC<any> = ({
  setDatePicker,
  startDate,
  isBlocked = true,
  disabled = false,
}) => {
  const [today, setToday] = useState(new Date());

  const CustomInput = React.forwardRef(({ value, onClick }: any, ref) => (
    <input
      type="text"
      name=""
      id=""
      readOnly
      onClick={onClick}
      className="input-calendar"
      value={value}
      style={{ backgroundImage: "url('assets/calendar.svg')" }}
      disabled={disabled}
    />
  ));
  

  return (
    <>
      <div className="customDatePickerWidth">
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setDatePicker(date);
          }}
          dateFormat="EEE, MMM dd yyyy"
          customInput={<CustomInput />}
          minDate={isBlocked ? today : ""}
        />
      </div>
    </>
  );
};

export default MyDatePicker;
