import React, { useEffect, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { AppContext } from "../../data/state";
import MyTitle from "../../components/MyTitle/MyTitle";
import "./ListCompanies.css";
import Loading from "../../components/Loading/Loading";
import ServiceImageHeader from "../../components/ServiceImageHeader/ServiceImageHeader";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import server from "../../api/server";
import MyModal from "../../components/MyModal/MyModal";
import BtnAddList from "../../components/BtnAddList/BtnAddList";

const ListCompanies: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [allCompanies, setAllCompanies] = useState<any[]>([]);
  const [allCompaniesFilter, setAllCompaniesFilter] = useState<any[]>([]);
  const [allCompaniesStatus, setAllCompaniesStatus] = useState<any[]>([]);

  const [companyNameFilter, setCompanyNameFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  let history = useHistory();

  const modalText = [
    {
      modalTitle: "Companies",
      modalBody: "Company updated successfully.",
    },
  ];

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  const addCompany = () => {
    history.push("/create-company");
    window.scrollTo(0, 0);
  };

  const listCompanyServices = (company: any) => {
    history.push({
      pathname: "/company-services",
      state: {
        companyId: company.id,
      },
    });
    window.scrollTo(0, 0);
  };

  const listPayments = (company: any) => {
    history.push({
      pathname: "/list-payments",
      state: {
        companyId: company.id,
      },
    });
    window.scrollTo(0, 0);
  };

  const listLocations = (company: any) => {
    history.push({
      pathname: "/list-locations",
      state: {
        companyID: company.id,
      },
    });
    window.scrollTo(0, 0);
  };

  const updateCompany = (company: any) => {
    history.push({
      pathname: "/create-company",
      state: {
        idCompany: company.id,
      },
    });
  };

  const updateStatusCompany = async (company: any, e:any) => {
    try {
      setIsLoading(true);

      if(e.target.innerText === 'ACTIVE'){
        e.target.classList.toggle("deactive-btn-user");
        e.target.innerText = 'DEACTIVE';
        company.companyStatusID = 1;
      } else {
        e.target.classList.toggle("deactive-btn-user");
        e.target.innerText = 'ACTIVE';
        company.companyStatusID = 2;
      }

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.put(`/admin/company/${company.id}`,
        JSON.stringify(company), {headers: headers,}
      );

      const response = request.data;

      if (response.statusCode != 200) {
        return;
      }

      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);

      setIsLoading(false);
      setModal(true);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const requestData = async () => {
    try {
      setIsLoading(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get("/admin/company", { headers });
      const response = request.data;

      dataFormat(response.data);
      setAllCompanies(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      console.error(error);
    }
  };

  const getAllCompaniesStatus = async () => {
    try {
      setIsActive(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get(`/admin/company-status`, {headers});
      const response = request.data;

      setAllCompaniesStatus(response.data);

      setIsActive(false);
    } catch (error) {
      setIsActive(false);
      const response = error.response;
      console.error(error);
    }
  };

  const dataFormat = (companies: any) => {
    let table: any = [];

    let content = companies.map((value) => {
      return value.phoneNumber;
    });

    let phoneNumbers = {
      data: { title: "Phone Number", content },
      button: false,
    };

    content = companies.map((value) => {
      return (value.companyStatusID === 1) ? "Active": "Inactive" ;
    })

    let compStatus = {
      data: { title: "Status", content},
      button:false,
    }

    content = companies.map((value) => {
      return value.email;
    });

    let emails = {
      data: { title: "Email", content },
      button: false,
    };

    content = companies.map((value) => {
      return value.companyName;
    });

    let names = {
      data: { title: "Name", content },
      button: false,
    };

    content = companies.map((value) => {
      const company = value;
      return {
        button: [
          {
            label: "Services",
            onPressed: () => listCompanyServices(company),
          },
          {
            label: "Payments",
            onPressed: () => listPayments(company),
          },
          {
            label: "Locations",
            onPressed: () => listLocations(company),
          },
          {
            label: "EDIT",
            onPressed: () => updateCompany(company),
          },
        ],
      };
    });

    let buttons = {
      data: { title: "Buttons", content },
      button: true,
    };

    table.push(names);
    table.push(emails);
    table.push(phoneNumbers);
    table.push(compStatus);
    table.push(buttons);

    setAllCompaniesFilter(table);
  };

  const dataFilterBy = async () => {
    try {
      setIsLoading(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      let requestString = "/admin/company?PageNumber="+pageNumber
                          +"&_pageSize="+pageSize;

      if(companyNameFilter !== "") {
        requestString += "&CompanyName="+companyNameFilter;
      }

      const request = await server.get(
        requestString,
        { headers }
      );

      const response = request.data;

      dataFormat(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      console.error(error);
    }
  };

  const onChangeInputCompanyName = async (e: any) => {
    setCompanyNameFilter(e.target.value);
  };

  const onClickButtonRestore = () => {
    setCompanyNameFilter("");
    dataFormat(allCompanies);
  };

  const onClickButtonApplyFilters = () => {
    dataFilterBy();
  };

  useEffect(() => {
    requestData();
    getAllCompaniesStatus();
  }, []);

  const renderPaymentReq = () =>{
    history.push({
      pathname: '/payment-request'
    })
  }

  return(
    <>
      {isLoading && <Loading />}
      <MyTitle title="My Companies" />
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <div className="list-companies-filters-container">
        <input
          type="text"
          placeholder="Search Company Name"
          className="name-filter-company"
          value={companyNameFilter}
          onChange={onChangeInputCompanyName}
        />
        <button
          className="apply-filter-list-companies"
          onClick={onClickButtonApplyFilters}
        >
          APPLY FILTERS
        </button>
        <button
          className="reset-values-list-companies"
          onClick={onClickButtonRestore}
        >
          CLEAR FILTERS
        </button>
        <BtnAddList label="Payment Request" onClick={renderPaymentReq} />
        <BtnAddList label="Add a new company" onClick={addCompany} />
      </div>
      <section className="companies-section">
        <ScheduleList data={allCompaniesFilter} />
      </section>
    </>
  );
};

export default ListCompanies;
