import React, { useState } from 'react';
import MyTitle from '../../components/MyTitle/MyTitle'
import './About.css';

const About:React.FC =() => {
  return (
    <div className="about-us">
      <MyTitle title="About us"/>
      <div className="container-whats-is">
        <div className="box">
            <img className="whats-is-img" src="assets/Transparent-PNG-01.png" />
        </div>
        <div className="box">
          <h2 className="whats-is">What is <strong>Upkeep?</strong></h2>
          <p className="lead whats-is-text">Upkeep was created to bring convenience to all homeowners, offering all the tools needed to acquire home services. We found the way to panner up with top quality professional companies, offer set pricing and book all service appointments through our platform.</p>
        </div>
      </div>
      <div className="container-story">
        <div className="box-story">
          <h2 className="story-behind story-behind-title">The history</h2>
          <h2 className="story-behind story-behind-title">behind <strong>Upkeep</strong></h2>
          <p className="story-behind">We wanted to create a platform where we addressed the problem for homeowners that has been ignored for years, that is acquiring home services. We wanted to solve the problem by bringing a more convenient approach rather than spending an hour on the phone comparing services all just to acquire one service. With Upkeep the user compares all companies and pricing in one screen and chooses the right fit for them in the matter of seconds! With the convenience of set pricing we believe there will be no surprises and we will be able to acquire trust from the homeowner.</p>
        </div>
        <div className="box-story">
          <img className="picture" src="assets/story.svg" />
        </div>
      </div>
      <div className="goal">
        <div className="box-story">
          <img className="picture" src="assets/our_goal.svg" />
        </div>
        <div className="box-story">
          <h2 className="goal-text">Our <strong>Goal</strong></h2>
          <p className="lead">The goal for Upkeep is to be the number one trusted on demand home service platform in the world. Although it sounds ambitious, we believe that by helping people and bringing this convenience to everybody and helping companies gain more jobs, we can make everybody happy and hope of gaining trust from both homeowners and companies.</p>
        </div>
      </div>
    </div>
  );
}

export default About;
