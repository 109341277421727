import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import "./ListTickets.css";
import { AppContext } from "../../data/state";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyTitle from "../../components/MyTitle/MyTitle";
import ReactPaginate from 'react-paginate';
import moment from "moment";

const ListTickets: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [isActive, setIsActive] = useState(false);

  const [hasTickets, setHasTickets] = useState(0);

 const [pageSize, setPageSize] = useState(10);
 const [totalOfTickets, setTotalOfTickets] = useState(0);
 const [totalOfTicketsFilter, setTotalOfTicketsFilter] = useState(0);
 const [currentPage, setCurrentPage] = useState(1);

  const [allTickets, setAllTickets] = useState<any[]>([]);
  const [allTicketsResponse, setAllTicketsResponse] = useState<any[]>([]);
  const [allTicketsResponseFilter, setAllTicketsResponseFilter] = useState<any[]>([]);

  const [allTicketsStatusesResponse, setAllTicketsStatusesResponse] = useState<any[]>([]);
  const [allCompanies, setAllCompanies] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [ticketID, setTicketID] = useState("");
  const [selectStatus, setSelectStatus] = useState(0);
  const [searchByCustomerName, setSarchByCustomerName] = useState("");

  const formatDate = (date: any) => moment(date).format("MMM. DD. YYYY");

  let history = useHistory();

  useEffect(() => {
    requestData();
    requestCompanies();
    getAllTicketsStatuses();
  }, []);

  const ticketDetail = (ticket: any) => {
    history.push({
      pathname: "/detail-ticket",
      state: {
        TicketId : ticket.id,
      },
    });
  };

  const updateStatus = (ticket: any) => {
    window.scrollTo(0, 0);
    history.push({
      pathname: '/update-status',
      state: {
        TicketId : ticket.id,
      },
    });
   };

  const requestData = async () => {
    try {
      setIsLoading(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get("/admin/tickets?PageNumber="+currentPage
                                    +"&_pageSize="+pageSize,
                                    {headers: headers}
                                  );

      const response = request.data;

      setTotalOfTickets(response.pages.totalCount);
      setTotalOfTicketsFilter(response.pages.totalCount);
      setHasTickets(response.data.length);

      setAllTicketsResponse(response.data);
      setAllTicketsResponseFilter(response.data);
      dataFormat(response.data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      console.error(error);
    }
  };

  const requestCompanies = async () => {
    try {
      setIsLoading(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get("/admin/company", { headers });
      const response = request.data;

      setAllCompanies(response.data);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      console.error(error);
    }
  };

  const getAllTicketsStatuses = async () => {
    try {
      setIsActive(true);
      const headers = {
        "Content-Type": "application/json",
      };

      const request = await server.get(`/customer/ticket-status`, {headers});
      const response = request.data;

      setAllTicketsStatusesResponse(response.data);

      setIsActive(false);
    } catch (error) {
      setIsActive(false);
      const response = error.response;
      console.error(error);
    }
  };

  const dataFilterBy = async (
    company: string,
    ticketNumber: string,
    statusNumber: number,
    customerName: string,
    currentPage: number,
  ) => {
    try {
      setIsActive(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      let requestString = "/admin/tickets?PageNumber="+currentPage
                          +"&_pageSize="+pageSize;

      if(company !== "") {
        requestString += "&CompanyName="+company;
      }

      if(ticketNumber !== "") {
        requestString += "&TicketID="+parseInt(ticketNumber, 10);
      }

      if(statusNumber != 0) {
        requestString += "&StatusID="+statusNumber;
      }

      if(customerName !== "") {
        requestString += "&CustomerName="+customerName;
      }

      const request = await server.get(
        requestString,
        { headers }
      );

      const response = request.data;

      setTotalOfTicketsFilter(response.pages.totalCount);
      dataFormat(response.data);
      setIsActive(false);
    } catch (error) {
      setIsActive(false);
      const response = error.response;
      console.error(error);
    }
  };

  const paginate = async (e:any) => {
    window.scroll({top: 0, left: 0, behavior: 'smooth' });
    setCurrentPage(e.selected+1);
    dataFilterBy(companyName, ticketID, selectStatus, searchByCustomerName, e.selected+1);
  };

  const onChangeInputCompanyName = (e: any) => {
    setCurrentPage(1);
    setCompanyName(e.target.value);
    dataFilterBy(e.target.value, ticketID, selectStatus, searchByCustomerName, 1);
  };

  const onChangeInputTicketId = (e: any) => {
    setCurrentPage(1);
    setTicketID(e.target.value);
    dataFilterBy(companyName, e.target.value, selectStatus, searchByCustomerName, 1);
  };

  const onChangeSearchByCustomerName = (e: any) => {
    setCurrentPage(1);
    setSarchByCustomerName(e.target.value);
    dataFilterBy(companyName, ticketID, selectStatus, e.target.value, 1);
  };

  const onChangeSelectStatus = async (e: any) => {
    setCurrentPage(1);
    setSelectStatus(e.target.value);
    dataFilterBy(companyName, ticketID, e.target.value, searchByCustomerName, 1);
  };

  const rescheduleTicket = (ticket:any) =>{
    history.push({
      pathname: "/booking-reschedule",
      state: { ticketID: ticket.id }
    })
  }

  const dataFormat = (ticketsFilter: any) => {
    let table: any = [];

    let content = ticketsFilter.map((value) => {
      return value.name;
    });

    let name = {
      data: { title: "User Name", content },
      button: false,
    };

    content = ticketsFilter.map((value) => {
      return value.statusName;
    });

    let status = {
      data: { title: "Status", content },
      button: false,
    };

    content = ticketsFilter.map((value) => {
      return value.id;
    });

    let ticketNumber = {
      data: { title: "No. Ticket", content },
      button: false,
    };

    content = ticketsFilter.map((value) => {
      return "$"+value.totalPrice;
    });

    let totalPrice = {
      data: { title: "Total Price", content },
      button: false,
    };

    content = ticketsFilter.map((value) => {
      return value.email;
    });

    let companyName = {
      data: { title: "Email", content },
      button: false,
    };

    content = ticketsFilter.map((value) => {
      return value.companyName;
    });

    let email = {
      data: { title: "Company Name", content },
      button: false,
    };

    content = ticketsFilter.map((value) => {
      return value.serviceName;
    });

    let serviceName = {
      data: { title: "Service Name", content },
      button: false,
    };

    content = ticketsFilter.map((value) => {
      return formatDate(value.scheduledDate);
    });

    let scheduledDate = {
      data: { title: "Scheduled Date & Time", content },
      button: false,
    };

    content = ticketsFilter.map((value) => {
      const ticket = value;

      let button = [{
        label: "Detail",
        onPressed: () => ticketDetail(ticket),
      }];

      if(ticket.canStaffUpdate) {
        button.push({
          label: "Update Status",
          onPressed: () => updateStatus(ticket),
        });
      }

      if(ticket.canStaffReschedule) {
        button.push({
          label: "Reschedule",
          onPressed: () => rescheduleTicket(ticket),
        })
      }
      return {button};
    });

    let buttons = {
      data: { title: "Buttons", content },
      button: true,
    };

    table.push(ticketNumber);
    table.push(name);
    table.push(email);
    table.push(companyName);
    table.push(serviceName);
    table.push(totalPrice);
    table.push(status);
    table.push(scheduledDate);
    table.push(buttons);

    setAllTickets(table);
  };

  const onClickButtonRestore = () => {
    setCompanyName("");
    setTicketID("");
    setSelectStatus(0);
    setCurrentPage(1);
    dataFilterBy("", "", 0, "", 1);
    dataFormat(allTicketsResponse);
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyTitle title="My Tickets" />
      <div className="filters-container-tickets">
        <div className="companies-filters-container">
          <input
            type="text"
            placeholder="Search Company Name"
            className="companies-filter"
            value={companyName}
            onChange={onChangeInputCompanyName}
          />
          <input
            type="text"
            placeholder="Search by Ticket ID"
            className="companies-filter"
            value={ticketID}
            onChange={onChangeInputTicketId}
          />
          <input
            type="text"
            placeholder="Search by Customer Name"
            className="companies-filter"
            value={searchByCustomerName}
            onChange={onChangeSearchByCustomerName}
          />
          <select
            className="status-filter"
            value={selectStatus}
            onChange={onChangeSelectStatus}
          >
            <option value={0} className="filter-option">Select Status</option>
            {
              allTicketsStatusesResponse &&
                allTicketsStatusesResponse.map((item) => {
                  return (
                    <option className="filter-option" key={item.id} value={item.id}>
                      {item.status}
                    </option>
                  )
              })
            }
          </select>
          <button
            className="reset-values"
            onClick={onClickButtonRestore}
          >
            CLEAR FILTERS
          </button>
        </div>
      </div>
      <section className="companies-section">
        <ScheduleList data={allTickets} />
      </section>
      <div>
        {
          totalOfTicketsFilter > 0 ?
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={'previous'}
              previousLinkClassName={'page-link'}
              nextLinkClassName={'page-link'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={Math.ceil(totalOfTicketsFilter/pageSize)}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={paginate}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              forcePage={currentPage-1}
            />
          </div>
          : null
        }
      </div>
    </>
  );
}

export default ListTickets;
