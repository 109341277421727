import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./ListPayments.css";
import { AppContext } from "../../data/state";
import MyTitle from "../../components/MyTitle/MyTitle";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import moment from "moment";
import BtnAddList from "../../components/BtnAddList/BtnAddList";
import BtnAction from "../../components/BtnAction/BtnAction";

const ListPayments: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [companyId, setCompanyId] = useState("");
  const [allPayments, setAllPayments] = useState<any[]>([]);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [currentBalance, setCurrentBalance] = useState<number>(0);
  const [lastTransferDate, setLastTransferDate] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const formatDate = (date: any) => moment(date).format("MMM. DD. YYYY");
  const formatTime = (date: any) => moment(date).format("HH:mm:ss");

  const location: any = useLocation();
  let history = useHistory();

  useEffect(() => {
    if (location.state !== undefined) {
      setCompanyId(location.state.companyId);
      requestData(location.state.companyId);
    }
  }, []);

  const getPaymentRequestStatus = async (id: any) => {
    try {
      setIsLoading(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get(`/admin/request-status/${id}`, {
        headers,
      });
      const response = request.data;
      if (response.statusCode != 200) {
        return;
      }

      setPaymentStatus(response.data.status);
      setIsLoading(false);
      return response.data.status;
    } catch (error) {
      setIsLoading(false);

      const response = error.response;

      if (response) {
        switch (response.status) {
          case 400: {
            setModal(true);
            break;
          }
          case 401: {
            break;
          }
        }
      }
      return "";
    }
  };

  const requestData = async (idCompany: any) => {
    try {
      setIsLoading(true);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get(
        `/admin/payment-request/company/${idCompany}`,
        { headers }
      );
      const response = request.data;
      let table: any = [];

      let content = response.data.map((value) => {
        return formatDate(value.date);
      });

      let date = {
        data: { title: "Date", content },
        button: false,
      };

      content = response.data.map((value) => {
        return formatTime(value.date);
      });

      let time = {
        data: { title: "Time", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.amount;
      });

      let amount = {
        data: { title: "Amount", content },
        button: false,
      };


      content = response.data.map((value) => {
        return formatDate(value.startDate);
      });

      let startDate = {
        data: { title: "Start Date", content },
        button: false,
      };

      content = response.data.map((value) => {
        return formatDate(value.endDate);
      });

      let endDate = {
        data: { title: "End Date", content },
        button: false,
      };

      content = [];
      for (const iterator of response.data) {
        let request = await getPaymentRequestStatus(iterator.requestStatusID);
        content.push(request);
      }

      let status = {
        data: { title: "Status", content },
        button: false,
      };

      content = response.data.map((value) => {
        const paymentValue = value;
        let objPayment: any = {
          button: [
            {
              label: "Download",
              onPressed: () => printReqReport(paymentValue),
            },
          ],
        };
        if(!paymentValue.requestStatus.isFinalStatus) {
          objPayment.button.push({
            label: "EDIT",
            onPressed: () => updatePayment(paymentValue),
          });
        }
        return objPayment;
      });

      let buttons = {
        data: { title: "Buttons", content },
        button: true,
      };

      table.push(date);
      table.push(time);
      table.push(amount);
      table.push(startDate);
      table.push(endDate);
      table.push(status);
      table.push(buttons);

      setAllPayments(table);
      await getCurrentBalance(idCompany);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      console.error(error);
    }
  };

  const printReqReport = async (reqObj: any) => {
    setIsLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.post(`/reports/payment-request/${reqObj.id}`, {},{
        headers,
        responseType: "blob",
      });


      const response = request.data;
      const pdfBlob = new Blob([response], { type: "application/pdf" });

      let fileURL = URL.createObjectURL(pdfBlob);
      window.open(fileURL);

      setIsLoading(false);
    } catch (error) {
      const response = error.response;
      console.error(response);
      setIsLoading(false);
    }
  };

  const updatePayment = (paymentValue: any) => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/create-payment",
      state: {
        paymentID: paymentValue.id,
      },
    });
  };

  const getCurrentBalance = async (idCompany: any) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.get(
        `/admin/payment-request/balance/${idCompany}`,
        {
          headers,
        }
      );
      const response = request.data;
      setCurrentBalance(response.data.balance);
      setLastTransferDate(formatDate(response.data.lastTransferDate));
    } catch (error) {
      const response = error.response;
      console.error(response);
    }
  };

  const addPaymentRequest = () => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/create-payment-request",
      state: {
        companyID: companyId,
      },
    });
  };

  const renderPrintStatement = () => {
    window.scrollTo(0,0);
    history.push({
      pathname: "/create-print-statement",
      state: {
        companyID: companyId,
      }
    });
  }

  return (
    <>
      {isLoading && <Loading />}
      <MyTitle title="My Payments" />
      <section className="payment-req-section">
        <div className="container-current-balance">
          <div className="current-balance">
            <div className="img-current">
              <img src="/assets/contact/pobox.png" alt="icon" />
            </div>
            <div className="container-text-balance">
              <span>
                <span className="label-balance">Current Balance:</span> $
                {currentBalance}
              </span>
              <span>
                <span className="label-balance">Last Transfer Date:</span>{" "}
                {lastTransferDate}
              </span>
            </div>
          </div>
          <BtnAddList label="Add Payment Request" onClick={addPaymentRequest} />
        </div>
        <BtnAction label={"Download Statement"} action={renderPrintStatement}/>
        <ScheduleList data={allPayments} />
      </section>
    </>
  );
};

export default ListPayments;
