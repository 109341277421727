import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../data/state";

import "./CompanyServices.css";

import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import AlertModal from "../../components/AlertModal/AlertModal";
import BtnAddList from "../../components/BtnAddList/BtnAddList";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import { saveAs } from 'file-saver';

const CompanyServices: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [companyServices, setCompanyServices] = useState([]);
  const [companyService, setCompanyService] = useState({});
  const [companyId, setCompanyId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [isAlertModal, setIsAlertModal] = useState(false);
  const [reload, setReload] = useState(false);

  const location: any = useLocation();

  useEffect(() => {
    if (location.state !== undefined) {
      setCompanyId(location.state.companyId);
      requestData(location.state.companyId);
    }
  }, []);

  let history = useHistory();

  const addLinkedService = () => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/linked-service",
      state: {
        companyId,
      },
    });
  };

  const addCompanyService = () => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/company-service",
      state: {
        companyId,
      },
    });
  };

  const updateCompanyService = (companyService:any) => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/company-service",
      state: {
        companyId: companyService.companyID,
        servicesID: companyService.servicesID,
        fee: companyService.fee,
        companyServiceId: companyService.id
      },
    });
  }

  const isRemovedCompanyService = (companyService: any) => {
    setModalTitle(modalText[1].modalTitle);
    setModalBody(modalText[1].modalBody);
    setIsAlertModal(true);
    setCompanyService(companyService);
  };

  const updateStatusCompanyService = async (companyService: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.delete(
        `/admin/company-services/${companyService.id}`,
        {
          headers,
        }
      );
      const response = request.data;
      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setReload(true);
      setModal(true);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      console.error(error);
    }
  };

  const importPrices = (serviceId: any) => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/import-price",
      state: {
        serviceId,
      },
    });
  };

  const exportPrices = async (companyService: any) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`,
    }

    setIsLoading(true);
    try {
      const responseServer = await server.get(
        `/admin/company-sub-services/export/${companyService.id}`,
        {
          headers: headers,
          responseType: 'blob'
      });

        if(responseServer.status !== 200) {
          setIsLoading(false);
          console.log(responseServer.data.message);
          return;
        }
        const csvBlob = new Blob([responseServer.data], { type: 'application/csv'});
        saveAs(csvBlob, companyService.servicesID+"-"+companyService.serviceName+'.csv');

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        return;
      }
  };

  const requestData = async (idCompany: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get(
        `/admin/company-services/company/${idCompany}`,
        {
          headers,
        }
      );
      const response = request.data;
      let table: any = [];
      let content = response.data.map((value) => {
        return value.companyName;
      });
      let companyNames = {
        data: { title: "Company Name", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.serviceName;
      });
      let serviceNames = {
        data: { title: "Service Name", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.fee;
      });
      let fee = {
        data: { title: "Fee", content },
        button: false,
      };

      content = response.data.map((value) => {
        const companyService = value;

        return {
          button: [
            {
              label: "EDIT",
              onPressed: () => updateCompanyService(companyService),
            },
            {
              label: "DELETE",
              onPressed: (e: any) => isRemovedCompanyService(companyService),
            },
            {
              label: "IMPORT PRICES",
              onPressed: (e: any) => importPrices(companyService.id),
            },
            {
              label: "EXPORT PRICES",
              onPressed: (e: any) => exportPrices(companyService),
            },
          ],
        };
      });
      let buttons = {
        data: { title: "Buttons", content },
        button: true,
      };

      table.push(companyNames);
      table.push(serviceNames);
      table.push(fee);
      table.push(buttons);

      setCompanyServices(table);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const modalText = [
    {
      modalTitle: "Company Service",
      modalBody: "Company Service removed successfully",
    },
    {
      modalTitle: "Delete a Company Service",
      modalBody: "Are you sure a delete a company service?",
    },
  ];

  const onChangeModal = (value: any) => {
    setModal(value);
    if(reload){
      window.location.reload();
    }
  };

  const onChangeAlertModal = (value: any) => {
    if(value){
      updateStatusCompanyService(companyService);
      setIsAlertModal(!value);
    } else {
      setIsAlertModal(value);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <AlertModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={isAlertModal}
        onChangeModal={onChangeAlertModal}
      />
      <MyTitle title="Company services" />
      <div className="buttons-companies-service">
        <BtnAddList label="Linked service" onClick={addLinkedService} />
        <BtnAddList label="Add company service" onClick={addCompanyService} />
      </div>
      <section className="company-services-section">
        <ScheduleList data={companyServices} />
      </section>
    </>
  );
};

export default CompanyServices;
