import React from "react";
import { BsUpload } from "react-icons/bs";

import "./InputFile.css"

interface CardInputFile {
  onChange(e): any,
  value: string,
  text: string,
  id: number,
};

const InputFile: React.FC<CardInputFile> = ({onChange, value, text, id}) => {
  return (
    <>
      <label htmlFor={id+"logo-upload"} className="custom-file-upload">
        <i>
          <strong className="upload-text-inputfile">{text}</strong>
          <BsUpload />
        </i>
      </label>
      <input
        className="input-form-img"
        type="file"
        name={id+"logo-upload"}
        id={id+"logo-upload"}
        placeholder=""
        onChange={onChange}
        autoComplete="off"
        accept=".jpeg, .png, .jpg, .svg"
        value={value}
      />
    </>
  );
}

export default InputFile;
