import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../data/state";
import MyTitle from "../../components/MyTitle/MyTitle";
import MyModal from "../../components/MyModal/MyModal";
import Loading from "../../components/Loading/Loading";
import MyDatePicker from "../../components/MyDatePicker/MyDatePicker";
import InputTextComponent from '../../components/InputTextComponent/InputTextComponent';
import DatePicker from "react-datepicker";
import server from '../../api/server';
import moment from "moment";
import "./AddBalance.css"

const AddBalance: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [isError, setIsError] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalBody, setModalBody] = useState("");

  const [date, setDate] = useState(new Date());
  const [dateSelected, setDateSelected] = useState(false);
  const [textId, setTextId] = useState("");

  const [valueBalance, setValueBalance] = useState({
    description: "",
    charges: 0,
    payments: 0,
    ticketId: 0,
  });

  let history = useHistory();

  const handleSubmit = async() => {
    setIsActive(true);

    let id = valueBalance.ticketId;

    if(valueBalance.ticketId != 0) {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.token}`,
        };

        const request = await server.get(`/admin/tickets/${id}`,
                        { headers: headers }
                      );
        if (request.status === 200) {
          setTextId("Ticket ID was found.");
        }

        setIsActive(false);
      } catch (error) {
        setIsActive(false);
        setTextId("Cannot find this ticket Id.");
        id = 0;
      }
    }

    try {
      const data = {
        Description: valueBalance.description,
        Charges: valueBalance.charges,
        Payments: valueBalance.payments,
        Reference: id,
        Date: date,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.post(`/admin/upkeep-balance`,
                      JSON.stringify(data),
                      { headers: headers }
                    );

      setModalBody("Balance created.");
      setModal(true);
      setIsActive(false);
    } catch (error) {
      setModalBody("All fields are required.");
      setModal(true);
      setIsActive(false);
      console.log(error);
      setIsError(true);
      return;
    }
  };

  const handleChangeInput = (event: any): void => {
    setValueBalance({
      ...valueBalance,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeModal = (value: any) => {
    setModal(value);
    if(!isError) {
      history.goBack();
    } else {
      setIsError(false)
    }
  };

  const onChangeDate = (s: any) => {
    setDateSelected(true);
    setDate(s);
  };

  return (
    <>
      {isActive &&
          <Loading />
      }
      <MyModal
        modalTitle="Balance"
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title="Create Balance" />
      <div className="container-add-balance">
        <InputTextComponent
          idName="description"
          name="Description"
          value={valueBalance.description}
          handleChangeValue={handleChangeInput}
          inputType="text"
        />
        <InputTextComponent
          idName="charges"
          name="Charges"
          value={valueBalance.charges}
          handleChangeValue={handleChangeInput}
          inputType="number"
        />
        <InputTextComponent
          idName="payments"
          name="Payments"
          value={valueBalance.payments}
          handleChangeValue={handleChangeInput}
          inputType="number"
        />
        <InputTextComponent
          idName="ticketId"
          name="Ticket Id"
          value={valueBalance.ticketId}
          handleChangeValue={handleChangeInput}
          inputType="number"
        />
        {
          textId !== "" ?
            <p className="id-found-text">{textId}</p>
          : null
        }
        <div className="calendar-cointainer">
            <MyDatePicker
              setDatePicker={onChangeDate}
              startDate={date}
              isBlocked={true}
            />
        </div>
        <div className="add-balance-btn-container">
          <button className="button-sumbit-add-balance" type="button" onClick={handleSubmit}>
            Add Balance
          </button>
        </div>
      </div>
    </>
  );
}

export default AddBalance;
