import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../data/state";

import "./CreateZipCode.css";
import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";

const CreateZipCode: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  let history = useHistory();

  const [isError, setIsError] = useState<boolean>(false);

  const [stateID, setStateID] = useState("DEFAULT");
  const [listState, setListStates] = useState([]);

  const [countyID, setCountyID] = useState("DEFAULT");
  const [listCounty, setListCounty] = useState([]);

  const [cityID, setCityID] = useState("DEFAULT");
  const [listCity, setListCity] = useState([]);

  const [zipCode, setZipCode] = useState();


  const [isUpdateCreateLocationZip, setIsUpdateCreateLocationZip] =
    useState(false);

  const [idCompanyLocation, setIdCompanyLocation] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  const location: any = useLocation();
  useEffect(() => {
    if (location.state !== undefined) {
      if (location.state.zipCodeID) {
        setIsUpdateCreateLocationZip(true);
        setIdCompanyLocation(location.state.companyLocationID);
      } else {
        setIdCompanyLocation(location.state.companyLocationID);
      }
    }
    getStates();
  }, []);

  const getStates = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get("/customer/state", {
        headers,
      });

      const response = request.data;
      setListStates(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getCounties = async (stateId: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get(`/customer/county/state/${stateId}`, {
        headers,
      });

      const response = request.data;
      setListCounty(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getCities = async (countyId: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get(`/customer/city/county/${countyId}`, {
        headers,
      });

      const response = request.data;
      setListCity(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const modalText = [
    {
      modalTitle: "Zip Code",
      modalBody: "ZIP Code created succesfully",
    },
    {
      modalTitle: "Zip Code",
      modalBody: "Fill out the form",
    },
    {
      modalTitle: "Error!!!",
      modalBody: "Try again later",
    },
  ];

  const onChangeModal = (value: any) => {
    setModal(value);
    if(!isError){
      history.goBack();
    } else {
      setIsError(false);
    }
  };

  const onSubmitFormCompanyLocationZip = (e: any) => {
    e.preventDefault();
    if (
      stateID == "DEFAULT" || countyID == "DEFAULT" || cityID == "DEFAULT"
    ) {
      setModalBody(modalText[1].modalBody);
      setModalTitle(modalText[1].modalTitle);
      setIsError(true);
      setModal(true);
    } else {
      let data:any = {
        CityID: cityID,
        Active: true,
        ZIPCode: zipCode
      };

      addZipCode(data);
    }
  }

  const addZipCode = async (data:any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.post(
        "/admin/zip-codes",
        JSON.stringify(data),
        {
          headers,
        }
      );

      const response = request.data;
      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setModalTitle(modalText[2].modalTitle);
      setModalBody(modalText[2].modalBody);
      setIsError(true);
      setModal(true);
    }
  };

  const onChangeSelectState = (e: any) => {
    let id = e.target.value;
    setStateID(id);
    setCountyID("DEFAULT");
    setCityID("DEFAULT");
    getCounties(id);
  };

  const onChangeSelectCounty = (e: any) => {
    let id = e.target.value;
    setCountyID(id);
    setCityID("DEFAULT");
    getCities(id);
  };

  const onChangeSelectCity = (e: any) => {
    let id = e.target.value;
    setCityID(id);
  };

  const onChangeZipCode = (e: any) => {
    setZipCode(e.target.value);
  }


  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle
        title={
          isUpdateCreateLocationZip
            ? "Update Zip Code"
            : "Create Zip Code"
        }
      />
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">
              {isUpdateCreateLocationZip
                ? "Update a Zip Code"
                : "Create a Zip Code"}
            </h1>
          </div>
          <div className="card-body-create">
            <form
              action=""
              onSubmit={onSubmitFormCompanyLocationZip}
              className="form-container"
            >
              <div className="form-container-input">
                <span className="label-input-create">State: </span>
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-create select-create"
                  onChange={onChangeSelectState}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={stateID}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Select state --
                  </option>
                  {listState.map((stateObj: any, index: number) => {
                    return (
                      <option key={index} value={stateObj.id}>
                        {stateObj.stateName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-container-input">
                <span className="label-input-create">County: </span>
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-create select-create"
                  onChange={onChangeSelectCounty}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={countyID}
                  disabled={stateID == "DEFAULT" ? true : false}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Select county --
                  </option>
                  {listCounty.map((countyObj: any, index: number) => {
                    return (
                      <option key={index} value={countyObj.id}>
                        {countyObj.countyName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-container-input">
                <span className="label-input-create">City: </span>
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-create select-create"
                  onChange={onChangeSelectCity}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={cityID}
                  disabled={countyID == "DEFAULT" ? true : false}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Select city --
                  </option>
                  {listCity.map((cityObj: any, index: number) => {
                    return (
                      <option key={index} value={cityObj.id}>
                        {cityObj.cityName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-container-input">
                <span className="label-input-create">Zip Code: </span>
                <input
                  className="input-form-create"
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  onChange={onChangeZipCode}
                  autoComplete="off"
                  placeholder="Zip Code..."
                  value={zipCode}
                  required
                />
              </div>
              
              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateZipCode;
