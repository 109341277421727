import React, { useState, useEffect } from "react";
import "./CardServiceDetail.css";

interface CardItemProps {
  id: number,
  name: string,
  price: number,
};

const CardServiceDetail: React.FC<CardItemProps> = (
                                        {id=-1,
                                        name='',
                                        price=-1}) => {
  return (
    <>
      <ul className="list-service-info">
        <li className="list-item">
          <label className="fields-service">Name</label>
          <label className="fields-service-info">{name}</label>
        </li>
        <li className="list-item">
          <label className="fields-service">Price</label>
          <label className="fields-service-info">{price}</label>
        </li>
      </ul>
    </>
  );
}

export default CardServiceDetail;
