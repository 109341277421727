import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./UpdatePayment.css";
import { AppContext } from "../../data/state";
import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";
import moment from "moment";

const UpdatePayment: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [paymentID, setPaymentID] = useState("");

  const [listRequestStatus, setListRequestStatusID] = useState([]);
  const [requestStatusID, setRequestStatusID] = useState("DEFAULT");

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  const location: any = useLocation();
  let history = useHistory();

  const modalText = [
    {
      modalTitle: "Payment Request",
      modalBody: "Payment Request updated succesfully",
    },
  ];

  const getRequestStatus = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get("/admin/request-status", {
        headers,
      });

      const response = request.data;
      setListRequestStatusID(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location.state !== undefined) {
      setPaymentID(location.state.paymentID);
      getRequestStatus();
    }
  }, []);

  const onChangeSelectRequestStatus = (e: any) => {
    setRequestStatusID(e.target.value);
  };

  const onChangeModal = (value: any) => {
    setModal(value);
    history.goBack();
  };

  const onSubmitFormPayment = (e: any) => {
    e.preventDefault();
    updatePaymentRequest();
  };

  const updatePaymentRequest = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        RequestStatusID: requestStatusID,
      };
      const request = await server.put(
        `/admin/payment-request/${paymentID}`,
        JSON.stringify(data),
        {
          headers,
        }
      );
      
      console.log(data, paymentID)

      const response = request.data;
      setModalBody(modalText[0].modalBody);
      setModalTitle(modalText[0].modalTitle);
      setModal(true);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title={"Update Payment"} />
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">Update Payment</h1>
          </div>
          <div className="card-body-create">
            <form
              action=""
              onSubmit={onSubmitFormPayment}
              className="form-container"
            >
              <div className="form-container-input">
                <span className="label-input-create">Request Status: </span>
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-create select-create"
                  onChange={onChangeSelectRequestStatus}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={requestStatusID}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Select Request Status --
                  </option>
                  {listRequestStatus.map(
                    (requestStatusObj: any, index: number) => {
                      return (
                        <option key={index} value={requestStatusObj.id}>
                          {requestStatusObj.status}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdatePayment;
