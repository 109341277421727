import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../data/state";

import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import MyModal from "../../components/MyModal/MyModal";
import "./ListUsers.css";
import BtnAddList from "../../components/BtnAddList/BtnAddList";

const ListUsers: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  let history = useHistory();
  const [staffUsers, setStaffUsers] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  const modalText = [
    {
      modalTitle: "Staff User",
      modalBody: "Staff user updated successfully",
    },
  ];

  const addUser = () => {
    history.push("/create-user");
    window.scrollTo(0, 0);
  };

  const updateUser = (user: any) => {
    history.push({
      pathname: "/create-user",
      state: {
        name: user.name,
        email: user.email,
        id: user.id,
        staffGroupsID: user.staffGroupsID,
      },
    });
  };

  const updateStatusUser = async (user: any, e: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.delete(`/admin/users/${user.id}`, {
        headers,
      });
      const response = request.data;
      if (e.target.innerText === "ACTIVE") {
        e.target.classList.toggle("deactive-btn-user");
        e.target.innerText = "DEACTIVE";
      } else {
        e.target.classList.toggle("deactive-btn-user");
        e.target.innerText = "ACTIVE";
      }
      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      console.error(error);
    }
  };

  useEffect(() => {
    requestData();
  }, []);

  const requestData = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get("/admin/users", { headers });
      const response = request.data;
      let table: any = [];
      let content = response.data.map((value) => {
        return value.email;
      });
      let emails = {
        data: { title: "Email", content },
        button: false,
      };
      content = response.data.map((value) => {
        return value.name;
      });
      let names = {
        data: { title: "Name", content },
        button: false,
      };
      content = response.data.map((value) => {
        return value.staffGroupName;
      });
      let groups = {
        data: { title: "Type", content },
        button: false,
      };
      content = response.data.map((value) => {
        const user = value;
        return {
          button: [
            {
              label: "EDIT",
              onPressed: () => updateUser(user),
            },
            {
              label: user.active ? "ACTIVE" : "DEACTIVE",
              onPressed: (e: any) => updateStatusUser(user, e),
            },
          ],
        };
      });
      let buttons = {
        data: { title: "Buttons", content },
        button: true,
      };
      table.push(emails);
      table.push(names);
      table.push(groups);
      table.push(buttons);

      setStaffUsers(table);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      console.error(error);
    }
  };

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyTitle title="List Users" />
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <div className="container-btn-services">
        <BtnAddList label="Add new user staff" onClick={addUser} />
      </div>
      <section className="customer-section">
        <ScheduleList data={staffUsers} />
      </section>
    </>
  );
};

export default ListUsers;
