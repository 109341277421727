import React, { useState, useContext, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../data/state';
import NavbarItem from '../NavbarItem/NavbarItem';
import {User} from '../../Models/User';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import './MyNavbar.css';

const MyNavbar: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [isMobileActive, setIsMobileActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if(window.innerWidth < 600){
      setIsMobileActive(true);
    }
  },[])

  let history = useHistory();

  const _handleClickSignIn = () => {
    if(isMobileActive){
      setIsOpen(!isOpen)
    }
    history.push("/signin");
  }
  const _handleClickLogOut = async() => {
    const tempUser: User ={
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
    }
    await dispatch({
      type: 'set-is-loggedin',
      isLoggedin: false
    });
    await dispatch({
      type: 'set-user',
      user: tempUser
    });
    await dispatch({
      type: 'set-token',
      user: undefined
    });
    await dispatch({
      type: 'set-exist-ticket-id',
      existTicketId: false
    });
    await dispatch({
      type: 'set-ticket-id',
      ticketID: undefined
    });
    await dispatch({
      type: 'set-temp-password',
      isTempPassword: false,
    });
    await dispatch({
      type: 'set-permissions',
      permissions: [],
    });
    history.replace('/home');
  }

  const toggle = () => setIsOpen(!isOpen);

  const _handlenChangePassword = async() => {
    history.push('/reset-password');
  };

  const navItems = [
    {
      id: 1,
      title: 'TICKETS',
      locationHref: '/list-tickets'
    },
    {
      id: 2,
      title: 'COMPANY',
      locationHref: '/list-companies'
    },
    {
      id: 3,
      title: 'SERVICES',
      locationHref: '/list-services'
    },
    {
      id: 4,
      title: 'ZIP CODES',
      locationHref: '/list-zip-codes'
    },
    {
      id: 5,
      title: 'USERS',
      locationHref: '/list-users'
    },
    {
      id: 6,
      title: 'TOOLS',
      locationHref: '/tools'
    },
  ];

  return(
    <div>
      <Navbar className="my-navbar" dark expand="md">
        <div className="container-img-toggle">
          <NavbarBrand className="navbar-img" href="/">
            <div className="container-logo">
              <img src="assets/logo_white.png" className="logo-navbar"/>
              <span className="title-navbar">Staff</span>
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} className="burger"/>
        </div>
        <Collapse isOpen={isOpen} navbar>
        {
          state.permissions !== undefined &&
          <Nav className="ml-auto" navbar>
          {navItems.map((element) => {
            if(state.permissions.includes(element.id)) {
              return(
                <NavbarItem
                itemTitle={element.title}
                itemLoc={element.locationHref}
                key={element.id}
                toggle={toggle}
                />
              );
            }
          })}
          </Nav>
        }
          {state.isLoggedin
            ? <UncontrolledDropdown >
                <DropdownToggle
                  caret
                  color="primary"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    className="photo"
                    alt="..."
                    src="assets/login/default-avatar.png"
                  />
                </DropdownToggle>
                <DropdownMenu right >
                  <NavLink onClick={() => history.push('/profile')}>
                    <DropdownItem >Profile</DropdownItem>
                  </NavLink>
                  <NavLink onClick={_handlenChangePassword}>
                    <DropdownItem >Change Password</DropdownItem>
                  </NavLink>
                  <DropdownItem divider  />
                  <NavLink onClick={_handleClickLogOut}>
                    <DropdownItem >Log out</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
            : <button onClick={_handleClickSignIn} className="btn-sign-in-nav">Sign in</button>
          }
        </Collapse>
      </Navbar>
    </div>
  );
}

export default MyNavbar;
