import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import {  Button, Form, FormGroup, Label, Input,
          Modal, ModalBody, ModalFooter
} from 'reactstrap';
import Loading from "../../components/Loading/Loading";
import server from "../../api/server";
import { AppContext } from '../../data/state';
import {serviceTicket} from "../../views/PaymentInformation/PaymentInformation";
import "./CheckoutForm.css";

interface checkOutFormProps {
  name?: string,
  email?: string,
  phone?: number,
  addres?: string,
  companyId?: number,
  scheduleDate?: string,
  scheduleId?: null,
  objServiceTicket?: serviceTicket,
}

const CheckoutForm: React.FC<checkOutFormProps>  =({
    name="", email="", phone=null, addres="", scheduleId=null,
    companyId=null, scheduleDate="", objServiceTicket=null})  =>{
  let history = useHistory();
  const [succeeded, setSucceeded] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const stripe:any = useStripe();
  const elements:any = useElements();
  const [boxChecked, setBoxChecked] = useState(false);
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  useEffect(() => {
    if(state.existPaymentIntent){
      makeStripePayment();
    }
    if(state.paymentError){
      setErrorMsg(state.paymentErrorMessage);
    }
  }, [])

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setErrorMsg(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    if (name == '' || email == '' || phone == 0 || addres == '') {

      await dispatch({
        type: 'set-payment-error',
        paymentError: true
      })
      setError(true);
      setErrorMsg("Please fill out all fields.");
    }
    else {
      setProcessing(true);
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.token}`
      }
      //starting stage of creating a ticket
      const dataTicket = {
        CompanyID: 1,
        ScheduledDate: "2021-03-30",
        ScheduleID: 1
      }

      await server.post('/customer/ticket', JSON.stringify(dataTicket), { headers: headers })
        .then(async (res) => {
          const response = res.data;

          await dispatch({
            type: 'set-exist-ticket-id',
            existTicketId: true
          });
          await dispatch({
            type: 'set-ticket-id',
            ticketID: response.data.id
          })
          await dispatch({
            type: 'set-card-element',
            cardElement: elements.getElement(CardElement),
          })
          console.log(response.data.id);
          //start of sending services list of ticket
          const dataListTicket = [
            {
              CompaniesSubServicesID: 14,
              Quantity: 10,
              PriceID: 1,
              Price: 812.80,
            }
          ]
          await server.post(`/customer/ticket-services/${response.data.id}`,
            JSON.stringify(dataListTicket), { headers: headers })
            .then(async (res) => {
              const response = res.data;

              history.push('/process-payment');
            })
            .catch(async (error) => {
              const response = error.response;
              console.log(JSON.stringify(dataListTicket));
              return;
            })
          //end of sending services list of ticket

        })
        .catch(async (error) => {
          const response = error.response;
          console.log(response.data);
          return;
        })
      //end of creating ticket's stage
    }
  };

  const makeStripePayment = async() =>{
    //Start of getting payload
    setProcessing(true);
    console.log(state.cardElement)
    /*
    const payload = await stripe.confirmCardPayment(state.clientSecret, {
      payment_method: {
        card: state.cardElement
      }
    });
    if (payload.error) {
      await dispatch({
        type: 'set-payment-error',
        paymentError: true
      })
      await dispatch({
        type: 'set-payment-error-message',
        paymentErrorMessage: `Payment failed ${payload.error.message}`
      })
      console.log(`Payment failed ${payload.error.message}`);
      history.push('/payment-confirm');
    } else {
      console.log(payload);
      await dispatch({
        type: 'set-payment-error-message',
        paymentErrorMessage: undefined
      })
      history.push('/payment-confirm');
    }
     */
    history.push('/payment-confirm');
    //End of getting payload
    setProcessing(false);
  }

  const toggle = async() => {
    setError(!error)
   };

  return (
    <>
      {processing && <Loading />}
      <div className="container-card-element">
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
        />
      </div>


      <FormGroup check className="check-terms">
        <label
          className="label-check"
          defaultChecked={boxChecked}
          onChange={() => setBoxChecked(!boxChecked)}
        >
          I agree with <a href="#" className="terms-service">terms of service</a>
          <input className="checkout-form-input check-input" type="checkbox" ></input>
          <span className="checkmark"></span>
        </label>
      </FormGroup>

      <Button
        disabled={!boxChecked || processing || disabled || succeeded}
        className="form-submit"
        onClick={(e) => handleSubmit(e)}
      >
        <strong>PAY NOW</strong>
      </Button>

      <Modal isOpen={error} toggle={toggle} className="width-modal">
        <ModalBody>
            {errorMsg}
        </ModalBody>
        <ModalFooter>
          <Button className="width-modal-btn" color="primary" onClick={toggle}>OK</Button>
        </ModalFooter>
      </Modal>

    </>
  );
}

export default CheckoutForm;
