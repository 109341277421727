import moment from "moment";
import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyDatePicker from "../../components/MyDatePicker/MyDatePicker";

import MyModal from '../../components/MyModal/MyModal'
import MyTitle from "../../components/MyTitle/MyTitle";

import { AppContext } from "../../data/state";

import "./PaymentReq.css";


const PaymentReq: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [datePicker, setDatePicker] = useState(new Date());
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  const location: any = useLocation();
  useEffect(() => {
    
  }, []);


  const onSubmitFormPaymentRequest = async (e: any) => {
    e.preventDefault();
    const dateFormated = formatDate(datePicker)
    setIsLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${state.token}`,
        "Content-Type": "application/json",
      };      
      const request = await server.post('/admin/payment-request',JSON.stringify(dateFormated),{headers})
      const response = request.data;
      setIsLoading(false);
      setModalTitle("Payment Request");
      setModalBody("Payment Requests created Successfully")
      setModal(true);
    } catch (error) {
      const response = error.response;
      const message = response.data.message;
      console.error(message);
      setModalTitle("Payment Request");
      setModalBody(message)
      setIsError(true);
      setModal(true);
      setIsLoading(false);
    }
  };

  const onChangeModal = (value: any) => {
    setModal(value);
    if(!isError){
      history.goBack();
    }
  };

  const onChangeDatePicker = (value) => {
    setDatePicker(value);
  };

  const formatDate = (date: any) => moment(date).format("YYYY-MM-DD");


  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle
        title={"Payment Request"}
      />
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">
              {"Payment Request All Companies"}
            </h1>
          </div>
          <div className="card-body-create">
            <form
              action=""
              onSubmit={onSubmitFormPaymentRequest}
              className="form-container"
            >
              <div className="form-container-input">
                <span className="label-input-create">Choose a date: </span>
                <MyDatePicker
                  setDatePicker={onChangeDatePicker}
                  startDate={datePicker}
                />
              </div>
              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentReq;
