import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./MyModal.css";

export default function MyModal({
  modalTitle,
  modalBody,
  modalValue,
  onChangeModal,
}) {
  const [modal, setModal] = useState(modalValue);

  const toggle = () => {
    onChangeModal(!modal);
    setModal(!modal);
  };

  useEffect(() => {
      setModal(modalValue);
  }, [modalValue])

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Ok
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
}
