import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../data/state";

import "./CreateUser.css";
import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";

const CreateUser: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [idUser, setIdUser] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [staffGroupsID, setStaffGroupsID] = useState("1");

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const [typeOfUser, setTypeOfUser] = useState<any[]>([]);
  const [selectTypeOfUSer, setSelectTypeOfUSer] = useState(0);
  const location: any = useLocation();

  useEffect(() => {
    if (location.state !== undefined) {
      setIsUpdateUser(true);
      setName(location.state.name);
      setEmail(location.state.email);
      setIdUser(location.state.id);
      setStaffGroupsID(location.state.staffGroupsID);

    }
    loadGroups();
  }, []);

  let history = useHistory();

  const modalText = [
    {
      modalTitle: "Staff User",
      modalBody: "Staff User created successfully",
    },
    {
      modalTitle: "User found",
      modalBody: "User already registered, Choose another email",
    },
    {
      modalTitle: "Staff User",
      modalBody: "Staff user updated successfully",
    },
  ];

  const onSubmitFormUser = async (e: any) => {
    e.preventDefault();
    let data = {
      Name: name,
      Email: email,
      Password: password,
      StaffGroupsID: selectTypeOfUSer,
    };

    if (!isUpdateUser) {
      addUser(data);
    } else {
      let data = {
        Name: name,
        Email: email,
        StaffGroupsID: selectTypeOfUSer,
      };
      updateUser(data);
    }
  };

  const addUser = async (data: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.post("/admin/users", JSON.stringify(data), {
        headers: headers,
      });
      const response = request.data;

      if (response.statusCode != 200) {
        return;
      }

      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setIsLoading(false);
      setModal(true);
      setTimeout(() => {
        history.goBack();
      }, 2500);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      if (response) {
        switch (response.status) {
          case 400: {
            setModalTitle(modalText[1].modalTitle);
            setModalBody(modalText[1].modalBody);
            setModal(true);
            break;
          }
          case 401: {
            break;
          }
        }
      }
    }
  };

  const updateUser = async (data: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.put(
        `/admin/users/${idUser}`,
        JSON.stringify(data),
        {
          headers: headers,
        }
      );
      const response = request.data;

      if (response.statusCode != 200) {
        return;
      }

      setModalTitle(modalText[2].modalTitle);
      setModalBody(modalText[2].modalBody);
      setIsLoading(false);
      setModal(true);
      setTimeout(() => {
        history.goBack();
      }, 2500);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const loadGroups = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get(
        "/admin/staff-groups",
        {
          headers: headers,
        }
      );

      const response = request.data;
      setTypeOfUser(response.data);

      if (response.statusCode != 200) {
        return;
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const onChangeName = (e: any) => {
    setName(e.target.value);
  };

  const onChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  const onChangeSelectStatus = async (e: any) => {
    setSelectTypeOfUSer(e.target.value);
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title="Staff user" />
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">
              {isUpdateUser ? "Update user" : "Create user"}
            </h1>
          </div>
          <div className="card-body-create">
            <form
              action=""
              onSubmit={onSubmitFormUser}
              className="form-container"
            >
              <div className="form-container-input">
                <span className="label-input-create">Name: </span>
                <input
                  className="input-form-create"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name..."
                  onChange={onChangeName}
                  autoComplete="off"
                  required
                  value={name}
                />
              </div>
              <div className="form-container-input">
                <span className="label-input-create">Email: </span>
                <input
                  className="input-form-create"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email..."
                  onChange={onChangeEmail}
                  autoComplete="off"
                  required
                  value={email}
                />
              </div>
              {
                !isUpdateUser &&
                <div className="form-container-input">
                  <span className="label-input-create">Password: </span>
                  <input
                    className="input-form-create"
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password..."
                    onChange={onChangePassword}
                    autoComplete="off"
                    required
                  />
                </div>
              }
              <div className="form-container-input">
                <span className="label-input-create">Type of user: </span>
                <select
                  className="input-form-create"
                  value={selectTypeOfUSer}
                  onChange={onChangeSelectStatus}
                >
                  <option value={0} className="filter-option" disabled>Select Type</option>
                  {
                    typeOfUser &&
                      typeOfUser.map((item) => (
                        item.active ? (
                          <option className="filter-option" key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ) : null
                    ))
                  }
                </select>
              </div>
              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateUser;
