import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./ListZipCodes.css"
import { AppContext } from "../../data/state";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyTitle from "../../components/MyTitle/MyTitle";
import BtnAddList from "../../components/BtnAddList/BtnAddList";

const ListZipCodes: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [allZipCodes, setZipCodes] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  let history = useHistory();

  const modalText = [
    {
      modalTitle: "Zip Code",
      modalBody: "Zip Code updated successfully.",
    },
  ];

  useEffect(() => {
    requestData();
  }, []);

  const addZipCode = () => {
    history.push("/create-zip-code");
    window.scrollTo(0, 0);
  };

  const updateStatusZipCode = async (zipCode: any, e: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const request = await server.delete(`/admin/zip-codes/${zipCode.id}`, {
        headers,
      });

      const response = request.data;

      if (e.target.innerText === "ACTIVE") {
        e.target.classList.toggle("deactive-btn-user");
        e.target.innerText = "DEACTIVE";
      } else {
        e.target.classList.toggle("deactive-btn-user");
        e.target.innerText = "ACTIVE";
      }

      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      console.error(error);
    }
  };

  const requestData = async () => {
    try {
      setIsLoading(true);

      const headers = {
        "Content-Type": "application/json",
      };

      const request = await server.get("/zip-codes", { headers });
      const response = request.data;

      let table: any = [];

      let content = response.data.map((value) => {
        return value.zipCode;
      });

      let zipCodes = {
        data: { title: "Zip Code", content },
        button: false,
      };


      content = response.data.map((value) => {
        let {city: {cityName}} = value;

        return cityName;
      });

      let city = {
        data: { title: "City", content },
        button: false,
      };

      content = response.data.map((value) => {
        let {city: {majorCity: {majorCityName}}} = value;

        return majorCityName;
      });

      let majorCity = {
        data: { title: "Major City", content },
        button: false,
      };

      content = response.data.map((value) => {
        let {city: {majorCity: {county: {state: {stateName}}}}} = value;

        return stateName;
      });

      let state = {
        data: { title: "State", content },
        button: false,
      };

      content = response.data.map((value) => {
        const zipCode = value;
        return {
          button: [
            {
              label: zipCode.active ? "DEACTIVE" : "ACTIVE",
              onPressed: (e: any) => updateStatusZipCode(zipCode, e),
            },
          ],
        };
      });

      let buttons = {
        data: { title: "Buttons", content },
        button: true,
      };
      table.push(zipCodes);
      table.push(city);
      table.push(majorCity);
      table.push(state);
      table.push(buttons);

      setZipCodes(table);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const response = error.response;
      console.error(error);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyTitle title="My Zip Codes" />
      <div className="container-btn-services">
        <BtnAddList label="Add new Zip Code" onClick={addZipCode} />
      </div>
      <section className="companies-section">
        <ScheduleList data={allZipCodes} />
      </section>
    </>
  );
}

export default ListZipCodes;
