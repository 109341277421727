import React, { useState } from 'react';
import MyTitle from '../../components/MyTitle/MyTitle';
import { useHistory } from "react-router-dom";
import './Tools.css';

const Tools: React.FC = () => {
  let history = useHistory();

  return (
    <div className="tool-view">
      <MyTitle title="Tools" />
      <div className="container-section-tools">
        <div className="card-tools">
          <div className="col-card-tools">
            <div className="container-text-tools">
              <div className="text-section">
                <div
                  className="item-text-section-tools handle-text-tools"
                  onClick={() => history.push("/balance")}
                >
                  <div>
                    <div className="number-item-tools">{1}</div>
                  </div>
                  <div className="text-item-tools">
                    <span>UpKeep Balance</span>
                  </div>
                </div>
              </div>
              <div className="text-section">
                <div
                  className="item-text-section-tools handle-text-tools"
                  onClick={() => history.push("/parameters")}
                >
                  <div>
                    <div className="number-item-tools">{2}</div>
                  </div>
                  <div className="text-item-tools">
                    <span>Parameters</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tools;
