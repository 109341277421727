import "./ScheduleItem.css";

function ScheduleItem({ data, button }) {
  return (
    <div className="schedule-container">
      <div className="schedule-header">
        <p className="schedule-header-title">
          <strong> {button ? "" : data?.title} </strong>
        </p>
      </div>
      {button ? (
        <div className="schedule-header">
          {data?.content.map((value: any, index: number) => {
            return (
              <div className="schedule-buttons-container" key={index}>
                {value.button.map((item: any, index: number) => {
                  return (
                    <button
                      onClick={item.onPressed}
                      key={index}
                      className={`${
                        item.label == "DEACTIVE" ? "deactive-btn-user" : ""
                      }`}
                    >
                      {item.label}
                    </button>
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="schedule-header">
          {data?.content.map((item: string, index: number) => (
            <div className="text-container" key={index}>
              <span>{item}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ScheduleItem;
