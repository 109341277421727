import React, { createContext, useReducer, useEffect } from "react";
import { useLocalStorage } from './useLocalStoraje';

let AppContext = createContext();

const initialState = {
  isLoggedin: false,
  token: undefined,
  user: undefined,
  isAddOn: undefined,
  permissions: [],
  isTempPassword: false,
}

let reducer = (state, action) => {
  switch (action.type) {
    case 'set-is-loggedin':
      return { ...state, isLoggedin: action.isLoggedin };
    case 'set-token':
      return { ...state, token: action.token };
    case 'set-user':
      return { ...state, user: action.user };
    case "set-is-add-on":
      return {...state, isAddOn: action.isAddOn}
    case "set-permissions":
      return {...state, permissions: action.permissions}
    case 'set-temp-password':
      return { ...state, isTempPassword: action.isTempPassword };
    default:
      return state;
  }
};

function AppContextProvider(props) {
  const fullInitialState = {
    ...initialState
  }

  let [data, setData] = useLocalStorage('upkeep', fullInitialState);
  let [state, dispatch] = useReducer(reducer, data);
  let value = { state, dispatch };

  useEffect(() => {
    setData(state);
  }, [state, setData]);

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
