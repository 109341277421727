import React, {useState, useEffect} from 'react';
import { Button, Card, CardImg } from 'reactstrap';
import "./CardService.css";

interface CardItemProps {
  itemImg: string,
  itemService: string,
  itemList: any[],
  onClickButton(): any,
};

function useWindowDimensions() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  return {
    width,
    height,
  }
}

const CardService: React.FC<CardItemProps> = ({itemImg='', itemService='', itemList=[], onClickButton}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if(width > 867) {
      setIsOpen(true);
    }
  })

  return (
    <div className="card card-div">
      <Card>
        <div className="title-div row">
          <div className="img-container">
            <CardImg top width="" src={itemImg} className="img-title" />
          </div>
          <div className="card-title">
              <label><strong>{itemService.toUpperCase()} </strong></label>
          </div>
        </div>
        <div className={!isOpen ? 'collapse-card': 'collapse-card-open'}>
          <div className="card-body">
            <div className="card-text accordion">
              <div className="card-text accordion-item">
                <ul className="list">
                  {
                    itemList.map(item => (
                      <li key={item.id}>{item.description}</li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
          <Button onClick={onClickButton} className="book-button"><strong>Book</strong></Button>
        </div>
        <a className="arrow" onClick={toggle}>
        </a>
      </Card>
    </div>
  );
}

export default CardService;
