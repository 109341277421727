import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../data/state";

import "./CreateLocation.css";

import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";

const CreateLocation: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  let history = useHistory();

  const [locationName, setLocationName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [statusLocationCompany, setStatusLocationCompany] = useState(false);

  const [selectStatusLocation, setSelectStatusLocation] = useState("DEFAULT");
  const [isUpdateCreateLocation, setIsUpdateCreateLocation] = useState(false);
  const [isError, setIsError] = useState(false);

  const [idCompanyLocation, setIdCompanyLocation] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  const location: any = useLocation();
  useEffect(() => {
    if (location.state !== undefined) {
        if(location.state.locationName){
          setIsUpdateCreateLocation(true);
          setIdCompanyLocation(location.state.idCompanyLocation);
  
          setLocationName(location.state.locationName);
          setAddress(location.state.address);
          setPhoneNumber(location.state.phoneNumber);
          setStatusLocationCompany(location.state.active);
  
          setSelectStatusLocation(location.state.active ? "1" : "0");
        } else {
          setIdCompanyLocation(location.state.idCompanyLocation);
        }
    } else {
      history.push('/list-companies');
    }
  }, []);

  const modalText = [
    {
      modalTitle: "Company Location",
      modalBody: "Company Location created succesfully",
    },
    {
      modalTitle: "Company Location",
      modalBody: "Choose a status to Company Location",
    },
    {
      modalTitle: "Company Location",
      modalBody: "Company Location updated succesfully",
    },
  ];

  const onChangeModal = (value: any) => {
    setModal(value);
    if(!isError){
      history.goBack();
    }
  };

  const onSubmitFormCompanyLocation = (e: any) => {
    e.preventDefault();
    if (selectStatusLocation == "DEFAULT" || selectStatusLocation == "") {
      setModalBody(modalText[1].modalBody);
      setModalTitle(modalText[1].modalTitle);
      setModal(true);
    } else {
      if (isUpdateCreateLocation) {
        updateCompanyLocation();
      } else {
        addCompanyLocation();
      }
    }
  };

  const addCompanyLocation = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        LocationName: locationName,
        Address: address,
        PhoneNumber: phoneNumber,
        Active: statusLocationCompany,
      };
      const request = await server.post(
        `/admin/company-locations/company/${idCompanyLocation}`,JSON.stringify(data),{headers}
      );
      const response = request.data;
      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
      setIsError(false);
    } catch (error) {
      console.error(error);
      setIsError(true);
      setModalTitle(modalText[0].modalTitle);
      setModalBody("Location Name in use. Please select other location.");

      setModal(true);
      setIsLoading(false);
    }
  };

  const updateCompanyLocation = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        Address: address,
        PhoneNumber: phoneNumber,
        Active: statusLocationCompany,
      };
      const request = await server.put(
        `/admin/company-locations/${idCompanyLocation}`,
        JSON.stringify(data),
        {
          headers,
        }
      );
      setIsLoading(false);
      setModalTitle(modalText[2].modalTitle);
      setModalBody(modalText[2].modalBody);
      setModal(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const onChangeLocationName = (e: any) => {
    setLocationName(e.target.value);
  };

  const onChangeAddress = (e: any) => {
    setAddress(e.target.value);
  };

  const onChangePhoneNumber = (e: any) => {
    setPhoneNumber(e.target.value);
  };

  const onChangeSelectService = (e: any) => {
    setSelectStatusLocation(e.target.value);
    if (e.target.value == "1") {
      setStatusLocationCompany(true);
    } else {
      setStatusLocationCompany(false);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle title={isUpdateCreateLocation ? "Update Location" : "Create Location"} />
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">
              {isUpdateCreateLocation ? "Update a Location" : "Create a Location"}
            </h1>
          </div>
          <div className="card-body-create">
            <form
              action=""
              onSubmit={onSubmitFormCompanyLocation}
              className="form-container"
            >
              <div className="form-container-input">
                <span className="label-input-create">Location Name: </span>
                <input
                  className="input-form-create"
                  type="text"
                  name="locationName"
                  id="locationName"
                  onChange={onChangeLocationName}
                  autoComplete="off"
                  placeholder="Location Name..."
                  value={locationName}
                  required
                />
              </div>
              <div className="form-container-input">
                <span className="label-input-create">Address: </span>
                <input
                  className="input-form-create"
                  type="text"
                  name="address"
                  id="address"
                  onChange={onChangeAddress}
                  autoComplete="off"
                  placeholder="Address..."
                  value={address}
                  required
                />
              </div>
              <div className="form-container-input">
                <span className="label-input-create">Phone Number: </span>    
                <input
                  className="input-form-create"
                  type="number"
                  name="phoneNumber"
                  id="phoneNumber"
                  onChange={onChangePhoneNumber}
                  autoComplete="off"
                  placeholder="Phone Number..."
                  value={phoneNumber}
                  required
                />
              </div>
              <div className="form-container-input">
                <span className="label-input-create">Status Location: </span>    
                <select
                  name="selectService"
                  id="selectService"
                  className="input-form-create select-create"
                  onChange={onChangeSelectService}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={selectStatusLocation}
                  required
                >
                  <option value="DEFAULT" disabled>
                    -- Choose a status to location --
                  </option>
                  <option value="0">Deactive</option>
                  <option value="1">Active</option>
                </select>
              </div>
              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateLocation;
