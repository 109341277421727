import React, { useState, useEffect } from "react";
import "./MyTime.css";

function MyTime({ time, onChangeListTimes, index, valueSelected, isAvailable }) {
  const [selected, setSelected] = useState(valueSelected);

  useEffect(() => {
    setSelected(valueSelected);
  }, [valueSelected])

  const onChangeSelected = () => {
    if(isAvailable){
      onChangeListTimes(time, index);
      setSelected(!selected);
    } 
  };
  return (
    <div
      className="item-time"
      onClick={onChangeSelected}
      style={{ 
        backgroundColor: isAvailable ? (selected ? "#cce7e4" : "#fff") : '#808080' , 
        border: isAvailable ? '1px solid var(--main-up-keep)' : '1px solid #808080' 
      }}
    >
      <span style={{ color: isAvailable ? (selected ? "#000" : "var(--main-up-keep)") : '#666' }}>
        {time}
      </span>
    </div>
  );
}

export default MyTime;
