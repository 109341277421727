//URL produccion
export const SERVER_URL = 'https://upkeep.wsd.com.mx/api';
//URL test
//export const SERVER_URL = 'https://testapi.wsd.com.mx/api';


//export const LOAD_STRIPE = "pk_test_51IAkL5Gsd21H7bBVw9wZt4j9ycUq0WlCzAm01UEbTZ4M7QNmtQEFDYwsTftQlvdUTYSjTEW92b6x7z2W45pngNQ600EZMMJBbd";
export const LOAD_STRIPE = "pk_live_51IAkL5Gsd21H7bBVWGX2HtssB5nkmnLvZO69qe9TLOkqdBd4Mf0p55fetbRb4kkY0ielhZYF3npwzDFQgTd88tc600bJX2rr8Q";
export const MAPS_API_KEY = "AIzaSyD-ecx8Cv5hwPzTcLWtlPd4VpRX8TCJ2tA";

export const FACEBOOK_PIXEL = "3073787856275917";
