import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../data/state";
import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";

import "./CreatePriority.css";

const modalText = [
  {
    modalTitle: "Service Priority",
    modalBody: "Service Priority updated successfully",
  },
  {
    modalTitle: "Service Priority",
    modalBody: "Fill all the fields",
  },
  {
    modalTitle: "Service not available",
    modalBody: "Try again later",
  },
];

const CreatePriority: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [priority, setPriority] = useState("");
  const [priorityId, setIdPriority] = useState("");
  const [nameCompany, setNameCompany] = useState("");
  const [isUpdateCreatePriority, setIsUpdateCreatePriority] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [isError, setIsError] = useState(false);

  const location: any = useLocation();
  const history: any = useHistory();
  useEffect(() => {
    if (location.state !== undefined) {
      setIsUpdateCreatePriority(true);
      setPriority(location.state.priority);
      setIdPriority(location.state.idPriority);
    setNameCompany(location.state.nameCompany);
    }
  }, []);

  const onChangeModal = (value: any) => {
    setModal(value);
    if (!isError) {
      history.goBack();
    } else {
      setIsError(false);
    }
  };

  const onSubmitFormPriority = (e: any) => {
    e.preventDefault();
    updatePriority();
  };

  const updatePriority = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };

      const data = {
          "Priority": priority,
      }

      const request = await server.put(
        `/admin/service-priority/${priorityId}`,
        JSON.stringify(data),
        {
          headers,
        }
      );

      const response = request.data;
      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setIsError(true);

      setModalTitle(modalText[2].modalTitle);
      setModalBody(modalText[2].modalBody);
      setModal(true);
    }
  };

  const onChangePriority = ({ target: { value } }: any) => {
    setPriority(value);
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle
        title={
          isUpdateCreatePriority ? "Update a Priority" : "Create a Priority"
        }
      />
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">
              {isUpdateCreatePriority
                ? "Update a Priority"
                : "Create a Priority"}
            </h1>
          </div>
          <div className="card-body-create">
            <form
              action=""
              onSubmit={onSubmitFormPriority}
              className="form-container"
            >
                <h4>Name company: {nameCompany}</h4>
              <div className="form-container-input">
                <span className="label-create-input">Priority:</span>
                <input
                  className="input-form-create"
                  type="number"
                  name="priority"
                  id="priority"
                  onChange={onChangePriority}
                  autoComplete="off"
                  placeholder="Priority..."
                  value={priority}
                  required
                  min={1}
                />
              </div>

              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreatePriority;
