import React, { useState, useEffect,useContext } from "react";
import MyTitle from "../../components/MyTitle/MyTitle";
import Loading from "../../components/Loading/Loading";
import { AppContext } from '../../data/state';
import server from '../../api/server';
import MyModal from "../../components/MyModal/MyModal";
import ScheduleList from "../../components/ScheduleList/ScheduleList";
import BtnAddList from "../../components/BtnAddList/BtnAddList";


import "./ListValues.css"
import { useHistory, useLocation } from "react-router";


const ListValues: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [listValues, setListValues] = useState([]);
  const [idFeature, setIdFeature] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);

  let history = useHistory();

  const location: any = useLocation();


  useEffect(() => {
    if (location.state !== undefined) {
      setIdFeature(location.state.idFeature)
      requestData(location.state.idFeature)
    }
  }, []);

  const onChangeModal = (value: any) => {
    setModal(value);
  };

  const addValue = () => {
    window.scrollTo(0, 0);
    history.push({
      pathname: "/create-value",
      state: {
        idFeature,
      },
    });
  }

  const requestData = async (idFeature:any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const request = await server.get(
        `/admin/feature-values/feature/${idFeature}`,
        {
          headers,
        }
      );
      const response = request.data;
      let table: any = [];

      let content = response.data.map((value) => {
        return value.displayName;
      });
      let values = {
        data: { title: "Display Names", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.active ? 'Active' : 'Deactive';
      });
      let status = {
        data: { title: "Status", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.isServiceCall ? 'Yes' : 'No';
      });
      let serviceCall = {
        data: { title: "Is a Service Call?", content },
        button: false,
      };

      content = response.data.map((value) => {
        return value.disclaimer;
      });

      let disclaimer = {
        data: { title: "Disclaimer", content },
        button: false,
      };


      content = response.data.map((value) => {
        const feature = value;
        return {
          button: [
            {
              label: "EDIT",
              onPressed: () => updateValue(feature),
            },
          ],
        };
      });
      let buttons = {
        data: { title: "Buttons", content },
        button: true,
      };

      table.push(values);
      table.push(serviceCall);
      table.push(status);
      table.push(disclaimer);
      table.push(buttons);

      setListValues(table);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const updateValue = (value:any) => {
    window.scrollTo(0, 0);

    history.push({
      pathname: "/create-value",
      state: {
        idFeature: value.serviceFeaturesID,
        value: value.value,
        requiredValue: value.requiredValue,
        active: value.active,
        idValue: value.id,
        displayName: value.displayName,
        isServiceCall: value.isServiceCall,
        disclaimer: value.disclaimer
      },
    });
  }

  return(
    <>
        <MyTitle title="List Answer"/>
        {isLoading && <Loading />}
        <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
        {!state.isAddOn &&
          <BtnAddList label="Add answer" onClick={addValue} />
        }
        <section className="section-list-values">
            <ScheduleList data={listValues} />
        </section>
    </>
  );
}

export default ListValues;
