import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../data/state";

import "./CreateValue.css";

import MyTitle from "../../components/MyTitle/MyTitle";
import server from "../../api/server";
import Loading from "../../components/Loading/Loading";
import MyModal from "../../components/MyModal/MyModal";

const modalText = [
  {
    modalTitle: "Feature Value",
    modalBody: "Feature Value created succesfully",
  },
  {
    modalTitle: "Feature Value",
    modalBody: "Fill out the form",
  },
  {
    modalTitle: "Feature Value",
    modalBody: "Feature Value updated succesfully",
  },
  {
    modalTitle: "Feature Value",
    modalBody: "Service Call values should be created only on first question",
  },
];

const CreateValue: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [value, setValue] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [disclaimer, setDisclaimer] = useState("");

  const [idFeature, setIdFeature] = useState("");
  const [idValue, setIdValue] = useState("");

  const [requiredValues, setRequiredValues] = useState<any[]>([]);
  const [selectRequiredValues, setSelectRequiredValues] = useState("DEFAULT");

  const [statusValue, setStatusValue] = useState(false);
  const [selectStatusValue, setSelectStatusValue] = useState("DEFAULT");

  const [isUpdateCreateValue, setIsUpdateCreateValue] = useState(false);
  const [isServiceCall, setIsServiceCall] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [isError, setIsError] = useState(false);

  const location: any = useLocation();
  const history: any = useHistory();
  useEffect(() => {
    if (location.state !== undefined) {
      loadRequiredValues(location.state.idFeature);
      if (location.state.idValue) {
        setIsUpdateCreateValue(true);
        setIdFeature(location.state.idFeature);
        setIdValue(location.state.idValue);
        setValue(location.state.value);
        setDisplayName(location.state.displayName);
        setDisclaimer(location.state.disclaimer);
        setSelectRequiredValues(
          location.state.requiredValue
            ? location.state.requiredValue
            : "DEFAULT"
        );

        setStatusValue(location.state.active);
        setSelectStatusValue(location.state.active ? "1" : "0");
        setIsServiceCall(location.state.isServiceCall);
      } else {
        setIdFeature(location.state.idFeature);
      }
    } else {
    }
  }, []);

  const loadRequiredValues = async (idFeature: string) => {
    setIsLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${state.token}`,
    };
    try {
      const request = await server.get(
        `/admin/feature-values/required-values/${idFeature}`,
        {
          headers,
        }
      );

      const responseServer = request.data;
      let flag = false;
      for (const iterator of responseServer.data) {
        if (iterator.serviceFeaturesID === idFeature) {
          flag = true;
          break;
        }
      }

      if (flag) {
        setRequiredValues([]);
      } else {
        setRequiredValues(responseServer.data);
      }

      if (responseServer.data.length === 0) {
        setRequiredValues([]);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.warn("Not found required values");
    }
  };

  const onChangeModal = (value: any) => {
    if (!isError) {
      history.goBack();
    } else {
      setIsError(false);
    }
    setModal(value);
  };

  const onSubmitFormCompanyService = (e: any) => {
    e.preventDefault();
    if (selectStatusValue == "DEFAULT") {
      setModalBody(modalText[1].modalBody);
      setModalTitle(modalText[1].modalTitle);
      setIsError(true);
      setModal(true);
    } else {
      if (isUpdateCreateValue) {
        updateValue(idValue);
      } else {
        addValue();
      }
    }
  };

  const addValue = async () => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        ServiceFeaturesID: idFeature,
        Value: value,
        RequiredValue:
          selectRequiredValues !== "DEFAULT" ? selectRequiredValues : null,
        Active: statusValue,
        DisplayName: displayName,
        IsServiceCall: isServiceCall,
        Disclaimer: disclaimer
      };

      const request = await server.post(
        "/admin/feature-values",
        JSON.stringify(data),
        {
          headers,
        }
      );

      const response = request.data;

      setIsLoading(false);
      setModalTitle(modalText[0].modalTitle);
      setModalBody(modalText[0].modalBody);
      setModal(true);
    } catch (error:any) {
      if(error?.response?.status === 400) {
        setModalBody(modalText[3].modalBody);
      } else {
        setModalBody("\nTry again later");
      }
      setIsLoading(false);
      setModalTitle(modalText[3].modalTitle);
      setIsError(true);
      setModal(true);
    }
  };

  const updateValue = async (idValue: any) => {
    try {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
      const data = {
        ServiceFeaturesID: idFeature,
        Value: value,
        RequiredValue:
          selectRequiredValues !== "DEFAULT" ? selectRequiredValues : null,
        Active: statusValue,
        DisplayName: displayName,
        IsServiceCall: isServiceCall,
        Disclaimer: disclaimer
      };
      const request = await server.put(
        `/admin/feature-values/${idValue}`,
        JSON.stringify(data),
        {
          headers,
        }
      );
      setIsLoading(false);
      setModalTitle(modalText[2].modalTitle);
      setModalBody(modalText[2].modalBody);
      setModal(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setModalTitle(modalText[3].modalTitle);
      setModalBody(modalText[3].modalBody);
      setIsError(true);
      setModal(true);
    }
  };

  const onChangeValue = (e: any) => {
    setValue(e.target.value);
  };

  const onChangeDisplayName = (e: any) => {
    setDisplayName(e.target.value);
  };

  const onChangeDisclaimer = (e: any) => {
    setDisclaimer(e.target.value);
  };

  const onChangeSelectValue = (e: any) => {
    setSelectStatusValue(e.target.value);
    if (e.target.value == "1") {
      setStatusValue(true);
    } else {
      setStatusValue(false);
    }
  };

  const onChangeIsServiceCall = ({target:{checked}}) => {
    setIsServiceCall(checked);
  }

  const onChangeSelectRequiredValues = (e: any) => {
    setSelectRequiredValues(e.target.value);
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyModal
        modalTitle={modalTitle}
        modalBody={modalBody}
        modalValue={modal}
        onChangeModal={onChangeModal}
      />
      <MyTitle
        title={
          isUpdateCreateValue
            ? "Update a Feature value"
            : "Create a Feature value"
        }
      />
      <section className="section-create">
        <div className="card-create">
          <div className="card-head-create">
            <h1 className="title-card-create">
              {isUpdateCreateValue
                ? "Update a Feature value"
                : "Create a Feature value"}
            </h1>
          </div>
          <div className="card-body-create">
            <form
              action=""
              onSubmit={onSubmitFormCompanyService}
              className="form-container"
            >
              <div className="form-container-input">
                <span className="label-create-input">Display Name: </span>
                <input
                  className="input-form-create"
                  type="text"
                  name="displayName"
                  id="displayName"
                  onChange={onChangeDisplayName}
                  autoComplete="off"
                  placeholder="Display Name..."
                  value={displayName}
                  required
                />
              </div>
              <div className="form-container-input">
                <span className="label-create-input">Answer: </span>
                <input
                  className="input-form-create"
                  type="text"
                  name="value"
                  id="value"
                  onChange={onChangeValue}
                  autoComplete="off"
                  placeholder="Answer..."
                  value={value}
                  required
                  disabled={state.isAddOn}
                />
              </div>

              <div className="form-container-input">
                <span className="label-create-input">Required Value: </span>
                <select
                  name="selectRequiredValue"
                  id="selectRequiredValue"
                  className="input-form-create select-create"
                  onChange={onChangeSelectRequiredValues}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={selectRequiredValues}
                  required
                  disabled={state.isAddOn || requiredValues.length === 0}
                >
                  <option value="DEFAULT" disabled>
                    -- Choose value --
                  </option>
                  {requiredValues.map((item, index) => {
                    return (
                      <option value={`${item.id}`} key={index}>
                        {item.value}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-container-input">
                <span className="label-create-input">Status to Answer: </span>
                <select
                  name="selectValue"
                  id="selectValue"
                  className="input-form-create select-create"
                  onChange={onChangeSelectValue}
                  style={{
                    backgroundImage: "url('assets/home/arrow_down.png')",
                  }}
                  value={selectStatusValue}
                  required
                  disabled={state.isAddOn}
                >
                  <option value="DEFAULT" disabled>
                    -- Choose status --
                  </option>
                  <option value="0">Deactive</option>
                  <option value="1">Active</option>
                </select>
              </div>

              <div className="form-container-input">
                <span className="label-create-input">Disclaimer: </span>
                <input
                  className="input-form-create"
                  type="text"
                  name="disclaimer"
                  id="disclaimer"
                  onChange={onChangeDisclaimer}
                  autoComplete="off"
                  placeholder="Disclaimer..."
                  value={disclaimer}
                />
              </div>

              <div className="form-container-input">
                <input type="checkbox" id="serviceCall" value="1" checked={isServiceCall} className="checkbox-form" onChange={onChangeIsServiceCall} disabled={isUpdateCreateValue}/>
                <label className="label-create-input">Is a Service Call?</label>
              </div>

              <div className="btn-container-create">
                <button type="submit" className="btn-submit-create">
                  <span className="label-btn-submit">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateValue;
